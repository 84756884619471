import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import Utils from '../../Util';
import { SideMenuActions } from '../side-menu-action-types';

export interface SideMenuItem {
	id: number;
	title: string;
	title2?: string;
	icon?: string;
	img: string;
	url: string;
	cohortId?: number;
}

export interface SideMenuState {
	sideMenu: SideMenuItem[];
	selected: number;
	cohorts: any[];
}

export const selectSideMenuState = createFeatureSelector<SideMenuState>(
	'sidemenu',
);

export const initialMenuState: SideMenuState = {
	sideMenu: [],
	selected: 0,
	cohorts: [],
};

export const sideMenuReducer = createReducer(
	initialMenuState,
	on(SideMenuActions.sideMenuLoaded, (state, action) => {
		console.log("hello i am line 34",action)
		const appPages = [];
		const id = 0;
		if (true) {
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.Applications,
				img: './assets/side-menu/applications.svg',
				url: '/home',
			});
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.AppsAnalytics,
				img: './assets/side-menu/analytics.svg',
				url: '/apps/analytics',
			});
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.CohortSettings,
				img: './assets/side-menu/settings.svg',
				url: '/cohort/settings',
			});
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.CourseContent,
				img: './assets/side-menu/books.svg',
				url: '/course/content',
			});
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.MentorRequests,
				img: './assets/side-menu/❓.svg',
				url: '/mentor/requests',
			});
			appPages.push({
				id: appPages.length,
				title: '💡' + ' ' + Utils.SIDE_MENU.MentorAnalytics,
				img: './assets/side-menu',
				url: '/mentor/analytics',
			});
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.Students,
				img: './assets/acc-cap.svg',
				url: '/students',
			});
			// appPages.push({
			// 	id: appPages.length,
			// 	title: Utils.SIDE_MENU.StudentAnalytics,
			// 	img: './assets/side-menu/stud-analytics.svg',
			// 	url: '/student/analytics',
			// });
			appPages.push({
				id: appPages.length,
				title: Utils.SIDE_MENU.CohortAnalytics,
				img: './assets/cohortAnalytics.svg',
				url: '/cohort/analytics',
			});
		}
		console
		return {
			...state,
			sideMenu: initialMenuState.sideMenu.concat(appPages),
			cohorts: action.cohorts,
		};
	}),
	on(SideMenuActions.sideMenuRedirected, (state, action) => {
		return {
			...state,
			selected: action.menu?.id,
		};
	}),
);

// export const { selectAll } = adapter.getSelectors();
