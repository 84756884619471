import { createAction, props } from '@ngrx/store';
import { Cohort } from 'src/app/models/dashboard-models';
import { MentorRequestFilters } from 'src/app/models/mentor-request-models';

export const setCohort = createAction(
	'[Mentor Request Page] Set Cohort',
	props<{ cohort: Cohort }>(),
);

export const initializeMentorRequestPage = createAction(
	'[Mentor Request Page] Initialize Mentor Request Page',
);

export const setMentorRequestFilters = createAction(
	'[Mentor Request Page] Set Mentor Request Filters',
	props<{ filters: MentorRequestFilters }>(),
);

export const loadRequestsSummary = createAction(
	'[Mentor Request Page] Load Mentor Requests Summary',
);

export const requestsSummaryLoaded = createAction(
	'[Mentor Request Page] Mentor Requests Summary Loaded',
	props<{ summary: any }>(),
);

export const loadRequests = createAction(
	'[Mentor Request Page] Load Mentor Requests',
);

export const requestsLoaded = createAction(
	'[Mentor Request Page] Mentor Requests Loaded',
	props<{ requests: any }>(),
);

export const paginateRequests = createAction(
	'[Mentor Request Page] Paginate Mentor Requests',
);

export const requestsPaginated = createAction(
	'[Mentor Request Page] Mentor Requests Paginated',
	props<{ requests: any }>(),
);

export const reviseMentorRequeststages = createAction(
	'[Mentor Request Page] Revise Mentor Request Stages',
	props<{ deal: any, stage: any }>(),
);
