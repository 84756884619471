import { createAction, props } from '@ngrx/store';
import { Cohort } from '../../models/dashboard-models';

export const loadCohortsList = createAction('[Cohorts] Load Cohorts List');

export const cohortsLoaded = createAction(
	'[Cohorts] Cohorts Loaded',
	props<{ cohorts: any }>(),
);

export const setActiveCohort = createAction(
	'[Cohorts] Set Active Cohort',
	props<{ cohort: Cohort }>(),
);
