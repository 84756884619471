import { CommonModule } from "@angular/common";
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterPipe' })
export class FilterPipe implements PipeTransform {
	transform(items: any[], filter: string): any {
		if (!items || !filter) {
			return items;
		}
		// filter items array, items which match and return true will be
		// kept, false will be filtered out
		return items.filter(author => {
			return (
				author.firstName.toLowerCase().includes(filter) ||
				author.lastName.toLowerCase().includes(filter)
			);
		});
	}
}




@NgModule({
	declarations: [FilterPipe],
	imports: [CommonModule],
	exports: [FilterPipe]
})

export class FilterPipeModule { }