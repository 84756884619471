import { createAction, props } from '@ngrx/store';

export const getSelectedStudent = createAction(
	'[StudentAnalytics] StudentAnalytics Get Selected Student From Students Page',
	props<{ id: number }>(),
);
export const getSelectedStudentSuccess = createAction(
	'[StudentAnalytics] StudentAnalytics Get Selected Student From Students Success',
	props<{ data: any }>(),
);
export const getSelectedStudentError = createAction(
	'[StudentAnalytics] StudentAnalytics Get Selected Student From Students Error',
	props<{ error: any }>(),
);
export const getInternalProfile = createAction(
	'[StudentAnalytics] StudentAnalytics Get  Internal Profile Data',
	props<{ id: any }>(),
);

export const getInternalProfileSuccess = createAction(
	'[StudentAnalytics] StudentAnalytics Get Internal Profile Data Succcess',
	props<{ data: any }>(),
);

export const getApplicationQuestions = createAction(
	'[StudentAnalytics] StudentAnalytics Get Application Questions',
);

export const getApplicationQuestionsSuccess = createAction(
	'[StudentAnalytics] StudentAnalytics Get Application Questions Succcess',
	props<{ data: any }>(),
);

export const getCohortEventData = createAction(
	'[StudentAnalytics] StudentAnalytics Get Cohort Event Data',
);

export const getCohortEventDataSuccess = createAction(
	'[StudentAnalytics] StudentAnalytics Get Cohort Event Data Succcess',
	props<{ data: any }>(),
);

export const getStudentData = createAction(
	'[Cohort Analytics] Cohort Analytics Other Data',
	props<{ cohortId: number; studentId: string }>(),
);

export const getStudentDataSuccess = createAction(
	'[Cohort Analytics] Cohort Analytics Other Data success',
	props<{ data: any }>(),
);
export const changeStudentEmail = createAction(
	'[Student Analytics] Change student email',
	props<{ oldEmail: string, newEmail:string }>(),
);
export const changeStudentEmailSuccess = createAction(
	'[Student Analytics] Change Email of student success',
	props<{ data: any }>(),
)
export const changeStudentEmailError = createAction(
	'[Student Analytics] Change Email of student Error',
	props<{ error: any }>(),
)
export const resetEmailChange = createAction(
	'[Student Analytics] Reset Email change details',
)
export const changeStudentEmailFreshsales = createAction(
	'[Student Analytics] Change student email on freshsales',
	props<{ id: number, field:string, value: string }>(),
)