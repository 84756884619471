import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SideMenuState } from './reducers/side-menu-reducers';

export const selectSideMenuState = createFeatureSelector<SideMenuState>(
	'sidemenu',
);

export const selectActiveSideMenu = createSelector(
	selectSideMenuState,
	state => {
		return state.sideMenu.filter(sideMenu => sideMenu.id === state.selected)[0];
	},
);

export const selectAllMenu = createSelector(
	selectSideMenuState,
	state => state.sideMenu,
);

export const selectActiveMenuId = createSelector(
	selectSideMenuState,
	state => state.selected,
);

export const selectAllCohort = createSelector(
	selectSideMenuState,
	state => state.cohorts,
);
