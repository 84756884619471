import { createFeatureSelector, createSelector } from '@ngrx/store';
import { studentsFeatureKey, StudentsState } from './students.reducer';

export const selectStudentApplicationState = createFeatureSelector<
	StudentsState
>(studentsFeatureKey);

export const selectStudentsState = createSelector(
	selectStudentApplicationState,
	state => {
		return state.studentsApplications;
	},
);

export const selectStudentsProgress = createSelector(
	selectStudentApplicationState,
	state => {
		return state.studentsLoaded;
	},
);

export const selectCountries = createSelector(
	selectStudentApplicationState,
	state => {
		return state.countryList;
	},
);

export const selectGroups = createSelector(
	selectStudentApplicationState,
	state => {
		return state.groupList;
	},
);
export const selectUpdateStatus = createSelector(
	selectStudentApplicationState,
	state => {
		return state.updateGroupStatus;
	},
);
export const selectEmailContent = createSelector(
	selectStudentApplicationState,
	state => {
		return state.emailContent;
	},
);

export const selectEmailSubject = createSelector(
	selectStudentApplicationState,
	state => state.emailSubject,
);
export const selectStudentUpdateStatus = createSelector(
	selectStudentApplicationState,
	state => {
		return state.updateInProgress;
	},
);
export const selectStudentUpdateError = createSelector(
	selectStudentApplicationState,
	state => {
		return state.error;
	},
);
export const selectMemberError = createSelector(
	selectStudentApplicationState,
	state => {
		return state.error;
	},
);
export const orderStatus = createSelector(
	selectStudentApplicationState,
	state => state.orderStatus,
);
export const orderError = createSelector(
	selectStudentApplicationState,
	state => state.orderError,
);
export const groupChangeOrderStatus = createSelector(
	selectStudentApplicationState,
	state => state.groupChangeStatus,
);
export const groupChnageOrderError = createSelector(
	selectStudentApplicationState,
	state => state.groupChangeError,
);
export const uploadErrorStatus = createSelector(
	selectStudentApplicationState,
	state => state.uploadStatus,
);
export const selectExtraDetails = createSelector(
	selectStudentApplicationState,
	state => state.extraDetails,
);
export const selectEmailfrom = createSelector(
	selectStudentApplicationState,
	state => state.emailFrom,
);
export const selectPaymentMode = createSelector(
	selectStudentApplicationState,
	state => {
		return state.modeList;
	},
);
export const selectPaymentLinks = createSelector(
	selectStudentApplicationState,
	state => {
		return state.linkList;
	},
);
