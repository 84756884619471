import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from 'auth-lib';
import { Platform } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { LoadingService, MessagesService } from 'util-lib';
import { Store } from '@ngrx/store';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from '@angular/router';
import { MenuController } from '@ionic/angular';
import { MenuUIService } from 'terra-ui';
import { AppState } from './reducers';
import { BobService } from './bob.service';
import { resetDashboard } from './home/redux/dashboard-actions';
import Utils from './Util';
import { loadCohortsList } from './cohorts/redux/cohorts-actions';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	isUserLoggedin = false;
	currentPageTitle = 'Dashboard';
	public selectedIndex = 0;
	routerOutlet: 'main' | 'main-content' = 'main-content';
	loading = false;

	constructor(
		private platform: Platform,
		public bobService: BobService,
		public auth: AuthStoreService,
		public actionSheetController: ActionSheetController,
		public messages: MessagesService,
		private router: Router,
		private menu: MenuController,
		private menuUIService: MenuUIService,
		public loadingService: LoadingService,
		private store: Store<AppState>,
	) {
		this.platform.ready().then(() => {});
	}

	ngOnInit() {
		this.auth.isLoggedOut$.subscribe(loggedOut => {
			if (!!loggedOut) {
				let path = window.location.href.split('#')[1]
				if(path?.includes("?")){
					path = path.split('?')[0]
				}
				else{
					path = "";
				}
				console.log("i am the path you are looking",path)
				// this.router.navigate([path], {
				// 	queryParams: { returnUrl: path },
				// });
				//waits(6)
				window.localStorage.setItem("returnUrl", path );
				this.store.dispatch({ type: 'Reset On Logout' });
				this.store.dispatch(resetDashboard());
			}
		});
		this.auth.firebaseToken$.pipe(distinctUntilChanged()).subscribe(token => {
			if (token) {
				this.store.dispatch(loadCohortsList());
			}
		});
		this.router.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loading = true;
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.loading = false;
					break;
				}
				default: {
					break;
				}
			}
		});
		this.router.events.forEach(item => {
			if (item instanceof NavigationEnd) {
				const gtmTag = {
					event: 'Pageview',
					pagePath: item.url,
					pageTitle: item.url,
				};
				const pageViewEvent: any = {};
				pageViewEvent.event = 200;
				pageViewEvent.app = 'learn';
				pageViewEvent.pagePath = item.url;
				this.auth.logAuthUserAction(pageViewEvent);
			}
		});
	}
}
