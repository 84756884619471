import { MentorRequestModel } from '../../models/mentor-request-models';
import { createReducer, on } from '@ngrx/store';
import * as MentorRequestActions from './mentor-request-actions';
import Utils from 'src/app/Util';

export const mentorRequestKey = 'mentor-request';

export const defaultFilterState = {
	pipelineId: 15000026345,
	page: 1,
	sort: Utils.CREATED_AT,
	stageIds: [],
	searchValue: '',
};

export const initialMentorRequestState: MentorRequestModel = {
	summary: null,
	requests: null,
	filters: defaultFilterState,
	requestsLoaded: false,
	paginationProgress: false,
};

export const mentorRequestReducer = createReducer(
	initialMentorRequestState,
	on(MentorRequestActions.setMentorRequestFilters, (state, action) => ({
		...state,
		filters: {
			...defaultFilterState,
			page: action.filters?.page,
			sort: action.filters?.sort,
			stageIds: action.filters?.stageIds,
			searchValue: action.filters?.searchValue,
			filterDates: action.filters?.filterDates,
		},
	})),
	on(MentorRequestActions.requestsSummaryLoaded, (state, action) => ({
		...state,
		summary: action.summary,
	})),
	on(MentorRequestActions.loadRequests, state => ({
		...state,
		requestsLoaded: false,
	})),
	on(MentorRequestActions.requestsLoaded, (state, action) => ({
		...state,
		requests: action.requests,
		requestsLoaded: true,
	})),
	on(MentorRequestActions.paginateRequests, state => ({
		...state,
		paginationProgress: true,
	})),
	on(MentorRequestActions.requestsPaginated, (state, action) => {
		const temparray = [...state.requests, ...action.requests];
		const unique = [
			...new Map(temparray.map(item => [item.id, item])).values(),
		];
		return {
			...state,
			requests: unique,
			requestsLoaded: true,
			paginationProgress: false,
		};
	}),
	on(MentorRequestActions.reviseMentorRequeststages, (state, action) => {
		const summary = state.summary;
		const stagesList = summary?.stages;
		const stage = stagesList.find(
			item => item.id === action.deal.deal_stage_id,
		);
		let newStageList;

		const getNameById = action.stage;
		// const getNameById = (id: number) =>
		// 	state.pipelineStages.find(item => item.id === id).name;

		// Update the new deal stage data
		if (stage) {
			const index = stagesList.findIndex(item => item.id === stage.id);
			newStageList = [...stagesList];
			let newCount = stage?.applications;
			newStageList[index] = { ...stage, applications: ++newCount };
		} else {
			newStageList = [...stagesList];
			newStageList.push({
				id: action.deal.deal_stage_id,
				title: action.stage,
				applications: 1,
			});
		}
		const requestsList = state.requests;
		const appDetails = requestsList.find(item => item.id === action.deal.id);
		// Update the old deal stage data from the application
		const removeStage = newStageList.find(
			item => item.id === appDetails?.deal_stage_id,
		);
		if (removeStage?.applications === 1) {
			newStageList = newStageList.filter(item => item.id !== removeStage?.id);
		} else {
			const stageIdx = newStageList.findIndex(
				item => item.id === removeStage.id,
			);
			let stageAppCount = removeStage?.applications;
			newStageList[stageIdx] = {
				...removeStage,
				applications: --stageAppCount,
			};
		}

		// Update the application details
		const appIdx = requestsList.findIndex(item => item.id === appDetails.id);
		const newrequestsList = [...requestsList];
		newrequestsList[appIdx] = {
			...newrequestsList[appIdx],
			deal_stage_id: action.deal.deal_stage_id,
			deal_stage_name: action.stage,
			...(action.stage === Utils.PAYMENT_LINK_SENT && {
				payment_value: action.deal.custom_field.cf_payment_value,
			}),
		};
		return {
			...state,
			requests: newrequestsList,
			summary: {
				...state.summary,
				stages: newStageList,
			},
		};
	}),
);
