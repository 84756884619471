import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CourseContentModel } from '../../models/course-content-models';
import { courseContentKey } from './course-content-reducer';

export const selectCourseContentState = createFeatureSelector<
	CourseContentModel
>(courseContentKey);

export const selectCourseContentLoaded = createSelector(
	selectCourseContentState,
	state => !!state.selectedCohort,
);

export const currentCohort = createSelector(
	selectCourseContentState,
	state => state?.selectedCohort,
);

export const selectedCohort = createSelector(
	selectCourseContentState,
	state => state.selectedCohort,
);

export const selectClassSegment = createSelector(
	selectCourseContentState,
	state => state.classSegment,
);

export const selectAssignmentSegment = createSelector(
	selectCourseContentState,
	state => state.assignmentSegment,
);

export const selectClassesForCohort = createSelector(
	selectClassSegment,
	state => state?.cohortClasses,
);

export const selectAssignmentsForCohort = createSelector(
	selectAssignmentSegment,
	state => state?.assignments,
);

export const selectClassListError = createSelector(
	selectClassSegment,
	state => state?.cohortClassesError,
);

export const selectCreateClassContainerStatus = createSelector(
	selectClassSegment,
	state => state.createClassContainerStatus,
);

export const selectCreateClassContainerError = createSelector(
	selectClassSegment,
	state => state?.createClassContainerError,
);

export const selectCreateClassStatus = createSelector(
	selectClassSegment,
	state => state.createClassStatus,
);

export const selectCreateClassError = createSelector(
	selectClassSegment,
	state => state.createClassError,
);

export const selectClassUpdateStatus = createSelector(
	selectClassSegment,
	state => state.classUpdateStatus,
);

export const selectClassUpdateError = createSelector(
	selectClassSegment,
	state => state.classUpdateError,
);

export const selectClassDeleteStatus = createSelector(
	selectClassSegment,
	state => state.classDeleteStatus,
);

export const selectClassDeleteError = createSelector(
	selectClassSegment,
	state => state?.classDeleteError,
);

export const selectClassModuleList = createSelector(
	selectClassSegment,
	state => state?.moduleList,
);

export const selectClassModuleListError = createSelector(
	selectClassSegment,
	state => state.moduleListError,
);

export const selectAssignmentListError = createSelector(
	selectAssignmentSegment,
	state => state?.cohortAssignmentsError,
);

export const selectCurrentCohortSyllabus = createSelector(
	selectCourseContentState,
	state => state?.syllabusDetail,
);

export const selectCurrentCohortSyllabusLoaded = createSelector(
	selectCourseContentState,
	state => state?.syllabusLoaded || false,
);

export const currentCohortId = createSelector(
	currentCohort,
	(state: any) => state.id,
);

export const currentCohortInstructors = createSelector(
	selectCourseContentState,
	state => state.instructorsSegment?.instructors,
);

export const selectAdditionalResourcesSegment = createSelector(
	selectCourseContentState,
	state => state.additionalResources,
);

export const selectAssignmentDeleteError = createSelector(
	selectAssignmentSegment,
	state => state?.assignmentDeleteError,
);

// export const selectAssignmentUpdateError = createSelector(
// 	selectAssignmentSegment,
// 	state => state?.assignmentUpdateError,
// );

export const selectAssignmentCreateStatus = createSelector(
	selectAssignmentSegment,
	state => state?.createAssignmentStatus,
);

export const selectAssignmentDeleteStatus = createSelector(
	selectAssignmentSegment,
	state => state?.assignmentDeleteStatus,
);

export const selectAuthors = createSelector(
	selectCourseContentState,
	state => state?.authors,
);

export const selectSelectedAuthors = createSelector(
	selectCourseContentState,
	state => state?.selectedAuthor,
);

export const selectInstructorUpdateStatus = createSelector(
	selectCourseContentState,
	state => state.instructorsSegment.instructorsUpdateStatus,
);

export const selectInstructorUpdateError = createSelector(
	selectCourseContentState,
	state => state.instructorsSegment.instructorsUpdateError,
);

export const selectInstructorDeleteStatus = createSelector(
	selectCourseContentState,
	state => state?.instructorsSegment.instructorsDeleteStatus,
);

export const selectInstructorDeleteError = createSelector(
	selectCourseContentState,
	state => state?.instructorsSegment.instructorsDeleteError,
);

export const selectAdditionalResources = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.additionalResources,
);

export const selectLinks = createSelector(
	selectAdditionalResourcesSegment,
	state =>
		state?.additionalResources
			.filter(additionalresource => additionalresource.type === 'link')
			.map((link, index) => {
				return { ...link, index: index + 1 };
			}),
);

export const selectFiles = createSelector(
	selectAdditionalResourcesSegment,
	state =>
		state?.additionalResources
			.filter(additionalresource => additionalresource.type === 'file')
			.map((file, index) => {
				return { ...file, index: index + 1 };
			}),
);

export const selectAdditionalResourceUpdateStatus = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.additionalResourcesUpdateStatus,
);

export const selectAdditionalResourceUpdateError = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.additionalResourcesUpdateError,
);
export const selectAdditionalResourceDeleteStatus = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.additionalResourcesDeleteStatus,
);

export const selectAdditionalResourceDeleteError = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.additionalResourcesDeleteError,
);

export const selectFileUrl = createSelector(
	selectAdditionalResourcesSegment,
	state => state?.url,
);

export const selectClassLoaded = createSelector(
	selectCourseContentState,
	state => state.classLoaded,
);

export const selectAssignmentLoaded = createSelector(
	selectCourseContentState,
	state => state.assignmentLoaded,
);

export const selectAdditionalResourcesLoaded = createSelector(
	selectCourseContentState,
	state => state.additionalResourcesLoaded,
);

export const selectLoaded = createSelector(
	selectCourseContentState,
	state => state.classLoaded,
);

export const selectInstructorLoaded = createSelector(
	selectCourseContentState,
	state => state.instructorsSegment?.instructorsLoaded,
);

export const selectSyllabusLoaded = createSelector(
	selectCourseContentState,
	state => state.syllabusLoaded,
);

export const selectCreateWeekStatus = createSelector(
	selectCourseContentState,
	state => state.weekSegment.createStatus,
);
export const selectDeleteWeekStatus = createSelector(
	selectCourseContentState,
	state => state.weekSegment.deleteStatus,
);

export const selectClassCopyStatus = createSelector(
	selectCourseContentState,
	state => state.copyClassStatus,
);

export const selectClassIdStatus = createSelector(
	selectCourseContentState,
	state => state.submitClassIdStatus,
);

export const selectCheckClassSavedStatus = createSelector(
	selectCourseContentState,
	state => state.checkClassIdSavedStatus,
);

export const selectHideClassStatus = createSelector(
	selectCourseContentState,
	state => state.hideClassStatus,
);
export const classStatusUpdating = createSelector(
	selectCourseContentState,
	state => state.classStatusUpdating,
);

export const selectedClassUpdated = createSelector(
	selectCourseContentState,
	state => state.activeClassUpdated,
);

export const selectedAssignmentUpdated = createSelector(
	selectCourseContentState,
	state => state.activeAssignmentUpdated,
);

export const selectedSyllabusUpdated = createSelector(
	selectCourseContentState,
	state => state.activeSyllabusUpdated,
);

export const selectedInstructorUpdated = createSelector(
	selectCourseContentState,
	state => state.activeInstructorUpdated,
);

export const selectedAddResourceUpdated = createSelector(
	selectCourseContentState,
	state => state.activeAddResourcesUpdated,
);

export const selectCourseContentActiveCohort = createSelector(
	selectCourseContentState,
	state => state.activeCohortId,
);

export const fileUploadStatus = createSelector(
	selectCourseContentState,
	state => state.linkUploaded,
);

export const selectCreateAssignmentError = createSelector(
	selectCourseContentState,
	state => state.assignmentSegment.createAssignmentError,
);

export const selectUpdateAssignmentStatus = createSelector(
	selectCourseContentState,
	state => state.assignmentSegment.updateAssignmentStatus,
);

export const selectUpdateSyllabusStatus = createSelector(
	selectCourseContentState,
	state => state.syllabusUpdated,
);

export const selectUpdateAssignmentError = createSelector(
	selectCourseContentState,
	state => state.assignmentSegment.updateAssignmentError,
);

export const overRideStatus = createSelector(
	selectCourseContentState,
	state => state.overRide,
);

export const selectModuleAddedStatus = createSelector(
	selectCourseContentState,
	state => state.moduleAddedStatus,
);

export const selectModuleAddedError = createSelector(
	selectCourseContentState,
	state => state.moduleAddedError,
);

export const selectModuleExists = createSelector(
	selectCourseContentState,
	state => state.moduleExists,
);
export const selectClassSubModuleStatus = createSelector(
	selectCourseContentState,
	state => state.classSubModuleStatus,
);
