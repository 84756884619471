import { createAction, props } from '@ngrx/store';
import {
	AdditionalResource,
	Assignment,
	AssignmentAPIBody,
	ClassAPIBody,
	ClassContainer,
	Instructor,
} from 'src/app/models/course-content-models';
import { Cohort } from '../../models/dashboard-models';

export const setupCourseContent = createAction(
	'[Course Content Page] Setup Course Content',
);

export const setCohort = createAction(
	'[Course Content Page] Set Cohort',
	props<{ cohort: Cohort }>(),
);

export const loadClassesForCohort = createAction(
	'[Course Content Page] Load Classes For Cohort',
	props<{ cohortId: number }>(),
);

export const classesForCohortLoaded = createAction(
	'[Course Content Page] Classes For Cohort Loaded',
	props<{ response: ClassContainer[] }>(),
);

export const classesForCohortError = createAction(
	'[Course Content Page] Classes For Cohort Error',
	props<{ error: any }>(),
);

export const loadClassModuleList = createAction(
	'[Course Content Page] Load Class Module List',
);

export const classModuleListLoaded = createAction(
	'[Course Content Page] Class Module List Loaded',
	props<{ response: any }>(),
);

export const classModuleListError = createAction(
	'[Course Content Page] Class Module List Error',
	props<{ error: any }>(),
);

export const createClassContainer = createAction(
	'[Course Content Page] Create New Class Container',
	props<{ name: string }>(),
);

export const ContainerCreated = createAction(
	'[Course Content Page] Class Container Created',
	props<{ response: any }>(),
);

export const createClassContainerError = createAction(
	'[Course Content Page] Create Class Container Error',
	props<{ error: any }>(),
);

export const createClass = createAction(
	'[Course Content Page] Create New Class',
	props<{ classBody: any }>(),
);

export const classCreated = createAction(
	'[Course Content Page] Class Created',
	props<{ status: boolean }>(),
);

export const createClassError = createAction(
	'[Course Content Page] Create Class Error',
	props<{ error: any }>(),
);

export const updateClass = createAction(
	'[Course Content Page] Update Class',
	props<{ classData: ClassAPIBody }>(),
);

export const classUpdated = createAction(
	'[Course Content Page] Class Updated',
	props<{ status: boolean }>(),
);

export const updatingClass = createAction(
	'[Course Content Page] Updating Class',
);

export const activeClassUpdated = createAction(
	'[Course Content Page] Active Class Updated',
	props<{ status: boolean }>(),
);

export const classUpdateError = createAction(
	'[Course Content Page] Class Update Error',
	props<{ error: any }>(),
);

export const deleteClass = createAction(
	'[Course Content Page] Delete Class',
	props<{ classId: number }>(),
);

export const reloadClass = createAction('[Course Content Page] Reload Class');

export const classDeleteError = createAction(
	'[Course Content Page] Class Delete Error',
	props<{ error: any }>(),
);

export const classDeleted = createAction(
	'[Course Content Page] Class Deleted',
	props<{ status: any }>(),
);

export const loadSyllabus = createAction(
	'[Course Content Page] Set Syllabus',
	props<{ cohortId: number }>(),
);

export const syllabusLoaded = createAction(
	'[Course Content Page] Syllabus Loaded',
	props<{ syllabusDetail: any }>(),
);

export const loadCohortInstructor = createAction(
	'[Course Content Page] Load Cohort Instructor',
	props<{ cohortId: number }>(),
);

export const cohortInstructorLoded = createAction(
	'[Course Content Page] Cohort Instructors Loaded',
	props<{ response: any[] }>(),
);

export const updateSyllabusDetail = createAction(
	'[Course Content Page] Update Syllabus',
	props<{ syllabusDetail: any }>(),
);

export const updatedSyllabusDetailOnStore = createAction(
	'[Course Content Page] Update Syllabus On store',
	props<{ syllabusDetail: any }>(),
);

export const resetSyllabusDetail = createAction(
	'[Course Content Page] Reset syllabus',
);

export const activeSyllabusUpdated = createAction(
	'[Course Content Page] Active Syllabus Updated',
	props<{ status: boolean }>(),
);

export const updateInstructorDetail = createAction(
	'[Course Content Page] Update Instructors',
	props<{ instructors: any[] }>(),
);

export const loadAssignmentsForCohort = createAction(
	'[Course Content Page] Load Assigments',
	props<{ cohortId: number }>(),
);

export const assignmentsForCohortLoaded = createAction(
	'[Course Content Page] Assignments For Cohort Loaded',
	props<{ response: Assignment[] }>(),
);

export const assignmentsForCohortError = createAction(
	'[Course Content Page] Assignments For Cohort Error',
	props<{ error: any }>(),
);

export const createAssignment = createAction(
	'[Course Content Page] Create New Assignment',
	props<{
		assignmentBody: AssignmentAPIBody;
	}>(),
);

export const createAssignmentError = createAction(
	'[Course Content Page] Create Assignment Error',
	props<{ error: any }>(),
);

export const assignmentCreated = createAction(
	'[Course Content Page] Assignment Created',
	props<{ status: boolean }>(),
);

export const updateAssignment = createAction(
	'[Course Content Page] Update Assignment',
	props<{ assignmentData: AssignmentAPIBody }>(),
);

export const activeAssignmentUpdated = createAction(
	'[Course Content Page] Active Assignment Updated',
	props<{ status: boolean }>(),
);

export const assignmentError = createAction(
	'[Course Content Page] Active Assignment Updated Error',
	props<{ status: boolean }>(),
);

export const assignmentUpdated = createAction(
	'[Course Content Page] Assignment Updated',
	props<{ status: boolean }>(),
);

export const assignmentUpdateError = createAction(
	'[Course Content Page] Class Assignment Error',
	props<{ error: any }>(),
);

export const deleteAssignment = createAction(
	'[Course Content Page] Delete Assignment',
	props<{ assignmentId: number; cohortId: number }>(),
);

export const assignmentDeleteError = createAction(
	'[Course Content Page] Assignment Delete Error',
	props<{ error: any }>(),
);

export const assignmentDeleted = createAction(
	'[Course Content Page] Assignment Deleted',
	props<{ status: any }>(),
);

export const reloadAssignment = createAction(
	'[Course Content Page] Reload Assignment',
);

export const reloadSyllabus = createAction(
	'[Course Content Page] Reload Syllabus',
);

export const reloadInstructor = createAction(
	'[Course Content Page] Reload Instructor',
);

export const reloadAdditionalResources = createAction(
	'[Course Content Page] Reload AdditionalResources',
);

export const loadAuthors = createAction('[Course Content Page] Load Authors');

export const loadedAuthors = createAction(
	'[Course Content Action] Loaded Authors',
	props<{ authors: any }>(),
);

export const loadAuthorsError = createAction(
	'[Course Content Action] Load Author Error',
	props<{ error: any }>(),
);

export const selectedAuthors = createAction(
	'[Course Content Action] Selected Authors',
	props<{ selectedAuthor: any }>(),
);

export const clearSelectedAuthors = createAction(
	'[Course Content Action] Clear Selected Authors',
);
export const updateInstructor = createAction(
	'[Course Content Page] Update Instructor',
	props<{ instructor: Instructor }>(),
);

export const instructorUpdated = createAction(
	'[Course Content Page] Instructor Updated',
	props<{ status: boolean }>(),
);

export const instructorUpdateError = createAction(
	'[Course Content Page] Instructor Update Error',
	props<{ error: any }>(),
);

export const deleteInstructor = createAction(
	'[Course Content Page] Delete Instructor',
	props<{ instructorId: any; roleId: any; cohortId: any }>(),
);

export const instructorDeleted = createAction(
	'[Course Content Page] Instructor Deleted',
	props<{ status: boolean }>(),
);

export const instructorDeleteError = createAction(
	'[Course Content Page] Instructor Delete Error',
	props<{ error: any }>(),
);

export const activeInstructorUpdated = createAction(
	'[Course Content Page] Active Instructor Updated',
	props<{ status: boolean }>(),
);

export const loadCohortAdditionalResource = createAction(
	'[Course Content Page] Load Cohort Additional Resource',
	props<{ cohortId: number }>(),
);

export const activeAddResourcesUpdated = createAction(
	'[Course Content Page] Active Additional Resources Updated',
	props<{ status: boolean }>(),
);

export const cohortAdditionalResourcesLoaded = createAction(
	'[Course Content Page] Cohort Additional Resources Loaded',
	props<{ response: AdditionalResource[] }>(),
);

export const additionalResourceLoadError = createAction(
	'[Course Content Page] Additional Resource Load Error',
	props<{ error: any }>(),
);

export const addAdditionalResource = createAction(
	'[Course Content Page] Add Additional Resource',
	props<{ additionalResource: AdditionalResource[] }>(),
);

export const additionalResourceAdded = createAction(
	'[Course Content Page] Additional Resource Added',
	props<{ status: boolean }>(),
);

export const additionalResourceAddError = createAction(
	'[Course Content Page] Additional Resource Add Error',
	props<{ error: any }>(),
);

export const updateAdditionalResource = createAction(
	'[Course Content Page] Update Additional Resource',
	props<{ additionalResource: AdditionalResource }>(),
);

export const additionalResourceUpdated = createAction(
	'[Course Content Page] Additional Resource Updatedd',
	props<{ status: boolean }>(),
);

export const additionalResourceUpdateError = createAction(
	'[Course Content Page] Additional Resource Update Error',
	props<{ error: any }>(),
);

export const deleteAdditionalResource = createAction(
	'[Course Content Page] Delete Additional Resource',
	props<{ additionalResourceId: any }>(),
);

export const additionalResourceDeleted = createAction(
	'[Course Content Page] Additional Resource Deleted',
	props<{ status: boolean }>(),
);

export const additionalResourceDeleteError = createAction(
	'[Course Content Page] Additional Resource Delete Error',
	props<{ error: any }>(),
);

export const fileUploadForCohort = createAction(
	'[Course Content Page] File Upload For Cohort',
	props<{ cohortId: number; folder: string; file: any; id: any }>(),
);

export const fileUploadForCohortUrl = createAction(
	'[Course Content Page] File Upload For Cohort Status',
	props<{ url: any; id: any }>(),
);

export const fileUploadForCohortError = createAction(
	'[Course Content Page] File Upload For Cohort Error',
	props<{ error: any }>(),
);

export const addNewFile = createAction(
	'[Course Content Page] Add New File',
	props<{ file: any }>(),
);

export const updateNewFile = createAction(
	'[Course Content Page] Update New File',
	props<{ file: any }>(),
);

export const createWeek = createAction(
	'[Course Content Page] Create Week',
	props<{ week: {} }>(),
);
export const createWeekStatusResponse = createAction(
	'[Course Content Page] Create Week Status Response',
	props<{ status: any }>(),
);

export const deleteWeek = createAction(
	'[Course Content Page] Delete Week',
	props<{ id: any }>(),
);

export const deleteWeekStatusResponse = createAction(
	'[Course Content Page] Delete Week Status Response',
	props<{ status: any }>(),
);

export const copyClass = createAction(
	'[Course Content Page] Copy Class',
	props<{ oldId: any; newId: any; confirmation: boolean }>(),
);

export const copyClassOverride = createAction(
	'[Course Content Page] Copy Class',
	props<{ status: boolean }>(),
);

export const copyClassStatusResponse = createAction(
	'[Course Content Page] Copy Class Status Response',
	props<{ status: any }>(),
);

export const submitClassId = createAction(
	'[Course Content Page] Submit Class Id',
	props<{ id }>(),
);

export const submitClassIdStatus = createAction(
	'[Course Content Page] Submit Class Id Status',
	props<{ status }>(),
);

export const checkClassSaved = createAction(
	'[Course Content Page] Check Class Saved',
	props<{ id }>(),
);

export const checkClassSavedStatus = createAction(
	'[Course Content Page] Check Class Saved Status',
	props<{ status }>(),
);

export const hideClass = createAction(
	'[Course Content Page] Hide Class',
	props<{ classid }>(),
);

export const hideClassStatus = createAction(
	'[Course Content Page] Hide Class Status',
	props<{ status }>(),
);

export const cohortOnClass = createAction(
	'[Course Content Page] Current Cohort Loaded On Class',
	props<{ status: number }>(),
);

export const cohortOnAssignment = createAction(
	'[Course Content Page] Current Cohort Loaded On Assignment',
	props<{ status: number }>(),
);

export const cohortOnSyllabus = createAction(
	'[Course Content Page] Current Cohort Loaded On Syllabus',
	props<{ status: number }>(),
);

export const cohortOnInstructor = createAction(
	'[Course Content Page] Current Cohort Loaded On Instructor',
	props<{ status: number }>(),
);

export const cohortOnResources = createAction(
	'[Course Content Page] Current Cohort Loaded On Resources',
	props<{ status: number }>(),
);

export const setCourseActiveCohort = createAction(
	'[Course Content Page] Active Cohort on Course Content',
	props<{ cohortId: number }>(),
);

export const setNewModule = createAction(
	'[Course Content Page] Set New Module',
	props<{ moduleName: string }>(),
);

export const setNewModuleError = createAction(
	'[Course Content Page] Set New Module Error',
	props<{ moduleAddedError: boolean }>(),
);

export const setNewModuleSuccess = createAction(
	'[Course Content Page] Set New Module Success',
	props<{ moduleNameResponse: any }>(),
);
export const resetModuleSuccess = createAction(
	'[Course Content Page] reset Module Success',
);
export const resetModuleExists = createAction(
	'[Course Content Page] reset Module Exist',
);
export const addNewModule = createAction(
	'[Course Content Page] Add new Module',
	props<{
		name: any;
		duration: number;
		moduleId: string;
		mandatory: boolean;
		classEventId: number;
		authors: any[];
		moduleOrder: number;
	}>(),
);
export const updateModuleList = createAction(
	'[Course Content Page] Update list after after adding new module',
	props<{ response: any; payload: any }>(),
);
export const restSubModuleAddedStatus = createAction(
	'[Course Content Page] Reset Class Sub Module Added status',
	props<{ status: any }>(),
);
export const updateClassSubModule = createAction(
	'[Course Content Page] Update class subModule',
	props<{
		name: any;
		duration: number;
		moduleId: string;
		mandatory: boolean;
		classEventId: number;
		authors: any[];
		id: number;
		moduleOrder: number;
	}>(),
);

export const classSubModuleUpdated = createAction(
	'[Course Content Page] Update class subModule success',
	props<{ response: any }>(),
);
export const deleteClassSubModule = createAction(
	'[Course Content Page] Delete Class Sub Module',
	props<{ id: any }>(),
);
export const classSubModuleDeleted = createAction(
	'[Course Content Page] Class Sub Module Deleted Successfully',
	props<{ response: any }>(),
);
export const reorderModules = createAction(
	'[Course Content Page] Reorder class Sub modules',
	props<{ data: any }>(),
);
export const classSubModuleReordered = createAction(
	'[Course Content Page] Reorder class Sub modules Success',
	props<{ response: any }>(),
);
export const bulkUploadSections = createAction(
	'[Course Content Page] Bulk upload Sections',
	props<{ cohortId: number; file: FormData }>(),
);
export const bulkUploadSectionsSuccess = createAction(
	'[Course Content Page] Bulk upload sections Success',
	props<{ response: any }>(),
);
export const bulkUploadClassEvents = createAction(
	'[Course Content Page] Bulk upload class events',
	props<{ cohortId: number; file: FormData }>(),
);
export const bulkUploadClassEventsSuccess = createAction(
	'[Course Content Page] Bulk upload class events Success',
	props<{ response: any }>(),
);
export const bulkUploadModules = createAction(
	'[Course Content Page] Bulk upload Modules',
	props<{ cohortId: number; file: FormData }>(),
);
export const bulkUploadModulesSuccess = createAction(
	'[Course Content Page] Bulk upload Modules Success',
	props<{ response: any }>(),
);
export const bulkUploadCalendarEvents = createAction(
	'[Course Content Page] Bulk upload Calendar Events',
	props<{ cohortId: number; file: FormData }>(),
);
export const bulkUploadCalendarEventsSuccess = createAction(
	'[Course Content Page] Bulk upload Calendar Events Success',
	props<{ response: any }>(),
);
