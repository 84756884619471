import { routerReducer } from '@ngrx/router-store';
import {
	ActionReducer,
	ActionReducerMap,
	createFeatureSelector,
	createSelector,
	MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { initialApplicationDetailsState } from '../application-details/redux/application-details-reducer';
import { initialCohortSettingsState } from '../cohort-settings/redux/cohort-settings-reducer';
import {
	cohortsReducer,
	initialCohortsState,
} from '../cohorts/redux/cohorts-reducer';
import { initialCourseContentState } from '../course-content/redux/course-content-reducer';
import { initialDashboardState } from '../home/redux/dashboard-reducer';
import { initialMentorRequestState } from '../mentor-requests/redux/mentor-requests-reducer';
import { initialMenuState } from '../side-menu/reducers/side-menu-reducers';

export interface AppState {
	router;
}

export const reducers: ActionReducerMap<AppState> = {
	router: routerReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
	return (state, action) => {
		if (action.type === 'Reset On Logout') {
			const stateData = { ...state };
			stateData.cohorts = initialCohortsState;
			stateData.dashboard = initialDashboardState;
			stateData.sidemenu = initialMenuState;
			stateData.applicationDetails = initialApplicationDetailsState;
			stateData.cohortSettings = initialCohortSettingsState;
			stateData.courseContent = initialCourseContentState;
			stateData.mentorRequest = initialMentorRequestState;
			return reducer(stateData, action);
		} else {
			return reducer(state, action);
		}
	};
}

export function resetOnSignOut(
	reducer: ActionReducer<any>,
): ActionReducer<any> {
	return (state, action) => {
		if (action.type === 'Reset On Logout') {
			const stateData = { ...state };
			stateData.cohorts = initialCohortsState;
			stateData.dashboard = initialDashboardState;
			stateData.sidemenu = initialMenuState;
			stateData.applicationDetails = initialApplicationDetailsState;
			stateData.cohortSettings = initialCohortSettingsState;
			stateData.courseContent = initialCourseContentState;
			stateData.mentorRequest = initialMentorRequestState;
			return reducer(stateData, action);
		} else {
			return reducer(state, action);
		}
	};
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
	? [logger]
	: [resetOnSignOut];
