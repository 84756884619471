import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import { selectActiveCohort } from 'src/app/cohorts/redux/cohorts-selectors';
import { AppState } from 'src/app/reducers';
import * as studentAnalyticsActions from './student-analytics.actions';

@Injectable()
export class StudentAnalyticsEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
	) {}

	loadApplicationDetails$ = createEffect(() =>
		this.actions.pipe(
			ofType(studentAnalyticsActions.getInternalProfile),
			withLatestFrom(
				this.authStore.user$,
				this.store.select(selectActiveCohort),
			),
			concatMap(([action, user, activeCohort]) =>
				this.bobService.getApplicationDetails(action.id).pipe(
					map(response => {
						return studentAnalyticsActions.getInternalProfileSuccess({
							data: response,
						});
					}),
				),
			),
		),
	);

	loadApplicationQuestions$ = createEffect(() =>
		this.actions.pipe(
			ofType(studentAnalyticsActions.getApplicationQuestions),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			concatMap(([action, token, activeCohort]) =>
				this.bobService
					.fetchApplicationQuestions(activeCohort.courseId, token)
					.pipe(
						map(response => {
							return studentAnalyticsActions.getApplicationQuestionsSuccess({
								data: response,
							});
						}),
					),
			),
		),
	);

	getSelectedStudent$ = createEffect(() =>
		this.actions.pipe(
			ofType(studentAnalyticsActions.getSelectedStudent),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.getSingleStudentData(action.id, token).pipe(
					map(response => {
						return studentAnalyticsActions.getSelectedStudentSuccess({
							data: response,
						});
					}),
				),
			),
		),
	);

	loadCohortEventData$ = createEffect(() =>
		this.actions.pipe(
			ofType(studentAnalyticsActions.getCohortEventData),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			concatMap(([action, token, activeCohort]) =>
				this.bobService.fetchCohortEventData(activeCohort.id, token).pipe(
					map(response => {
						let temp: any = response;
						return studentAnalyticsActions.getCohortEventDataSuccess({
							data: temp.data,
						});
					}),
				),
			),
		),
	);

	getStudentData$ = createEffect(() =>
		this.actions.pipe(
			ofType(studentAnalyticsActions.getStudentData),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.fetchCohortStudentOtherData(action.cohortId, action.studentId, token)
					.pipe(
						map((response: any) => {
							return studentAnalyticsActions.getStudentDataSuccess({
								data: response.data[0],
							});
						}),
					),
			),
		),
	);
	changeStudentEmail$ = createEffect(() =>
	this.actions.pipe(
		ofType(studentAnalyticsActions.changeStudentEmail),
		withLatestFrom(this.authStore.firebaseToken$),
		concatMap(([action, token]) =>
			this.bobService
				.changeEmail(action.oldEmail, action.newEmail, token)
				.pipe(
					map((response: any) => {
						return studentAnalyticsActions.changeStudentEmailSuccess({
							data: response,
						});
					}),
					catchError(response => {
						return of(studentAnalyticsActions.changeStudentEmailError({error : response.error}))
					})

				),
		),
	),
);
}
