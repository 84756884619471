import { createReducer, on } from '@ngrx/store';
import * as StudentAnalyticsActions from './student-analytics.actions';

export const studentAnalyticsFeatureKey = 'studentAnalytics';

export interface SelectedStudent {
	email: string;
	multimedia: string;
}
export interface StudentAnalyticsState {
	classes;
	selectedStudent: SelectedStudent;
	details;
	questions;
	cohortEvents;
	cohortEventsLoaded;
	studentData: any;
	changeEmailStatus: any;
	changeEmailError: any;
}

export const initialState: StudentAnalyticsState = {
	classes: null,
	selectedStudent: null,
	details: null,
	questions: null,
	cohortEvents: null,
	cohortEventsLoaded: false,
	studentData: {
		lastSlackActive: '--',
		reactions: 0,
		comments: 0,
	},
	changeEmailStatus: null,
	changeEmailError: null,
};

export const studentAnalyticsReducer = createReducer(
	initialState,
	on(StudentAnalyticsActions.getSelectedStudentSuccess, (state, action) => {
		return {
			...state,
			selectedStudent: action.data,
		};
	}),

	on(StudentAnalyticsActions.getInternalProfileSuccess, (state, action) => {
		return {
			...state,
			details: action.data,
		};
	}),
	on(
		StudentAnalyticsActions.getApplicationQuestionsSuccess,
		(state, action) => {
			return {
				...state,
				questions: action.data,
			};
		},
	),
	on(StudentAnalyticsActions.getCohortEventData, state => {
		return {
			...state,
			cohortEventsLoaded: false,
		};
	}),
	on(StudentAnalyticsActions.getCohortEventDataSuccess, (state, action) => {
		return {
			...state,
			cohortEvents: action.data,
			cohortEventsLoaded: true,
		};
	}),

	on(StudentAnalyticsActions.getStudentDataSuccess, (state, action) => {
		return {
			...state,
			studentData: action.data,
		};
	}),
	on(StudentAnalyticsActions.changeStudentEmailSuccess, (state) => {
		return {
			...state,
			changeEmailStatus: true,
		};
	}),
	on(StudentAnalyticsActions.changeStudentEmailError, (state, action) => {
		return {
			...state,
			changeEmailError: action.error,
		};
	}),
	on(StudentAnalyticsActions.resetEmailChange, (state, action) => {
		return {
			...state,
			changeEmailError: null,
			changeEmailStatus: null,
		};
	}),
	
);
