import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { selectCountries, selectGroups } from '../redux/students.selector';

@Component({
	selector: 'app-students-filter',
	templateUrl: './students-filter.component.html',
	styleUrls: ['./students-filter.component.scss'],
})
export class StudentsFilterComponent implements OnInit {
	countries$: Observable<any> = this.store.select(selectCountries);
	groups$: Observable<any> = this.store.select(selectGroups);
	filterType: any;
	filterList: any;
	countryList: any;
	groupList: any;
	selectedItems: any;
	searchTerm = '';
	searchItems: any;
	chipList: any;

	constructor(
		private store: Store<AppState>,
		private navParams: NavParams,
		private popoverController: PopoverController,
	) {}

	ngOnInit() {
		this.filterType = this.navParams.data.filterType;
		this.filterList = this.navParams.data.filterList;
		this.chipList = this.navParams.data.chipList;
		this.searchItems = this.filterList;
		// this.selectedItems = this.filterList;
		this.selectedItems = [];
		// console.log('filterList', this.filterList);
	}

	onItemSelected(item) {
		// const contains = this.selectedItems?.some(
		// 	filterValue => filterValue === item,
		// );
		// if (contains) {
		// 	console.log('contains',item)
		// 	console.log('this.selectedItems',this.selectedItems)

		// 	this.selectedItems = this.selectedItems.filter(
		// 		filterValue => filterValue === item,
		// 	);
		// } else {
		// 	this.selectedItems.push(item);
		// }
			// console.log('this.selectedItems after',this.selectedItems)
			if(this.selectedItems?.includes(item)){
				this.selectedItems = this.selectedItems.filter( el => el!== item)
			}
			else {
				this.selectedItems.push(item)
			}
	}

	onSearch() {
		if (this.searchTerm) {
			this.searchItems = this.filterList.filter(item =>
				item.toLowerCase().startsWith(this.searchTerm.toLowerCase()),
			);
		} else {
			this.searchItems = this.filterList;
		}
	}

	isFilterSelected(item) {
		return this.chipList?.some(filterValue => filterValue === item);
	}

	onDone() {
		if (this.filterType === 'country') {
			this.popoverController.dismiss({
				selectedCountries: this.selectedItems,
			});
		} else if (this.filterType === 'group') {
			this.popoverController.dismiss({
				selectedGroups: this.selectedItems,
			});
		} else if(this.filterType === 'status'){
			this.popoverController.dismiss({
				selectedStatus: this.selectedItems,
			});
		}
	}

	trackByFn(index: number, item: any) {
		return item;
	}
}
