import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { setActiveCohort } from '../cohorts/redux/cohorts-actions';
import {
	selectActiveCohort,
	selectCohortsList
} from '../cohorts/redux/cohorts-selectors';
import * as CourseContentActions from '../course-content/redux/course-content-actions';
import { loadApplications, setApplicationFilters } from '../home/redux/dashboard-actions';
import { ApplicationFilters } from '../home/redux/dashboard-reducer';
import { selectApplicationFilters } from '../home/redux/dashboard-selectors';
import { getStudentList } from '../mentor-analytics/redux/mentor-analytics.actions';
import { loadRequests, setMentorRequestFilters } from '../mentor-requests/redux/mentor-request-actions';
import { selectFilters } from '../mentor-requests/redux/mentor-request-selectors';
import { Cohort } from '../models/dashboard-models';
import { MentorRequestFilters } from '../models/mentor-request-models';
import { AppState } from '../reducers';
import { loadCountryList, loadGroupList, loadStudentsList } from '../students/redux/students.actions';
import Utils from '../Util';

@Component({
	selector: 'app-cohort-selector',
	templateUrl: './cohort-selector.component.html',
	styleUrls: ['./cohort-selector.component.scss'],
})
export class CohortSelectorComponent implements OnInit {
	@Input() page: 'applications' | 'mentors' | 'courseContent';
	cohortsList$ = this.store.pipe(select(selectCohortsList));
	activeCohort$ = this.store.pipe(select(selectActiveCohort));
	mentorRequestFilters$ = this.store.pipe(select(selectFilters));

	mentorRequestFilters: MentorRequestFilters;
	activeCohort: Cohort | null;
	activeCohortId: number | null;
	params: any;
	applicationFilters$: Observable<ApplicationFilters> = this.store.pipe(
		select(selectApplicationFilters),
	);
	applicationFilters: ApplicationFilters;

	constructor(private store: Store<AppState>, private router: ActivatedRoute) { }

	ngOnInit() {
		this.activeCohort$.subscribe(activeCohort => {
			this.activeCohort = activeCohort;
			this.activeCohortId = activeCohort?.id;
		});
		combineLatest([
			this.cohortsList$,
			this.activeCohort$,
			this.router.queryParams,
		])
			.pipe(
				tap(([cohortList, activeCohort, params]) => {
					if (cohortList?.length && !activeCohort && params.cohortId) {
						// No active cohort but we have a cohort ID in the query params.
						// Set the cohort in the query param as the active cohort.
						this.onCohortSwitch(params.cohortId);
					} else if (cohortList?.length && !activeCohort && !params.cohortId) {
						// No active cohort and we don't have a query param either.
						// Set the first cohort from the list as the active cohort.
						this.onCohortSwitch(cohortList[0].id);
					}
				}),
			)
			.subscribe();
		if (this.page === 'applications') {
			this.applicationFilters$
				.pipe(filter(filters => !!filters))
				.subscribe(filters => {
					this.applicationFilters = filters;
				});
		}

		if (this.page === 'mentors') {
			this.mentorRequestFilters$
				.pipe(filter(filters => !!filters))
				.subscribe(filters => {
					this.mentorRequestFilters = filters;
				});
		}
	}

	onCohortSwitch(id: any) {
		const selectedCohortId = parseInt(id, 10);
		
		this.cohortsList$
			.pipe(first())
			.subscribe(
				cohortsList => {
					const selectedCohort = cohortsList.find(
						cohort => cohort.id === selectedCohortId,
					);
					if (selectedCohort) {
						this.store.dispatch(setActiveCohort({ cohort: selectedCohort }));
						// if (this.page === 'applications') {
						this.store.dispatch(
							setApplicationFilters({
								filters: {
									...this.applicationFilters,
									cohortId: selectedCohort.id,
									sort: Utils.CREATED_AT,
									stageIds: [],
									searchValue: '',
									page: 1,
									tags: [],
								},
							}),
						);
						// }
						// else if (this.page === 'mentors') {
						this.store.dispatch(
								setMentorRequestFilters({
									filters: {
										...this.mentorRequestFilters,
										sort: Utils.CREATED_AT,
										stageIds: [],
										searchValue: '',
										page: 1,
									},
								}),
							);
						// }
						// else if (this.page === 'courseContent') {
						// console.log('selectedCohort', selectedCohort)
						// console.log('selectedCohortId', selectedCohortId)
						this.store.dispatch(loadApplications())
						this.store.dispatch(
							CourseContentActions.loadClassesForCohort({
								cohortId: selectedCohortId,
							}),
						);
						this.store.dispatch(CourseContentActions.loadClassModuleList());
						this.store.dispatch(
							CourseContentActions.loadAssignmentsForCohort({
								cohortId: selectedCohortId,
							}),
						);
						this.store.dispatch(
							CourseContentActions.loadSyllabus({
								cohortId: selectedCohortId
							})
						);
						this.store.dispatch(
							CourseContentActions.loadCohortInstructor({
								cohortId: selectedCohortId,
							}),
						);
						this.store.dispatch(
							CourseContentActions.loadCohortAdditionalResource({
								cohortId: selectedCohortId,
							}),
						);
						// }
						this.store.dispatch(setActiveCohort({ cohort: selectedCohort }));
						this.store.dispatch(loadStudentsList());
						this.store.dispatch(loadCountryList());
						this.store.dispatch(loadGroupList());
					}
				});
	}

	trackByFn(index: number, item: any) {
		return item.id;
	}
}
