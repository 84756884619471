import { createAction, props } from '@ngrx/store';
import { StudentFilters } from 'src/app/models/students-page-model';

export const studentsList = createAction('[Students] Load Students List');

export const loadStudentsList = createAction(
	'[Students Effect] Load Students List',
);

export const loadStudentsListwithFilter = createAction(
	'[Students Effect] Load Students List with Filter',
	props<{ filters: StudentFilters }>(),
);

export const studentListLoaded = createAction(
	'[Students Effect] Students List Loaded',
	props<{ students: any }>(),
);

export const emptyStudentList = createAction(
	'[Students Effect] Empty Students List',
);

export const loadCountryList = createAction(
	'[Students Effect] Load Country List',
);

export const countryListLoaded = createAction(
	'[Students Effect] Country List Loaded',
	props<{ countries: any }>(),
);

export const loadGroupList = createAction('[Students Effect] Load Group List');

export const groupListLoaded = createAction(
	'[Students Effect] Group List Loaded',
	props<{ groups: any }>(),
);

export const changeOneGroup = createAction(
	'[Students Effect] Change One Group',
	props<{ studentId: any; groupId: number }>(),
);
export const resetStudentsDetails = createAction(
	'[Student Details Page] Reset Students Details',
);
export const groupChangeProgress = createAction(
	'[Students Action] Group Change Progress',
	props<{ status: boolean }>(),
);

export const groupChangeError = createAction(
	'[Students Action] Group Change Error',
	props<{ error: any }>(),
);

export const groupChnageOrder = createAction(
	'[Students Effect] Group Changed',
	props<{ order: any }>(),
);
export const groupChnageOrderError = createAction(
	'[Students Effect] Group Change Error',
	props<{ error: any }>(),
);
export const changeMultipeGroups = createAction(
	'[Students Effect] Change Multiple Groups',
	props<{ studentIds: any; groupId: number }>(),
);

export const multipleGroupsChanged = createAction(
	'[Students Effect] Multiple Groups Changed',
	props<{ students: any }>(),
);
export const fetchEmailTemplate = createAction(
	'[Students Effect] Get Email template',
	props<{ id: string }>(),
);
export const updateEmailContent = createAction(
	'[Students Page] Update Email Content',
	props<{
		subject: string;
		content: string;
		emailFrom: string;
	}>(),
);
export const studentUpdateError = createAction(
	'[Students Page] Student Update Error',
	props<{ error: any }>(),
);

export const sendEmailProgress = createAction(
	'[Students Action] Send Email Progress',
);
export const studentUpdateProgress = createAction(
	'[Students Action] Student Update Progress',
	props<{ status: boolean }>(),
);
export const genericNotify = createAction(
	'[Students Page] Generic Notify',
	props<{
		student: any;
		content: string;
		subject: string;
		templateType: string;
		emailFrom: string;
	}>(),
);

export const changeMultipeGroupsViaSheet = createAction(
	'[Students Effect] Change Multiple Groups Sheet',
	props<{ studentObj: any }>(),
);

export const changeMultipeGroupsViaFile = createAction(
	'[Students Effect] Change Multiple Groups file',
	props<{ file: FormData }>(),
);

export const bulkGenericNotify = createAction(
	'[Application Page] Bulk Generic Notify',
	props<{
		list: any;
		content: string;
		subject: string;
		templateType: string;
		emailFrom: string;
	}>(),
);

export const oneTimeWelcomeEmailCheck = createAction(
	'[Students Effect] One Time Welcome Email Sending Check',
	props<{ ids: any }>(),
);

export const oneTimeonStartMailCheck = createAction(
	'[Students Effect] One Time Onstart Email Sending Check',
	props<{ ids: any }>(),
);

export const emailSendOrderCreated = createAction(
	'[Students Effect] Email Send Order Created Successfully',
	props<{ order: any }>(),
);

export const emailSendOrderError = createAction(
	'[Students Effect] Email Send Order Error',
	props<{ error: any }>(),
);

export const clearOrderStatus = createAction(
	'[Students Effect] Clear Order Status',
);

export const clearGroupChangeStatus = createAction(
	'[Students Effect] Clear Group Chnage Status',
);

export const uploadErrorStatus = createAction(
	'[Students Effect] Upload Status',
	props<{ uploadStatus: any }>(),
);
export const getExtraDetails = createAction(
	'[Students Effect] Download Extra Details',
	props<{ cohortId: number; filterDates: string[]; sort: string }>(),
);
export const extraDetailsDownloaded = createAction(
	'[Students Effect] Extra Details Of Students Downloaded',
	props<{ details: [] }>(),
);
export const resetExtraDetails = createAction(
	'[Students Action] Reset Extra Details of Students',
);
export const resetDraftEmail = createAction(
	'[Students Action] Reset Draft Email of Students',
);

export const getPaymentMode = createAction(
	'[Students Action] Get Payment Mode of Students',
);

export const gotPaymentMode = createAction(
	'[Students Effect] Payment Mode Loaded',
	props<{ mode: any }>(),
);

export const getPaymentLink = createAction(
	'[Students Action] Get Payment Link of Students',
);

export const paymentLinkLoaded = createAction(
	'[Students Effect] Payment Link Loaded',
	props<{ links: any }>(),
);
