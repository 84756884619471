import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStoreService } from 'auth-lib';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessagesService } from 'util-lib';
import { PeopleRequestBody } from './models/cohort-settings-models';
import {
	AdditionalResource,
	AssignmentAPIBody,
	ClassAPIBody,
	Instructor,
} from './models/course-content-models';
import { StudentFilters } from './models/students-page-model';

@Injectable({ providedIn: 'root' })
export class BobService {
	public headers: any = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};

	// for dev
	private ENDPOINT = environment.endpoint;

	private COHORT_API = environment.url.cohort_url;
	private NOTIFICATION_API = environment.url.notification_url;
	private headerSubject = new BehaviorSubject<boolean>(true);
	public showHeader$ = this.headerSubject.asObservable();
	PROFILE_API: string = environment.url.profile_url;
	CATALOG_API: string = environment.url.catlog_url;
	CLASSID_API: any = environment.url.class_id_url;
	PAYMENT_API: any = environment.url.payment_url;

	constructor(
		public http: HttpClient,
		public auth: AuthStoreService,
		private messageService: MessagesService,
	) {
		this.auth.titles = {
			main: 'BOB',
			extra: 'with',
			sub: 'Learn about what matters. Work on climate change now.',
		};
	}

	public enableHeader() {
		this.headerSubject.next(true);
	}

	public disableHeader() {
		this.headerSubject.next(false);
	}

	getCohortsList(token: string): Observable<any> {
		console.log(this.ENDPOINT);
		return this.http
			.post(
				this.ENDPOINT + 'cohorts/list',
				{
					token,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getApplicationsSummary(cohortId: number, courseId: number): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications_summary',
				{
					cohort_id: cohortId,
					course_id: courseId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	fetchApplications(
		cohortId: number,
		filterDates: string[],
		sort: string,
		page: number,
		stages?: number[],
		tags?: number[],
		search?: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications',
				{
					cohort_id: cohortId,
					filterDates,
					stages,
					sort,
					page,
					tags,
					search,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getApplicationDetails(id: number): Observable<any> {
		return this.http
			.get(this.ENDPOINT + 'application/details/' + id)
			.pipe(shareReplay());
	}

	getApplicationNotes(id: number): Observable<any> {
		return this.http
			.get(this.ENDPOINT + 'application/notes/' + id)
			.pipe(shareReplay());
	}

	addApplicationNote(id: number, note: string): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/add/note',
				{
					id,
					note,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationStage(
		id: number,
		username: string,
		note: string,
		stage: string,
		payment?: number,
		discount?: number,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/update/stage',
				{
					id,
					username,
					note,
					stage,
					payment,
					discount,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationCohort(
		id: number,
		email: string,
		oldCohortId: number,
		newCohortId: number,
		productId: number,
		username: string,
		note: string,
		token: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/update/cohort',
				{
					id,
					email,
					oldCohortId,
					newCohortId,
					productId,
					username,
					note,
					token,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	createPaymentOrder(
		token: string,
		applicationId: number,
		email: string,
		cohortId: number,
		sku: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'order/create',
				{
					token,
					applicationId,
					email,
					sku,
					cohortId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	refundOrder(
		token: string,
		email: string,
		cohortId: number,
		orderId: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'order/refund',
				{
					token,
					email,
					cohortId,
					orderId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getDefaultPipelineStages(): Observable<any> {
		return this.http.get(this.ENDPOINT + 'default/stages').pipe(shareReplay());
	}

	updateCohortOnPaymentLinkSent(
		id: number,
		newCohortId: number,
		productId: number,
		username: string,
		note: string,
		token: string,
		email: string,
		amount: number,
		oldCohortId: number,
	): Observable<any> {
		return this.http
			.put(
				this.ENDPOINT + 'application/update/cohort/change',
				{
					id,
					newCohortId,
					productId,
					username,
					note,
					token,
					email,
					amount,
					oldCohortId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	sendPaymentReminder(
		reminder: {
			name: string;
			email: string;
			cohortId: number;
			courseId: number;
			amount: number;
			cohortDate: number;
			courseName: string;
			weeks: string;
			discountCode: string;
			refundWeek: string;
			signatureUserName: string;
			applicationId: number;
			note: string;
		},
		token: string,
		ccRecipient: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/notify/remind',
				{
					...reminder,
					token,
					ccRecipient,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	updateFirebaseStudentStatus(
		token: string,
		email: string,
		cohortId: number,
		status: number,
	): Observable<any> {
		return this.http
			.put(
				this.ENDPOINT + 'application/update/student/status',
				{
					token,
					email,
					cohortId,
					status,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getAdminRoles(token: string): Observable<any> {
		return this.http
			.get(this.CATALOG_API + 'roles/list', {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	getPeopleList(token: string): Observable<any> {
		return this.http
			.get(this.CATALOG_API + 'people/list', {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	getCourseInstructor(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/role/visible/people`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	getPeopleWithRoles(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/role/all/people`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	assignRole(
		token: string,
		peopleId: number,
		cohortId: number,
		order: number,
		roleId: number,
	): Observable<any> {
		return this.http
			.post(
				this.CATALOG_API + 'coursePeople',
				{
					peopleId,
					cohortId,
					order,
					roleId,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	getPriceListForCohort(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/prices`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	fileUpload(folder: string, file: FormData, token): Observable<any> {
		const headers: any = {
			Authorization: 'Bearer ' + token,
			Accept: 'application/json',
		};
		const url = this.PROFILE_API + `upload/` + folder;
		return this.http
			.post<any>(url, file, { headers: new HttpHeaders(headers) })
			.pipe(shareReplay());
	}

	updateCohortPrice(
		token: string,
		priceId: number,
		price: number,
		timezone: string,
	): Observable<any> {
		let path = `id/${priceId}/price/${price}`;
		if (timezone) {
			path += `/timezone/${encodeURIComponent(timezone)}`;
		}

		return this.http
			.put(this.COHORT_API + path, null, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	removeRoleAssignment(
		token: string,
		cohortId: number,
		roleId: number,
		peopleId: number,
	): Observable<any> {
		return this.http
			.delete(
				this.COHORT_API + `${cohortId}/role/${roleId}/people/${peopleId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.pipe(shareReplay());
	}

	getEmailTemplate(id: string): Observable<any> {
		return this.http
			.get(this.ENDPOINT + 'application/emailTemplate/' + id, {
				headers: new HttpHeaders(this.headers),
			})
			.pipe(shareReplay());
	}

	genericNotify(
		token: string,
		email: string,
		content: string,
		subject: string,
		ccRecipient: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/notify/generic',
				{
					token,
					email,
					content,
					subject,
					ccRecipient,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	loadClassesForCohort(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/module/classes`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	loadSyllabusForCohort(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/syllabus`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	createClassForCohort(classData: any, token: string): Observable<any> {
		return this.http
			.post(
				this.COHORT_API + 'class',
				{
					name: classData.title,
					link: classData.classLink,
					moduleId: classData.moduleId,
					cohortId: classData.cohortId,
					description: classData.description,
					multimedia: classData.image,
					startDateTime: classData.startDateTime,
					endDateTime: classData.endDateTime,
					creatorId: classData.creatorId,
					creator: classData.creator,
					visible: classData.visible,
					zoomLink: classData.zoomLink,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	updateCohortClass(classData: ClassAPIBody, token: string): Observable<any> {
		return this.http
			.put(
				this.COHORT_API + `class/${classData.id}`,
				{
					name: classData.title,
					link: classData.classLink,
					moduleId: classData.moduleId,
					cohortId: classData.cohortId,
					description: classData.description,
					multimedia: classData.image,
					creatorId: classData.creatorId,
					startDateTime: classData.startDateTime,
					endDateTime: classData.endDateTime,
					creator: classData.creator,
					zoomLink: classData.zoomLink,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	deleteClassFromCohort(token: string, classId: number): Observable<any> {
		return this.http
			.delete(this.COHORT_API + `class/${classId}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	createClassContainer(token: string, name: string): Observable<any> {
		return this.http
			.get(this.COHORT_API + `module/${encodeURIComponent(name)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	classModuleList(token: string): Observable<any> {
		return this.http
			.get(this.CATALOG_API + 'modules/list', {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	updateSyllabus(token: string, syllabusDetail: any): Observable<any> {
		return this.http
			.put(this.COHORT_API + `syllabus`, syllabusDetail, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	updateInstructors(token: string, instructors: any): Observable<any> {
		const APIs = [];

		instructors.filter(instructor => {
			let calendlyLink = '';
			try {
				calendlyLink = instructor.extraProfile.filter(
					ep => ep.key === 'Calendly',
				)[0].value;
			} catch (e) { }
			APIs.push(
				this.http.put(
					this.CATALOG_API + `instructor/${instructor.id}`,
					{
						...instructor,
						calendlyLink,
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					},
				),
			);
		});

		return forkJoin(APIs).pipe(shareReplay());
	}

	createAssignmentForCohort(
		assignmentData: AssignmentAPIBody,
		cohortId: number,
		token: string,
	): Observable<any> {
		return this.http
			.post(
				this.COHORT_API + `${cohortId}/assignment`,
				{
					title: assignmentData.title,
					link: assignmentData.assignmentLink,
					type: assignmentData.type,
					name: assignmentData.name,
					description: assignmentData.description,
					multimedia: assignmentData.image,
					mandatory: assignmentData.mandatory,
					startDateTime: assignmentData.startDateTime,
					endDateTime: assignmentData.endDateTime,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	updateCohortAssignment(
		assignmentData: AssignmentAPIBody,
		cohortId: number,
		token: string,
	): Observable<any> {
		const newData = {
			id: assignmentData.id,
			title: assignmentData.title,
			link: assignmentData.assignmentLink,
			type: assignmentData.type,
			name: assignmentData.name,
			description: assignmentData.description,
			multimedia: assignmentData.image,
			mandatory: assignmentData.mandatory,
			startDateTime: assignmentData.startDateTime,
			endDateTime: assignmentData.endDateTime,
		};
		// console.log(newData);

		return this.http.put(
			this.COHORT_API + `${cohortId}/assignment/${assignmentData.id}`,
			{
				id: assignmentData.id,
				title: assignmentData.title,
				link: assignmentData.assignmentLink,
				type: assignmentData.type,
				name: assignmentData.name,
				description: assignmentData.description,
				multimedia: assignmentData.image,
				mandatory: assignmentData.mandatory,
				startDateTime: assignmentData.startDateTime,
				endDateTime: assignmentData.endDateTime,
			},
			{ headers: { Authorization: `Bearer ${token}` } },
		);
	}

	loadAssignmentsForCohort(token: string, cohortId: number): Observable<any> {
		return this.http
			.get(this.COHORT_API + `${cohortId}/assignments`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	deleteAssignmentFromCohort(
		token: string,
		assignmentId: number,
		cohortId: number,
	): Observable<any> {
		return this.http
			.delete(this.COHORT_API + `${cohortId}/assignment/${assignmentId}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	updateInstructor(token: string, instructor: Instructor): Observable<any> {
		return this.http
			.put(this.CATALOG_API + `instructor/${instructor.id}`, instructor, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	deleteInstructor(
		token: string,
		instructorId: number,
		roleId: number,
		cohortId: number,
	): Observable<any> {
		return this.http
			.delete(
				this.COHORT_API + `${cohortId}/role/${roleId}/people/${instructorId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.pipe(shareReplay());
	}

	getCourseQuestions(token: string, courseId: number): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'course/questions',
				{
					token,
					courseId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationTimezone(
		applicationId: number,
		timezone: string,
	): Observable<any> {
		return this.http
			.put(
				this.ENDPOINT + 'application/update/timezone',
				{
					applicationId,
					timezone,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationCountry(
		applicationId: number,
		country: string,
	): Observable<any> {
		return this.http
			.put(
				this.ENDPOINT + 'application/update/country',
				{
					applicationId,
					country,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	loadAdditionalResourcesForCohort(
		token: string,
		cohortId: number,
	): Observable<any> {
		// console.log(`${cohortId}`);

		return this.http
			.get(this.COHORT_API + `${cohortId}/materials`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	addAdditionalResource(
		token: string,
		additionalResource: AdditionalResource[],
	): Observable<any> {
		return this.http
			.post(this.COHORT_API + `material`, additionalResource, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	updateAdditionalResource(
		token: string,
		additionalResource: AdditionalResource,
	): Observable<any> {
		return this.http
			.put(
				this.COHORT_API + `material/${additionalResource.id}`,
				additionalResource,
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.pipe(shareReplay());
	}

	deleteAdditionalResource(
		token: string,
		additionalResourceId: number,
	): Observable<any> {
		return this.http
			.delete(this.COHORT_API + `material/${additionalResourceId}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	getApplicationsAnalytics(
		cohortId: number,
		filterDate: string[],
		tagFilters?: number[],
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications/analytics',
				{
					cohortId,
					filterDate,
					...(tagFilters?.length && { tagFilters }),
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}
	getPaymentDateAnalytics(
		cohortId: number,
		filterDate: string[],
		paymentReceivedDates: string[],
		tagFilters?: number[],
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications/paymentdateanalytics',
				{
					cohortId,
					filterDate,
					paymentReceivedDates,
					...(tagFilters?.length && { tagFilters }),
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationTags(
		id: number,
		username: string,
		note: string,
		tags: Array<string>,
		token: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/update/tags',
				{
					id,
					username,
					note,
					tags,
					token,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getAllTagGroups(): Observable<any> {
		return this.http
			.get(this.ENDPOINT + 'application/tag/groups', {
				headers: new HttpHeaders(this.headers),
			})
			.pipe(shareReplay());
	}

	createPeople(token: string, people: PeopleRequestBody): Observable<any> {
		return this.http
			.post(this.CATALOG_API + `instructor`, people, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	createWeekTimeline(week: any, cohortid: any, token) {
		// console.log(week, cohortid.id, token);
		return this.http
			.post(
				this.COHORT_API + `${cohortid.id}/module/${week.id}/time`,
				{
					startDateTime: week.startDate.getTime(),
					endDateTime: week.endDate.getTime(),
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	deleteWeekTimeline(moduleid: any, cohortid: any, token) {
		return this.http
			.delete(
				this.COHORT_API + `${cohortid.id}/module/${moduleid}`,

				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}
	copyClassService(oldId, newId, token, confirmation) {
		return this.http
			.post(
				this.CLASSID_API + `${oldId}/copy/${newId}`,
				{ override: confirmation },
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	submitClassIdService(id, token) {
		return this.http
			.post(
				this.CLASSID_API + `${id}/new`,
				{},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	checkClassSavedService(id, token) {
		return this.http
			.get(this.CLASSID_API + `alias/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.pipe(shareReplay());
	}

	hideClass(id, token) {
		return this.http
			.post(
				this.COHORT_API + `class/${id}/toggleVisible`,
				{},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.pipe(shareReplay());
	}

	fetchMentorRequests(filters: any): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications',
				{
					pipelineId: filters.pipelineId,
					cohort_id: filters.cohortId,
					sort: filters.sort,
					page: filters.page,
					search: filters.searchValue,
					stages: filters.stageIds,
					filterDates: filters.filterDates,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	fetchMentorReqSummary(cohortId: number, pipelineId: number): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications_summary',
				{
					cohort_id: cohortId,
					pipelineId,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getRequestDetails(id: any): Observable<any> {
		return this.http
			.get(this.ENDPOINT + `request/details/${id}`)
			.pipe(shareReplay());
	}

	scheduleMeetRequest(
		dealid: any,
		mentorName: string,
		fellowName: string,
		recipientEmail: string,
		senderEmail: string,
		scheduleTime: any,
		link: string,
	): Observable<any> {
		return this.http
			.post(
				this.NOTIFICATION_API,
				{
					notificationType: 'email',
					inputJson: JSON.stringify({
						email: recipientEmail,
						subject: 'Feedback on your mentorship meet',
						from: senderEmail,
						template: 'mentor_feedback_template',
						mailVars: {
							name: mentorName,
							fellow: fellowName,
							link,
							dealid,
						},
					}),
					taskDate: scheduleTime,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	sendFeedbackForm(
		mentorName: string,
		fellowName: string,
		recipientEmail: string,
		senderEmail: string,
		link: string,
	): Observable<any> {
		return this.http
			.post(
				this.NOTIFICATION_API,
				{
					notificationType: 'email',
					inputJson: JSON.stringify({
						email: recipientEmail,
						subject: 'Feedback on your mentorship meet',
						from: senderEmail,
						template: 'mentor_feedback_template',
						mailVars: {
							name: mentorName,
							fellow: fellowName,
							link,
						},
					}),
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateMeetIds(fellowId: number, data: any): Observable<any> {
		return this.http
			.put(
				this.ENDPOINT + 'update/meet/id',
				{
					id: fellowId,
					meetData: data,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateApplicationQuestion(applicationId: number, question: string) {
		return this.http
			.put(
				this.ENDPOINT + 'application/update/question',
				{
					applicationId,
					question,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getMentorFeedback(dealid, token) {
		return this.http
			.get(this.PROFILE_API + `deal/${dealid}/feedback`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.pipe(shareReplay());
	}

	deleteNotificationTask(id, token) {
		this.http
			.delete(this.NOTIFICATION_API + `${id[0]}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.pipe(shareReplay())
			.subscribe(value => console.log(value));

		this.http
			.delete(this.NOTIFICATION_API + `${id[1]}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.pipe(shareReplay())
			.subscribe(value => console.log(value));

		return of('12344');
	}

	fetchPaymentReceivedApplications(
		cohortId: number,
		filterDates: string[],
		sort: string,
		page: number,
		stages?: number[],
		tags?: number[],
		search?: string,
	): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications/paymentTable',
				{
					cohort_id: cohortId,
					filterDates,
					stages,
					sort,
					page,
					tags,
					search,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	getAllDownloads(
		cohortId: number,
		filterDates: string[],
		sort: string,
	): Observable<any> {
		return this.http.post(
			this.ENDPOINT + 'download-all',
			{
				cohort_id: cohortId,
				filterDates,
				sort,
			},
			{ headers: new HttpHeaders(this.headers) },
		);
	}

	fetchStudentList(id, token) {
		return this.http.get(this.PROFILE_API + `cohort/${id}/list`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	fetchAllApplications(data): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'applications/deals/all',
				{
					...data,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	updateDealField(id, value, field) {
		this.http
			.post(this.ENDPOINT + 'applications/update/field', {
				id,
				field,
				value,
			})
			.subscribe(res => {
				console.log('resssssssssssssss', res);
			});
	}

	updateStudentReason(cohortId, studentId, reason, token) {
		return this.http.post(
			this.PROFILE_API + 'reason',
			{
				cohortId,
				profileId: studentId,
				reason,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}

	fetchStudentsList(cohortId, token) {
		return this.http.get(this.COHORT_API + `v2/${cohortId}/students`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	fetchStudentsListwithFilter(cohortId, token, studentfilter?: StudentFilters) {
		// console.log('filter',filter)
		return this.http.post(
			this.COHORT_API + `v2/${cohortId}/students`,
			{
				studentfilter,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}

	loadCountryList(cohortId, token) {
		return this.http.get(this.COHORT_API + `${cohortId}/student/countries`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	loadGroupList(cohortId, token) {
		return this.http.get(this.COHORT_API + `${cohortId}/groups`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	updateGroupList(cohortId, obj, token) {
		return this.http.put(this.COHORT_API + `${cohortId}/groups/batch`, obj, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	updateGroupListViaFile(cohortId, file, token) {
		return this.http.put(this.COHORT_API + `${cohortId}/groups/upload`, file, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
			},
		});
	}

	updateSingleGroup(
		cohortId: number,
		studentId: number,
		groupId: number,
		token: string,
	): Observable<any> {
		return this.http
			.put(this.COHORT_API + 'group', {
				cohortId,
				studentId,
				groupId,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.pipe(shareReplay());
	}
	updateMultipleGroups(
		cohortId: number,
		studentIds: [],
		groupId: number,
		token: string,
	): Observable<any> {
		return this.http.put(
			this.COHORT_API + `${cohortId}/group/${groupId}`,
			{ studentIds },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}

	bulkGenericNotify(list: any): Observable<any> {
		return this.http
			.post(
				this.ENDPOINT + 'application/notify/bulk/generic',
				{
					list,
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}
	oneTimeWelcomeEmailCheck(
		cohortId: number,
		studentIds: any,
		token: string,
	): Observable<any> {
		// return
		return this.http.put(
			this.COHORT_API + `${cohortId}/students/welcomeMail/true`,
			{ studentIds },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}
	oneTimeonStartMailCheck(
		cohortId: number,
		studentIds: any,
		token: string,
	): Observable<any> {
		// return
		return this.http.put(
			this.COHORT_API + `${cohortId}/students/onStartMail/true`,
			{ studentIds },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}
	sendOneEmail(
		content: string,
		token: string,
		email: string,
		subject: string,
		from: string,
	): Observable<any> {
		return this.http
			.post(
				this.NOTIFICATION_API,
				{
					notificationType: 'email',
					inputJson: JSON.stringify({
						email,
						subject,
						from,
						template: 'generic_template',
						mailVars: {
							content,
						},
					}),
				},
				{ headers: new HttpHeaders(this.headers) },
			)
			.pipe(shareReplay());
	}

	// Cohort analytics

	JQLQueryCohort(token, script) {
		const urlForSlack =
			this.COHORT_API + script.cohortId + '/slack/class/lastseen';

		const urlForClassBran =
			this.COHORT_API + script.cohortId + '/classes/completions';

		const urlForEventsBran =
			this.COHORT_API + script.cohortId + '/events/attendance';

		const urlForAssignmentsBran =
			this.COHORT_API + script.cohortId + '/submissions/allAssignment';

		const headers: any = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
		};

		return forkJoin([
			this.http.post(
				'https://bob-mixpanel-git-prod-terrado.vercel.app/api/JQL',
				script,
			),
			this.http
				.get(urlForSlack, { headers: new HttpHeaders(headers) })
				.pipe(map((data: any) => data.data)),
			this.http
				.get(urlForClassBran, { headers: new HttpHeaders(headers) })
				.pipe(map((data: any) => data.data)),
			this.http
				.get(urlForEventsBran, { headers: new HttpHeaders(headers) })
				.pipe(map((data: any) => data.data)),
			this.http
				.get(urlForAssignmentsBran, { headers: new HttpHeaders(headers) })
				.pipe(map((data: any) => data.data)),
		]).pipe(shareReplay());
	}

	fetchApplicationQuestions(cohortId, token) {
		const url = this.ENDPOINT + 'course/questions';
		const headers: any = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
		};
		return this.http.post(
			url,
			{ courseId: cohortId, token },
			{ headers: new HttpHeaders(headers) },
		);
	}
	getSingleStudentData(id: number, token: string): Observable<any> {
		return this.http.get(this.PROFILE_API + `id/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	fetchCohortEventData(cohortId, token) {
		return this.http.get(this.COHORT_API + `${cohortId}/student/events`, {
			headers: { Authorization: `Bearer ${token}` },
		});
	}

	fetchCohortStudentOtherData(cohortId, studentId, token) {
		return this.http.get(
			this.COHORT_API + `${cohortId}/student/${studentId}/counts`,
			{
				headers: { Authorization: `Bearer ${token}` },
			},
		);
	}

	getPaymentMode(token, cohortId) {
		https: return this.http.get(
			this.PAYMENT_API + `order/cohort/${cohortId}/list`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}

	createNewModule(moduleName, token) {
		return this.http.get(this.COHORT_API + `module/${moduleName}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
	changeEmail(oldEmail: string, newEmail: string, token: string) {
		console.log(oldEmail, newEmail, token);
		return this.http.put(
			this.COHORT_API + `old/${oldEmail}/new/${newEmail}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
	}

	getPaymentLink(cohortId, token) {
		return this.http.get(this.PAYMENT_API + `price`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
	addNewModule(name: string, duration: number, moduleId: string = "", mandatory: boolean, classEventId: number, authors: any = [], moduleOrder: number, token) {
		return this.http.post(
			this.COHORT_API + "module",
			{
				name: name,
				duration: duration,
				moduleId: moduleId,
				mandatory: mandatory,
				classEventId: classEventId,
				authors: authors,
				moduleOrder: moduleOrder
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)
	}
	updateClassSubModule(name: string, duration: number, moduleId: string = "", mandatory: boolean, classEventId: number, authors: any = [], id: number, moduleOrder: number, token) {
		return this.http.put(
			this.COHORT_API + `module/${id}`,
			{
				name: name,
				duration: duration,
				moduleId: moduleId,
				mandatory: mandatory,
				classEventId: classEventId,
				authors: authors,
				moduleOrder: moduleOrder
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)
	}
	deleteClassSubModule(id: any, token) {
		return this.http.delete(
			this.COHORT_API + `module/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)
	}
	reorderModules(newOrder: any, token) {
		return this.http.put(
			this.COHORT_API + "modules/order",
			[
				...newOrder
			],
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)
	}
	bulkUploadSections(cohortId: number, file: FormData, token): Observable<any> {
		const headers: any = {
			Authorization: 'Bearer ' + token,
			Accept: 'application/json',
		};
		const url = this.COHORT_API + `${cohortId}/sections/upload`;
		return this.http
			.post<any>(url, file, { headers: new HttpHeaders(headers) })
	}
	bulkUploadClassEvent(cohortId: number, file: FormData, token): Observable<any> {
		const headers: any = {
			Authorization: 'Bearer ' + token,
			Accept: 'application/json',
		};
		const url = this.COHORT_API + `${cohortId}/class/event/upload`;
		return this.http
			.post<any>(url, file, { headers: new HttpHeaders(headers) })
	}
	bulkUploadModules(cohortId: number, file: FormData, token): Observable<any> {
		const headers: any = {
			Authorization: 'Bearer ' + token,
			Accept: 'application/json',
		};
		const url = this.COHORT_API + `${cohortId}/class/module/upload`;
		return this.http
			.post<any>(url, file, { headers: new HttpHeaders(headers) })
	}
	bulkUploadCalendarEvents(cohortId: number, file: FormData, token): Observable<any> {
		const headers: any = {
			Authorization: 'Bearer ' + token,
			Accept: 'application/json',
		};
		const url = this.COHORT_API + `${cohortId}/events/upload`;
		return this.http
			.post<any>(url, file, { headers: new HttpHeaders(headers) })
	}
}
