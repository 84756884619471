import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setAllCohortUrl } from '../home/redux/dashboard-actions';
import { AppState } from '../reducers';

@Component({
  selector: 'app-mini-navbar',
  templateUrl: './mini-navbar.component.html',
  styleUrls: ['./mini-navbar.component.scss'],
})
export class MiniNavbarComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  ngOnInit() { }

  setAllCohortUrl() {
    let url = window.location.href;
    const urlSplit = url.split("#");
    console.log(urlSplit[1]);
    this.store.dispatch(setAllCohortUrl({ currentUrl: urlSplit[1] }));
    this.router.navigate(['/all-classes']);
  }
}
