import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'auth-lib';
import { filter } from 'rxjs/operators';
import { PeopleNoteFormModule } from 'terra-ui';

const routes: Routes = [
	{
		path: 'home',
		loadChildren: () =>
			import('./home/home.module').then(m => m.HomePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'login/:loginMode',
		loadChildren: () =>
			import('./login/login.module').then(m => m.LoginPageModule),
		pathMatch: 'prefix',
	},
	{
		path: 'application/details/:cohortId/:id',
		loadChildren: () =>
			import('./application-details/application-details.module').then(
				m => m.ApplicationDetailsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'cohort/settings',
		loadChildren: () =>
			import('./cohort-settings/cohort-settings.module').then(
				m => m.CohortSettingsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'course/content',
		loadChildren: () =>
			import('./course-content/course-content.module').then(
				m => m.CourseContentPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'apps/analytics',
		loadChildren: () =>
			import('./apps-analytics/apps-analytics.module').then(
				m => m.AppsAnalyticsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'mentor/requests',
		loadChildren: () =>
			import('./mentor-requests/mentor-requests.module').then(
				m => m.MentorRequestsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'mentor/analytics',
		loadChildren: () =>
			import('./mentor-analytics/mentor-analytics.module').then(
				m => m.MentorAnalyticsModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'request/details/:id',
		loadChildren: () =>
			import('./request-details/request-details.module').then(
				m => m.RequestDetailsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'slides',
		loadChildren: () =>
			import('./slide-editor/slide-editor.module').then(
				m => m.SlideEditorModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'students',
		loadChildren: () =>
			import('./students/students.module').then(m => m.StudentsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'student/analytics',
		loadChildren: () =>
			import('./student-analytics/student-analytics.module').then(
				m => m.StudentAnalyticsModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'cohort/analytics',
		loadChildren: () =>
			import('./cohort-analytics/cohort-analytics.module').then(
				m => m.CohortAnalyticsPageModule,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'join/course-event/:id',
		loadChildren: () =>
			import('./join/join.module').then(m => m.JoinPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'join/public-event/:id',
		loadChildren: () =>
			import('./join/join.module').then(m => m.JoinPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'join/view-recording/:id',
		loadChildren: () =>
			import('./join/join.module').then(m => m.JoinPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'all-classes',
		loadChildren: () => import('./all-classes/all-classes.module').then(m => m.AllClassesPageModule),
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// preloadingStrategy: PreloadAllModules,
			scrollPositionRestoration: 'disabled',
			useHash: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	url: string;
	constructor(private router: Router) {
		router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				console.log('prev:', event.url);
				this.url = event.url;

				if (
					this.url.includes('organiser') ||
					this.url.includes('edit') ||
					this.url.includes('new')
				) {
					routes[1].redirectTo = this.url;
				} else {
					routes[1].redirectTo = 'home';
				}
				console.log(routes);
			});
	}
}
