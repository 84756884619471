import { createReducer, on } from '@ngrx/store';
import * as CohortSettingsActions from './cohort-settings-actions';
import { CohortSettingsModel, Role } from '../../models/cohort-settings-models';

export const cohortSettingsKey = 'cohort-settings';

export const initialCohortSettingsState: CohortSettingsModel = {
	selectedCohort: null,
	roles: [],
	rolesError: null,
	rolesLoaded: false,
	people: [],
	peopleLoaded: false,
	peopleError: null,
	peopleWithRoles: [],
	peopleWithRolesLoaded: false,
	peopleWithRoleError: null,
	roleAssigned: null,
	roleAssignmentError: null,
	priceListForCohort: [],
	priceListForCohortLoaded: false,
	priceListForCohortError: null,
	cohortPriceUpdate: null,
	cohortPriceUpdateError: null,
	roleAssignmentRemoved: false,
	removeRoleAssignmentError: null,
	peopleAdded: false,
	peopleAddError: null,
};

export const cohortSettingsReducer = createReducer(
	initialCohortSettingsState,
	on(CohortSettingsActions.setCohort, (state, action) => ({
		...state,
		selectedCohort: action.cohort,
	})),
	on(CohortSettingsActions.rolesListLoaded, (state, action) => ({
		...state,
		roles: action.roles,
		rolesLoaded: true,
		rolesError: null,
	})),
	on(CohortSettingsActions.rolesListError, (state, action) => ({
		...state,
		rolesLoaded: true,
		rolesError: action.error,
	})),
	on(CohortSettingsActions.peopleListLoaded, (state, action) => ({
		...state,
		people: action.people,
		peopleLoaded: true,
		peopleError: null,
	})),
	on(CohortSettingsActions.peopleListError, (state, action) => ({
		...state,
		peopleLoaded: true,
		peopleError: action.error,
	})),
	on(CohortSettingsActions.peopleWithRolesLoaded, (state, action) => ({
		...state,
		peopleWithRoles: action.peopleWithRoles,
		peopleWithRolesLoaded: true,
		peopleWithRoleError: null,
	})),
	on(CohortSettingsActions.peopleWithRolesError, (state, action) => ({
		...state,
		peopleWithRolesLoaded: true,
		peopleWithRoleError: action.error,
	})),
	on(CohortSettingsActions.roleAssigned, (state, action) => ({
		...state,
		roleAssigned: action.response,
		roleAssignmentError: null,
	})),
	on(CohortSettingsActions.roleAssignmentError, (state, action) => ({
		...state,
		roleAssigned: null,
		roleAssignmentError: action.error,
	})),
	on(CohortSettingsActions.priceListForCohortLoaded, (state, action) => ({
		...state,
		priceListForCohort: action.priceList,
		priceListForCohortLoaded: true,
		priceListForCohortError: null,
	})),
	on(CohortSettingsActions.priceListForCohortError, (state, action) => ({
		...state,
		priceListForCohortLoaded: true,
		priceListForCohortError: action.error,
	})),
	on(CohortSettingsActions.updateCohortPrice, (state, action) => ({
		...state,
		cohortPriceUpdate: null,
		cohortPriceUpdateError: null,
	})),
	on(CohortSettingsActions.cohortPriceUpdated, (state, action) => ({
		...state,
		cohortPriceUpdate: action.respone,
		cohortPriceUpdateError: null,
	})),
	on(CohortSettingsActions.cohortPriceUpdateError, (state, action) => ({
		...state,
		cohortPriceUpdateError: action.error,
	})),
	on(CohortSettingsActions.removeRoleAssignment, state => ({
		...state,
		roleAssignmentRemoved: false,
		roleAssignmentError: null,
	})),
	on(CohortSettingsActions.roleAssignmentRemoved, (state, action) => ({
		...state,
		roleAssignmentRemoved: !!action.respone,
		roleAssignmentError: null,
	})),
	on(CohortSettingsActions.removeRoleAssignmentError, (state, action) => ({
		...state,
		roleAssignmentRemoved: false,
		roleAssignmentError: action.error,
	})),
	on(CohortSettingsActions.peopleAdded, (state, action) => ({
		...state,
		peopleAdded: !!action.response,
		peopleAddError: null,
		people: [...state.people, action.response?.course],
	})),
	on(CohortSettingsActions.peopleAddError, (state, action) => ({
		...state,
		peopleAdded: false,
		peopleAddError: action.error,
	})),
);
