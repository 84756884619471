import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import {
	orderError,
	orderStatus,
	selectEmailContent,
	selectEmailfrom,
	selectEmailSubject,
	selectStudentUpdateError,
	selectStudentUpdateStatus,
} from '../redux/students.selector';
import * as Handlebars from 'handlebars';
import { LoadingService, MessagesService } from 'util-lib';
import { format } from 'date-fns';
import * as studentsActions from '../redux/students.actions';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-students-email-modal',
	templateUrl: './students-email-modal.component.html',
	styleUrls: ['./students-email-modal.component.scss'],
})
export class StudentsEmailModalComponent implements OnInit, OnDestroy {
	constructor(
		public modalController: ModalController,
		private store: Store<AppState>,
		private messageService: MessagesService,
	) {}
	@Input() activeCohort: any;
	@Input() emailList: any;
	@Input() nameList: any;
	@Input() groupList: any;
	@Input() selectedTemplateName: string;

	selectStudentUpdateStatus$ = this.store.select(selectStudentUpdateStatus);
	emailContent$ = this.store.select(selectEmailContent);
	emailSubject$ = this.store.select(selectEmailSubject);
	updateError$ = this.store.select(selectStudentUpdateError);
	orderStatus$ = this.store.select(orderStatus);
	orderError$ = this.store.select(orderError);
	emailFrom$ = this.store.select(selectEmailfrom);

	// public editorConfig = {
	// 	enterMode: 1,
	// 	indentOffset: 1,
	// 	autoParagraph: false,
	// };
	public editorConfig = {
		removeButtons: '',
		enterMode: 2,
		height: '320px',
		autoParagraph: false,
		entities: false,
		entities_additional: '',
		extraPlugins: 'emoji',
		allowedContent: true,
		extraAllowedContent: '*{*}',
		// toolbar: [
		// 	{
		// 		name: 'basicstyles',
		// 		items: [
		// 			'Bold',
		// 			'Italic',
		// 			'Underline',
		// 			'BulletedList',
		// 			'Link',
		// 			'EmojiPanel',
		// 		],
		// 	},
		// ],
	};
	emailContent = '';
	emailSubject = '';
	emailFrom = '';
	showMailSendBtn = false;
	sendingPrgress = false;
	updateProgress: boolean;

	updateEmailContent() {
		this.emailFrom$.subscribe(sender => {
			if (sender) {
				console.log(sender);
				this.emailFrom = sender;
			}
		});
		this.store.dispatch(
			studentsActions.updateEmailContent({
				subject: this.emailSubject,
				content: this.emailContent,
				emailFrom: this.emailFrom,
			}),
		);
		console.log(format(new Date(this.activeCohort.cohortDate), 'eeee, MMMM d'));
	}

	ngOnInit() {
		this.updateProgress = false;
		this.showMailSendBtn = false;
		console.log('email List', this.emailList);
		this.emailFrom$.subscribe(sender => {
			this.emailFrom = sender;
		});
		this.emailSubject$.pipe().subscribe(data => {
			if (data) {
				console.log('data', data);
				const template = Handlebars.compile(data);
				const subject = template({
					courseName: this.activeCohort.courseName,
				});
				this.emailSubject = subject;
			}
		});
		this.emailContent$.subscribe(data => {
			if (data) {
				if (this.selectedTemplateName === 'Two Weeks Out Email') {
					const content = data;
					// const template = Handlebars.compile(data);
					// const content = template({
					// 	courseName: this.activeCohort.courseName,
					// 	startDate: format(
					// 		new Date(this.activeCohort.cohortDate),
					// 		'd, MMMM d',
					// 	),
					// });
					this.emailContent = content;
					if (this.emailSubject) {
						this.showMailSendBtn = true;
					}
				} else if (this.selectedTemplateName === 'Instructors Intro Email') {
					const content = data;
					// const template = Handlebars.compile(data);
					// const content = template
					// ({
					// 	groupName: this.groupList[0],
					// 	startDate: format(
					// 		new Date(this.activeCohort.cohortDate),
					// 		'd, MMMM d',
					// 	),
					// });
					this.emailContent = content;
					if (this.emailSubject) {
						this.showMailSendBtn = true;
					}
				} else {
					const content = data;

					// const template = Handlebars.compile(data);
					// const content = template({
					// 	courseName: this.activeCohort.courseName,
					// 	studentName: this.nameList[0],
					// 	startDate: format(
					// 		new Date(this.activeCohort.cohortDate),
					// 		'd, MMMM d',
					// 	),
					// });
					this.emailContent = content;
					if (this.emailSubject) {
						this.showMailSendBtn = true;
					}
				}
			}
		});
	}
	async closeModal(clear: boolean) {
		await this.modalController.dismiss({ clearStudentDetails: clear });
	}
	sendMail() {
		this.sendingPrgress = true;
		console.log(this.emailContent.length);
		if (this.emailContent.length > 14750){
			this.showErrorToast('Email too long to send!');
			return;
		}
		if (this.emailList.length === 0) {
			this.showErrorToast('Already Sent email to the selected student');
		}
		if (this.emailList.length === 1) {
			console.log('one mail at a time');
			this.store.dispatch(studentsActions.sendEmailProgress());
			this.store.dispatch(
				studentsActions.bulkGenericNotify({
					list: this.emailList,
					content: this.emailContent,
					subject: this.emailSubject,
					templateType: this.selectedTemplateName,
					emailFrom: this.emailFrom,
				}),
			);

			this.orderStatus$.subscribe(status => {
				console.log(status, this.sendingPrgress);
				if (status && this.sendingPrgress) {
					console.log('close the modal');
					this.sendingPrgress = false;
					const newList = [];
					this.emailList.forEach(element => {
						newList.push(element.studentId);
					});
					console.log(newList);
					if (this.selectedTemplateName === 'Two Weeks Out Email') {
						this.store.dispatch(
							studentsActions.oneTimeonStartMailCheck({ ids: newList }),
						);
					} else if (this.selectedTemplateName === 'Welcome Email') {
						this.store.dispatch(
							studentsActions.oneTimeWelcomeEmailCheck({ ids: newList }),
						);
					}
					this.store.dispatch(studentsActions.resetDraftEmail());
					this.closeModal(true);
				}
			});
			this.orderError$.pipe(filter(error => !!error)).subscribe(error => {
				this.sendingPrgress = false;
				this.showErrorToast('Failed to send email' + error);
				this.closeModel(true);
			});
		} else if (this.emailList.length > 1) {
			console.log('Many mails at a time');
			this.store.dispatch(studentsActions.sendEmailProgress());
			this.store.dispatch(
				studentsActions.bulkGenericNotify({
					list: this.emailList,
					content: this.emailContent,
					subject: this.emailSubject,
					templateType: this.selectedTemplateName,
					emailFrom: this.emailFrom,
				}),
			);
			this.orderStatus$.subscribe(status => {
				console.log(status, this.sendingPrgress);
				if (status && this.sendingPrgress) {
					console.log('successful request', this.emailList);
					this.sendingPrgress = false;
					const newList = [];
					this.emailList.forEach(element => {
						newList.push(element.studentId);
					});
					console.log(newList);
					if (this.selectedTemplateName === 'Two Weeks Out Email') {
						this.store.dispatch(
							studentsActions.oneTimeonStartMailCheck({ ids: newList }),
						);
					} else if (this.selectedTemplateName === 'Welcome Email') {
						this.store.dispatch(
							studentsActions.oneTimeWelcomeEmailCheck({ ids: newList }),
						);
					}
					this.store.dispatch(studentsActions.resetDraftEmail());
					this.closeModal(true);
				}
			});
			this.orderError$.pipe(filter(error => !!error)).subscribe(error => {
				this.sendingPrgress = false;
				this.showErrorToast('Failed to send email' + error);
				this.closeModel(true);
			});
		}
	}
	showErrorToast(message) {
		console.log(message);
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			type: "error",
			title1: 'Error',
			title2: message,
		});
	}
	showSuccessToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			title1: 'Success',
			title2: message,
		});
	}
	ngOnDestroy(): void {
		console.log('destroyed');
		this.emailContent = '';
		this.emailSubject = '';
		this.showMailSendBtn = false;
	}

	async closeModel(clear: boolean) {
		this.store.dispatch(studentsActions.resetDraftEmail());
		await this.modalController.dismiss({ closeAllModals: clear });
	}
}
