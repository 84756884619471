import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-event-attendance',
	templateUrl: './event-attendance.component.html',
	styleUrls: ['./event-attendance.component.scss'],
})
export class EventAttendanceComponent implements OnInit {
	@Input() eventsData: any;

	constructor() {}

	ngOnInit() {}
}
