import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { map } from 'rxjs/operators';
import { selectActiveCohort } from 'src/app/cohorts/redux/cohorts-selectors';
import { AppState, PeopleNoteFormComponent } from 'terra-ui';
@Component({
	selector: 'app-student-observations',
	templateUrl: './student-observations.component.html',
	styleUrls: ['./student-observations.component.scss'],
})
export class StudentObservationsComponent implements OnInit {
	@Input() profileID;

	isObservableEnable$ = this.store.pipe(select(selectActiveCohort)).pipe(
		map((profile: any) => {
			let roles: any = [];
			if (profile?.roles && profile.roles.length > 0) {
				roles = profile.roles;
			}

			return (
				roles.indexOf('Instructor') > -1 || roles.indexOf('Course Admin') > -1
			);
		}),
	);
	constructor(
		public modalController: ModalController,
		public authStore: AuthStoreService,
		private store: Store<AppState>,
	) {}

	ngOnInit() {}

	async openObservation() {
		const modal = await this.modalController.create({
			component: PeopleNoteFormComponent,
			cssClass: 'people-noteform-modal',
			backdropDismiss: true,
			componentProps: {
				studentID: this.profileID,
				pageName: 'BOB',
			},
		});
		// modal.onDidDismiss().then((settings: any) => {
		// 	const { data } = settings;
		//
		// });
		return await modal.present();
	}

	async editObservation(observationData) {
		console.log('observationData', observationData);
		const modal = await this.modalController.create({
			component: PeopleNoteFormComponent,
			cssClass: 'people-noteform-modal',
			backdropDismiss: true,
			componentProps: {
				studentID: this.profileID,
				pageName: 'BOB',
				noteValue: observationData,
			},
		});
		// modal.onDidDismiss().then((settings: any) => {
		// 	const { data } = settings;
		//
		// });
		return await modal.present();
	}
}
