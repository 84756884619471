import { createReducer, on } from '@ngrx/store';
import { TagGroups } from 'src/app/models/dashboard-models';
import * as ApplicationDetailsActions from './application-details-actions';

export const applicationDetailsKey = 'application-details';

export interface ApplicationDetailsState {
	details: any;
	notes: any;
	loaded: boolean;
	updateInProgress: boolean;
	updateError: any;
	noteProgress: boolean;
	orderStatus: any;
	orderError: any;
	refundStatus: any;
	refundError: any;
	remindProgress: boolean;
	remindError: any;
	firebaseStatusUpdated: boolean;
	emailSubject: string;
	emailContent: string;
	timezoneUpdateStatus: any;
	timezoneUpdateError: any;
	countryUpdateStatus: any;
	countryUpdateError: any;
	tags: Array<TagGroups>;
	tagStatus: boolean;
	tagError: any;
	isCompletedSendingMail: boolean
}

export const initialApplicationDetailsState: ApplicationDetailsState = {
	details: null,
	notes: null,
	loaded: false,
	updateInProgress: false,
	updateError: null,
	orderStatus: null,
	orderError: null,
	noteProgress: false,
	refundStatus: null,
	refundError: null,
	remindProgress: false,
	remindError: null,
	firebaseStatusUpdated: false,
	emailSubject: '',
	emailContent: '',
	timezoneUpdateStatus: null,
	timezoneUpdateError: null,
	countryUpdateStatus: null,
	countryUpdateError: null,
	tags: [],
	tagStatus: false,
	tagError: null,
	isCompletedSendingMail: null
};

export const applicationDetailsReducer = createReducer(
	initialApplicationDetailsState,
	on(ApplicationDetailsActions.setApplicationDetails, (state, action) => ({
		...state,
		details: action.application,
		loaded: !!action.application,
	})),
	on(ApplicationDetailsActions.setNotes, (state, action) => ({
		...state,
		notes: action.notes,
	})),
	on(
		ApplicationDetailsActions.resetApplicationDetails,
		state => initialApplicationDetailsState,
	),
	on(ApplicationDetailsActions.addNote, state => ({
		...state,
		noteProgress: true,
	})),
	on(ApplicationDetailsActions.updateApplicationCohort, state => ({
		...state,
		updateInProgress: true,
		updateError: null,
	})),
	on(ApplicationDetailsActions.updateApplicationStage, (state, action) => ({
		...state,
		updateInProgress: true,
		updateError: null,
	})),
	on(
		ApplicationDetailsActions.updateCohortOnPaymentLinkSent,
		(state, action) => ({
			...state,
			updateInProgress: true,
			updateError: null,
		}),
	),
	on(ApplicationDetailsActions.updateApplicationProgress, (state, action) => ({
		...state,
		updateInProgress: action.status,
	})),
	on(ApplicationDetailsActions.applicationUpdateError, (state, action) => ({
		...state,
		updateInProgress: false,
		updateError: action.error,
	})),
	on(ApplicationDetailsActions.noteProgress, (state, action) => ({
		...state,
		noteProgress: action.status,
	})),
	on(ApplicationDetailsActions.orderCreated, (state, action) => ({
		...state,
		orderStatus: action.order,
		orderError: null,
	})),
	on(ApplicationDetailsActions.orderError, (state, action) => ({
		...state,
		orderError: action.error,
	})),
	on(ApplicationDetailsActions.refundPaymentOrder, (state, action) => ({
		...state,
		refundStatus: null,
		refundError: null,
	})),
	on(ApplicationDetailsActions.orderRefunded, (state, action) => ({
		...state,
		refundStatus: action.status,
		refundError: null,
	})),
	on(ApplicationDetailsActions.refundError, (state, action) => ({
		...state,
		refundError: action.error,
	})),
	on(ApplicationDetailsActions.remindPayment, state => ({
		...state,
		remindProgress: true,
		remindError: null,
	})),
	on(ApplicationDetailsActions.paymentRemindProgress, (state, action) => ({
		...state,
		remindProgress: action.status,
		remindError: null,
	})),
	on(ApplicationDetailsActions.remindError, (state, action) => ({
		...state,
		remindProgress: false,
		remindError: action.error,
	})),
	on(ApplicationDetailsActions.updateFirebaseStudentStatus, state => ({
		...state,
		firebaseStatusUpdated: false,
	})),
	on(ApplicationDetailsActions.firebaseUpdateProgress, (state, action) => ({
		...state,
		firebaseStatusUpdated: action.status,
	})),
	on(ApplicationDetailsActions.updateEmailContent, (state, action) => ({
		...state,
		emailSubject: action.subject,
		emailContent: action.content,
	})),
	on(ApplicationDetailsActions.updateTimezone, (state, action) => ({
		...state,
		timezoneUpdateStatus: null,
		timezoneUpdateError: null,
	})),
	on(ApplicationDetailsActions.timezoneUpdateStatus, (state, action) => ({
		...state,
		timezoneUpdateStatus: action.status,
	})),
	on(ApplicationDetailsActions.timezoneUpdateError, (state, action) => ({
		...state,
		timezoneUpdateError: action.error,
	})),
	on(ApplicationDetailsActions.updateCountry, (state, action) => ({
		...state,
		countryUpdateStatus: null,
		countryUpdateError: null,
	})),
	on(ApplicationDetailsActions.countryUpdateStatus, (state, action) => ({
		...state,
		countryUpdateStatus: action.status,
	})),
	on(ApplicationDetailsActions.countryUpdateError, (state, action) => ({
		...state,
		countryUpdateError: action.error,
	})),
	on(ApplicationDetailsActions.setAllTags, (state, action) => ({
		...state,
		tags: action.tags,
		tagStatus: true,
		tagError: null
	})),
	on(ApplicationDetailsActions.updateTagProgress, (state, action) => ({
		...state,
		tagStatus: action.status,
	})),
	on(ApplicationDetailsActions.tagUpdateError, (state, action) => ({
		...state,
		tagStatus: false,
		tagError: action.error,
	})),
	on(ApplicationDetailsActions.genericEmailSuccess, (state, action) => {
		console.log("&&&&&&&&&&&&",action)
		return {
		...state,
		updateInProgress: action.status,
		isCompletedSendingMail: action.isCompletedSendingMail
	}}),
);
