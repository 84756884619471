import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import { selectActiveCohort } from 'src/app/cohorts/redux/cohorts-selectors';
import { AppState } from 'src/app/reducers';
import * as Handlebars from 'handlebars';
import * as StudentsActions from './students.actions';
import { format } from 'date-fns';

@Injectable()
export class StudentsEffects {
	constructor(
		private actions: Actions,
		private store: Store<AppState>,
		private bobService: BobService,
		private authStore: AuthStoreService,
	) {}

	loadStudentsList = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.loadStudentsList),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService.fetchStudentsList(activeCohort?.id, token).pipe(
					map(response => {
						return StudentsActions.studentListLoaded({
							students: response,
						});
					}),
				),
			),
		),
	);

	loadStudentsListwithFilter = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.loadStudentsListwithFilter),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.fetchStudentsListwithFilter(activeCohort?.id, token, action.filters)
					.pipe(
						map(response => {
							return StudentsActions.studentListLoaded({
								students: response,
							});
						}),
					),
			),
		),
	);

	loadCountryList = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.loadCountryList),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService.loadCountryList(activeCohort?.id, token).pipe(
					map(response => {
						console.log('response', response);
						return StudentsActions.countryListLoaded({
							countries: response,
						});
					}),
				),
			),
		),
	);

	loadGroupList = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.loadGroupList),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService.loadGroupList(activeCohort?.id, token).pipe(
					map(response => {
						console.log('response', response);
						return StudentsActions.groupListLoaded({
							groups: response,
						});
					}),
				),
			),
		),
	);
	changeOneGroup = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.changeOneGroup),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.updateSingleGroup(
						activeCohort.id,
						action.studentId,
						action.groupId,
						token,
					)
					.pipe(
						map(response => {
							console.log('changed');
							return StudentsActions.groupChnageOrder({
								order: response.message,
							});
						}),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								StudentsActions.groupChnageOrderError({
									error: response.error.error,
								}),
							);
						}),
					),
			),
		),
	);
	changeMultipeGroups = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.changeMultipeGroups),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.updateMultipleGroups(
						activeCohort.id,
						action.studentIds,
						action.groupId,
						token,
					)
					.pipe(
						map(response => {
							console.log('response', response);
							return StudentsActions.groupChnageOrder({
								order: response.message,
							});
						}),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								StudentsActions.groupChnageOrderError({
									error: response.error.error,
								}),
							);
						}),
					),
			),
		),
	);
	fetchEmailTemplate$ = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.fetchEmailTemplate),
			mergeMap(({ id }) =>
				this.bobService.getEmailTemplate(id).pipe(
					map(response => {
						console.log(response);
						return StudentsActions.updateEmailContent({
							subject: response.subject,
							content: response.body,
							emailFrom: response.tfrommail,
						});
					}),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							StudentsActions.studentUpdateError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);
	genericNotify$ = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.genericNotify),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.authStore.user$,
				this.store.select(selectActiveCohort),
			),
			map(
				([
					{ student, content, subject, templateType, emailFrom },
					token,
					user,
					activeCohort,
				]) => {
					const template = Handlebars.compile(content);
					console.log('paymentLink', student.paymentLink);
					const finalContent = template({
						courseName: activeCohort.courseName,
						startDate: format(
							new Date(activeCohort.cohortDate),
							'eeee, MMMM d',
						),
						studentName: student.fullName,
						firstName: student.firstName,
						paymentLink: student.payLink,

						// groupName: student.groupName,
						SenderNameSign: user.displayName,
						sendersName: user.displayName,
					});
					content = finalContent;
					let from = emailFrom;
					if (templateType === 'Instructors Intro Email') {
						from = user.email;
					}
					// console.log(content, token, student.email, subject)
					return [content, token, student.email, subject, from];
				},
			),

			mergeMap(([content, token, email, subject, from]) =>
				this.bobService.sendOneEmail(content, token, email, subject, from).pipe(
					map(response => {
						// console.log(content, token, email, subject)
						return StudentsActions.emailSendOrderCreated({
							order: response.Message,
						});
					}),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							StudentsActions.emailSendOrderError({
								error: response.error.error,
							}),
						);
					}),
				),
			),
		),
	);
	changeMultipeGroupsViaSheet = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.changeMultipeGroupsViaSheet),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.updateGroupList(activeCohort.id, action.studentObj, token)
					.pipe(
						map(response => {
							console.log('response', response);

							this.store.dispatch(StudentsActions.loadStudentsList());

							return StudentsActions.groupChangeProgress({
								status: false,
							});
						}),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								StudentsActions.groupChangeError({
									error: response.error.error || response.error,
								}),
							);
						}),
					),
			),
		),
	);

	changeMultipeGroupsViaFile = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.changeMultipeGroupsViaFile),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.updateGroupListViaFile(activeCohort.id, action.file, token)
					.pipe(
						map(response => {
							console.log('response', response);

							this.store.dispatch(StudentsActions.loadStudentsList());

							return (
								StudentsActions.groupChangeProgress({
									status: false,
								}),
								StudentsActions.uploadErrorStatus({
									uploadStatus: 'success',
								})
							);
						}),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								StudentsActions.groupChangeError({
									error: response.error.error || response.error,
								}),
								StudentsActions.uploadErrorStatus({
									uploadStatus: 'failed',
								}),
							);
						}),
					),
			),
		),
	);

	bulkGenericNotify$ = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.bulkGenericNotify),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.authStore.user$,
				this.store.select(selectActiveCohort),
			),
			map(
				([
					{ list, content, subject, templateType, emailFrom },
					token,
					user,
					activeCohort,
				]) => {
					const newList = [];
					let from = emailFrom;
					if (
						templateType === 'Instructors Intro Email' ||
						templateType === 'change email'
					) {
						from = user.email;
					}
					// console.log(list);
					let tempFirstName;
					list.forEach(element => {
						tempFirstName = element.firstName;
						// console.log('paymentLink', element);

						const compiledTemplate = Handlebars.compile(content);
						const finalContent = compiledTemplate({
							courseName: activeCohort.courseName,
							startDate: format(
								new Date(activeCohort.cohortDate),
								'eeee, MMMM d',
							),
							studentName: element.fullName,
							// groupName: element.groupName,
							firstName: element.firstName,
							paymentLink: element.linkDetails,
							SenderNameSign: user.displayName,
							sendersName: user.displayName,
						});
						const tempObj = {
							ccRecipient: user.email,
							content: finalContent,
							email: element.email,
							subject,
							token,
							from,
						};
						newList.push(tempObj);
					});
					const paymentReminderlink = list[0].linkDetails;
					const template = Handlebars.compile(content);
					const tempContent = template({
						firstName: tempFirstName,
						courseName: activeCohort.courseName,
						startDate: format(
							new Date(activeCohort.cohortDate),
							'eeee, MMMM d',
						),
						studentName: user.displayName,
						// groupName: element.groupName,
						SenderNameSign: user.displayName,
						paymentLink: paymentReminderlink,
						sendersName: user.displayName,
					});
					const loggedInUser = {
						content: tempContent,
						email: user.email,
						subject,
						token,
						from,
					};
					// console.log(newList);
					newList.push(loggedInUser);
					return newList;
				},
			),
			mergeMap(list =>
				this.bobService.bulkGenericNotify(list).pipe(
					map(response =>
						StudentsActions.emailSendOrderCreated({
							order: response.data,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							StudentsActions.emailSendOrderError({
								error: response.error.error,
							}),
						);
					}),
				),
			),
		),
	);
	oneTimeWelcomeEmailCheck = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.oneTimeWelcomeEmailCheck),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			tap(([action, token, activeCohort]) => {
				console.log(action.ids, activeCohort, token);
			}),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.oneTimeWelcomeEmailCheck(activeCohort?.id, action.ids, token)
					.pipe(
						map(response => {
							return StudentsActions.studentListLoaded({
								students: response,
							});
						}),
					),
			),
		),
	);
	oneTimeonStartMailCheck = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.oneTimeonStartMailCheck),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.oneTimeonStartMailCheck(activeCohort?.id, action.ids, token)
					.pipe(
						map(response => {
							return StudentsActions.studentListLoaded({
								students: response,
							});
						}),
					),
			),
		),
	);
	getExtraDetails = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.getExtraDetails),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService
					.getAllDownloads(action.cohortId, action.filterDates, action.sort)
					.pipe(
						map(response => {
							return StudentsActions.extraDetailsDownloaded({
								details: response.data,
							});
						}),
					),
			),
		),
	);

	getPaymentMode = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.getPaymentMode),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService.getPaymentMode(token, activeCohort?.id).pipe(
					map(response => {
						console.log('*****mode***', response);
						return StudentsActions.gotPaymentMode({
							mode: response,
						});
					}),
				),
			),
		),
	);

	getPaymentLink = createEffect(() =>
		this.actions.pipe(
			ofType(StudentsActions.getPaymentLink),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, token, activeCohort]) =>
				this.bobService.getPaymentLink(activeCohort?.id, token).pipe(
					map(response => {
						console.log('response', response);
						return StudentsActions.paymentLinkLoaded({
							links: response,
						});
					}),
				),
			),
		),
	);
}
