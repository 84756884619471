import { createAction, props } from '@ngrx/store';
import { FormQuestion } from 'src/app/models/dashboard-models';
import { Cohort } from '../../models/dashboard-models';
import { ApplicationFilters } from './dashboard-reducer';

export const loadCohortsList = createAction(
	'[Dashboard Effect] Load Cohorts List',
);

export const cohortsLoaded = createAction(
	'[Dashboard Effect] Cohorts Loaded',
	props<{ cohorts: any }>(),
);

export const resetDashboard = createAction(
	'[Dashboard Effect] Reset Dashboard',
);

export const setActiveCohort = createAction(
	'[Dashboard Effect] Set Active Cohort',
	props<{ cohort: Cohort }>(),
);

export const setApplicationFilters = createAction(
	'[Dashboard Effect] Set Application Filters',
	props<{ filters: ApplicationFilters }>(),
);

// Applications Summary Actions
export const loadApplicationsSummary = createAction(
	'[Dashboard Effect] Load Applications Summary',
);

export const applicationsSummaryLoaded = createAction(
	'[Dashboard Effect] Applications Summary Loaded',
	props<{ summary: any }>(),
);

export const applicationsSummaryError = createAction(
	'[Dashboard Effect] Applications Summary Error',
	props<{ error: any }>(),
);

// Applications Actions
export const loadApplications = createAction(
	'[Dashboard Effect] Load Applications',
);

export const loadPaymentReceivedApplications = createAction(
	'[Dashboard Effect] Load Applications With Respect to Payment Received Date',
);
export const paginateApplications = createAction(
	'[Dashboard Effect] Paginate Applications',
);

export const paginatePaymentReceivedApplications = createAction(
	'[Dashboard Effect] Paginate Applications With Respect to Payment Received Date Applications',
);

export const applicationsPaginated = createAction(
	'[Dashboard Effect] Applications Paginated',
	props<{ applications: any }>(),
);

export const paginatePaymentReceivedPaginated = createAction(
	'[Dashboard Effect] Applications W.R.T. Payment Date Paginated',
	props<{ applications: any }>(),
);
export const applicationsLoaded = createAction(
	'[Dashboard Effect] Applications Loaded',
	props<{ applications: any }>(),
);

export const PaymentReceivedApplicationsLoaded = createAction(
	'[Dashboard Effect] Applications W.R.T. Payment Date Loaded',
	props<{ applications: any }>(),
);
export const applicationsError = createAction(
	'[Dashboard Effect] Applications Error',
	props<{ error: any }>(),
);

export const refetchApplications = createAction(
	'[Dashboard Effect] Refetch Applications',
);

export const removeApplication = createAction(
	'[Dashboard Effect] Remove Application',
	props<{ id: number }>(),
);

export const reviseApplicationStages = createAction(
	'[Dashboard Effect] Revise Application Stages',
	props<{ deal: any }>(),
);

export const loadDefaultPipelineStages = createAction(
	'[Dashboard Effect] Load Default Pipeline Stages',
);

export const setDefaultPipelineStages = createAction(
	'[Dashboard Effect] Set Default Pipeline Stages',
	props<{ stages: any }>(),
);

export const loadCourseQuestions = createAction(
	'[Dashboard Effect] Load Course Questions',
	props<{ courseId: number }>(),
);

export const courseQuestionsLoaded = createAction(
	'[Dashboard Effect] Course Questions Loaded',
	props<{ questions: FormQuestion[] }>(),
);
export const setAllCohortUrl = createAction(
	'[Dashboard Action] Set all cohort URL',
	props<{ currentUrl: string }>(),
)