import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import {
	catchError,
	filter,
	map,
	mergeMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import { AppState } from 'src/app/reducers';
import { MessagesService } from 'util-lib';
import { selectStudentsState } from '../../students/redux/students.selector';
import * as CohortAnalyticsActions from './cohort-analytics.actions';
import { CohortMixPanelAPI } from './cohort-analytics.selectors';

@Injectable()
export class CohortAnalyticsEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
		private messageService: MessagesService,
	) {}

	getCohortMixpanelList$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortAnalyticsActions.getCohortMixpanelList),
			withLatestFrom(
				this.authStore.user$,
				this.store.select(selectStudentsState),
				this.store.select(CohortMixPanelAPI),
				this.authStore.firebaseToken$,
			),
			tap(() => {
				this.store.dispatch(CohortAnalyticsActions.getCohortAPIStart());
			}),
			filter(([action, user, studentList, loader, token]) => loader === true),
			mergeMap(([action, user, studentList, loader, token]) => {
				const matchStudents = studentList.students.map((d: any) => d.email);

				return this.bobService
					.JQLQueryCohort(token, {
						cohortId: action.cohortId,
						studentList: matchStudents,
					})
					.pipe(
						map((responses: any) => {
							const newObject = [];

							responses[1].filter(res => {
								if (res.slackLastActive) {
									newObject.push({
										email: res.email,
										id: newObject.length * -1,
										name: 'slack_last_active',
										time: res.slackLastActive,
									});
								}
							});

							responses[2].filter(res => {
								res.events.filter(events => {
									newObject.push({
										...events,
										time: events.lastActive || events.marked,
										email: res.email,
										id: newObject.length * -1,
										name: 'bran_class',
										classId: events.classId,
										url: res.classId,
									});
								});
							});

							responses[3].filter(res => {
								res.events.filter(events => {
									newObject.push({
										...events,
										time: events.lastAttended,
										email: res.email,
										id: newObject.length * -1,
										name: 'bran_events',
										eventId: events.eventId,
									});
								});
							});

							responses[4].filter(res => {
								res.assignments
									.filter(
										events =>
											events.reviewedOn ||
											events.startedOn ||
											events.submittedOn,
									)

									.filter(
										events =>
											events.reviewedOn ||
											((events.startedOn || events.submittedOn) && events.url),
									)
									.filter(events => {
										newObject.push({
											...events,
											time: events.lastAction,
											email: res.email,
											id: newObject.length * -1,
											name: 'bran_assignments',
											assignmentId: events.assignmentId,
										});
									});
							});

							const response = [...responses[0], ...newObject];
							this.store.dispatch(CohortAnalyticsActions.getCohortAPIEnd());
							this.store.dispatch(
								CohortAnalyticsActions.getCohortStudentClass({
									cohortId: action.cohortId,
									data: responses[2],
								}),
							);

							this.store.dispatch(
								CohortAnalyticsActions.getCohortClassStudent({
									cohortId: action.cohortId,
									data: responses[2],
								}),
							);

							return CohortAnalyticsActions.getCohortMixpanelListSuccess({
								cohortId: action.cohortId,
								data: [...response]
									.map(r => {
										return { ...r, time: r.time * 1, cohortId: r.cohortId * 1 };
									})
									.reduce((group, product) => {
										const { email } = product;
										group[email] = group[email] ?? [];
										group[email].push(product);
										return group;
									}, {}),
							});
						}),
						catchError((err: any) => {
							this.messageService.showMsg({
								icon: 'alert-circle-outline',
								type: 'error',
								title1: 'Error',
								title2: JSON.stringify(err.message),
							});
							throw err;
						}),
					);
			}),
		),
	);
}
