import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
	CloudinaryConfiguration,
	CloudinaryModule,
} from '@cloudinary/angular-5.x';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthLibModule } from 'auth-lib';
import { Cloudinary } from 'cloudinary-core';
import { FeatureToggleLibModule } from 'feature-toggle-lib';
import {
	PeopleNoteFormModule,
	PeopleNoteViewModule,
	TerraUIModule,
} from 'terra-ui';
import { UtilLibModule } from 'util-lib';
import { CohortAnalyticsEffects } from '../cohort-analytics/redux/cohort-analytics.effects';
import {
	cohortAnalyticsFeatureKey,
	cohortAnalyticsReducer,
} from '../cohort-analytics/redux/cohort-analytics.reducer';
import { CohortSelectorModule } from '../cohort-selector/cohort-selector.module';
import { CohortSettingsEffects } from '../cohort-settings/redux/cohort-settings-effects';
import {
	cohortSettingsKey,
	cohortSettingsReducer,
} from '../cohort-settings/redux/cohort-settings-reducer';
import { SideMenuComponentModule } from '../side-menu/side-menu.component.module';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ChangeEmailModalComponent } from './change-email-modal/change-email-modal.component';
import { ClassComponent } from './class/class.component';
import { EventAttendanceComponent } from './event-attendance/event-attendance.component';
import { StudentAnalyticsEffects } from './redux/student-analytics.effects';
import {
	studentAnalyticsFeatureKey,
	studentAnalyticsReducer,
} from './redux/student-analytics.reducer';
import { StudentAnalyticsComponent } from './student-analytics.component';
import { StudentAnalyticsResolver } from './student-analytics.resolver';
import { StudentsAnalyticsRoutingModule } from './student-analytics.routing.module';
import { StudentInternalProfileComponent } from './student-internal-profile/student-internal-profile.component';
import { StudentObservationsComponent } from './student-observations/student-observations.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TerraUIModule,
		FeatureToggleLibModule,
		CloudinaryModule.forRoot({ Cloudinary }, {
			cloud_name: 'terra-do',
		} as CloudinaryConfiguration),
		AuthLibModule,
		SideMenuComponentModule,
		StoreModule.forFeature(cohortSettingsKey, cohortSettingsReducer),
		EffectsModule.forFeature([CohortSettingsEffects, StudentAnalyticsEffects]),
		StoreModule.forFeature(cohortSettingsKey, cohortSettingsReducer),
		StoreModule.forFeature(studentAnalyticsFeatureKey, studentAnalyticsReducer),
		EffectsModule.forFeature([CohortAnalyticsEffects]),
		StoreModule.forFeature(cohortAnalyticsFeatureKey, cohortAnalyticsReducer),
		NgSelectModule,
		CohortSelectorModule,
		CommonModule,
		StudentsAnalyticsRoutingModule,
		HttpClientModule,
		UtilLibModule,
		PeopleNoteFormModule,
		PeopleNoteViewModule,
	],
	declarations: [
		StudentAnalyticsComponent,
		AssignmentsComponent,
		ClassComponent,
		EventAttendanceComponent,
		StudentInternalProfileComponent,
		StudentObservationsComponent,
		ChangeEmailModalComponent,
	],
	providers: [StudentAnalyticsResolver],
})
export class StudentAnalyticsModule {}
