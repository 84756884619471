enum SideMenu {
	Applications = 'Applications',
	CohortSettings = 'Cohort settings',
	CourseContent = 'Course content',
	AppsAnalytics = 'Apps analytics',
	MentorRequests = 'Mentor requests',
	MentorAnalytics = 'Mentor Analytics',
	Students = 'Students',
	StudentAnalytics = 'Student Analytics',
	CohortAnalytics = 'Cohort Analytics',
}

export default class Utils {
	// Sort options
	static CREATED_AT = 'cf_created_date';
	static STAGE_UPDATED_TIME = 'stage_updated_time';
	static DEAL_NAME = 'name';
	static MENTOR_NAME = 'cf_mentor_name';
	static UPDATED_AT = 'updated_at';
	static NOTE_UPDATED_AT = 'note_updated_at';

	// Applications pipeline stages
	static APPLICATION_RECEIVED = 'Application received';
	static REQUEST_RECEIVED = '1:1 request received';
	static SCHEDULED = '1:1 scheduled';
	static ON_HOLD = 'On hold';
	static PAYMENT_RECEIVED = 'Payment received';
	static PAYMENT_LINK_SENT = 'Payment link sent';
	static PAYMENT_REFUNDED = 'Payment refunded';
	static REJECTED = 'Rejected';
	static USER_WITHDREW_APPLICATION = 'Withdrawn or Unresponsive';
	static ARCHIVE_AS_TEST = 'Archived as test';

	// Mentors pipeline stages
	static NEW_REQUESTS = 'New requests';
	static SENT_TO_MENTOR = 'Sent to mentor';
	static MENTOR_SCHEDULED = 'Scheduled';
	static CANCELLED = 'Cancelled';
	static AWAITING_FEEDBACK = 'Awaiting feedback';
	static FEEDBACK_RECEIVED = 'Feedback received';

	// Firebase Student Status
	static SCHEDULED_STATUS_CODE = 2;
	static PAYMENT_LINK_SENT_STATUS_CODE = 3;

	static PAYMENT_LINK = 'https://my.terra.do/payment';
	// Email template types
	static REJECT_AND_NOTIFY = 'rejectNotify';
	static REMIND = 'remind';
	static SEND_PAYMENT_LINK = 'payment_link';
	static CHANGE_COHORT = 'changeCohort';

	// Side menu
	static SIDE_MENU = SideMenu;
	// Local storage keys
	static COHORT_CONTEXT = 'cohort context';
	static ACTIVE_COHORT = 'active cohort';

	// Student assignment status
	static ASSIGNMENT_SUBMITTED = 'Submitted';
	static ASSIGNMENT_RECVIEWED = 'Reviewed';
	static ASSIGNMENT_STARTED = 'Started';

	/**
	 * Returns a pre-defined CSS class for the status tags
	 */
	static stageTagColor(
		tag: string,
		type: 'applications' | 'mentors' | 'studentAnalytics',
	): string {
		if (type === 'mentors') {
			switch (tag) {
				case this.NEW_REQUESTS:
					return 'action200';
				case this.AWAITING_FEEDBACK:
				case this.ON_HOLD:
					return 'sea100';
				case this.SENT_TO_MENTOR:
				case this.FEEDBACK_RECEIVED:
				case this.MENTOR_SCHEDULED:
					return 'earth100';
				case this.REJECTED:
				case this.CANCELLED:
					return 'tag-red';
				case this.ARCHIVE_AS_TEST:
					return 'black200';
				default:
					return 'action100';
			}
		} else if (type === 'applications') {
			switch (tag) {
				case this.APPLICATION_RECEIVED:
					return 'action100';
				case this.SCHEDULED:
					return 'sea100';
				case this.PAYMENT_LINK_SENT:
					return 'sea100';
				case this.PAYMENT_RECEIVED:
					return 'earth100';
				case this.PAYMENT_REFUNDED:
					return 'black100';
				case this.REJECTED:
					return 'tag-red';
				case this.ON_HOLD:
					return 'sea100';
				case 'Paid':
					return 'earth100';
				case 'Lost':
					return 'tag-red';
				case this.USER_WITHDREW_APPLICATION:
					return 'tag-red';
				case this.ARCHIVE_AS_TEST:
					return 'black200';
				default:
					return 'action100';
			}
		} else {
			switch (tag) {
				case this.ASSIGNMENT_SUBMITTED:
					return 'earth100';
				case this.ASSIGNMENT_RECVIEWED:
					return 'sea100';
				case this.ASSIGNMENT_STARTED:
					return 'action100';
				default:
					return 'actionF1C2CA';
			}
		}
	}

	static getApplicationLabel(application: any): string {
		let elapsed;
		let days;
		switch (application.deal_stage_name) {
			case this.APPLICATION_RECEIVED:
				elapsed = Math.round(
					(new Date().getTime() - new Date(application.created_at).getTime()) /
						(1000 * 60 * 60 * 24),
				);

				if (!elapsed) {
					return `${this.APPLICATION_RECEIVED} today`;
				} else {
					days = elapsed > 1 ? elapsed + ' days' : elapsed + ' day';
					return `${this.APPLICATION_RECEIVED} ${days} ago`;
				}
			case this.REQUEST_RECEIVED:
				elapsed = Math.round(
					(new Date().getTime() - new Date(application.created_at).getTime()) /
						(1000 * 60 * 60 * 24),
				);
				if (!elapsed) {
					return `${this.REQUEST_RECEIVED} today`;
				} else {
					days = elapsed > 1 ? elapsed + ' days' : elapsed + ' day';
					return `${this.REQUEST_RECEIVED} ${days} ago`;
				}
			case this.PAYMENT_LINK_SENT:
				if (application.payment_value) {
					return `$${parseInt(
						application.payment_value,
						10,
					)} payment link sent`;
				}
				return 'Payment link sent';
			case this.PAYMENT_RECEIVED:
				if (application.payment_value) {
					return `$${parseInt(application.payment_value, 10)} received`;
				}
				return 'Payment received';
			case this.PAYMENT_REFUNDED:
				if (application.payment_value) {
					return `$${parseInt(application.payment_value, 10)} refunded`;
				}
				return 'Payment refunded';
			case this.USER_WITHDREW_APPLICATION:
				return 'Withdrawn or Unresponsive';
			case this.ARCHIVE_AS_TEST:
				return 'Archived as Test';
			case this.NEW_REQUESTS:
				elapsed = Math.round(
					(new Date().getTime() - new Date(application.created_at).getTime()) /
						(1000 * 60 * 60 * 24),
				);

				if (!elapsed) {
					return 'Request received today';
				} else if (elapsed === 1) {
					return 'Request received yesterday';
				} else {
					return `Request received ${elapsed} days ago`;
				}
			default:
				return application.deal_stage_name;
		}
	}

	/**
	 * Returns email template name
	 */
	static emailTemplate(type: string): string {
		switch (type) {
			case this.REJECT_AND_NOTIFY:
				return 'application_rejection';
			case this.REMIND:
				return 'remind_payment';
			case this.SEND_PAYMENT_LINK:
				return 'payment_email_template';
			case this.CHANGE_COHORT:
				return 'course_change_template';
			default:
				return 'generic_template';
		}
	}
}
