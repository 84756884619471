import { createAction, props } from '@ngrx/store';
import {
	PeopleRequestBody,
	PeopleWithRole,
	Role
} from 'src/app/models/cohort-settings-models';
import { People, Price } from '../../models/cohort-settings-models';
import { Cohort } from '../../models/dashboard-models';

export const setCohort = createAction(
	'[Cohort Settings Page] Set Cohort',
	props<{ cohort: Cohort }>(),
);

export const loadRolesList = createAction(
	'[Cohort Settings Page] Load Roles List'
);

export const rolesListLoaded = createAction(
	'[Cohort Settings Page] Roles List Loaded',
	props<{ roles: Role[] }>(),
);

export const rolesListError = createAction(
	'[Cohort Settings Page] Roles List Error',
	props<{ error: any }>(),
);

export const loadPeopleList = createAction(
	'[Cohort Settings Page] Load People List',
);

export const peopleListLoaded = createAction(
	'[Cohort Settings Page] People List Loaded',
	props<{ people: People[] }>(),
);

export const peopleListError = createAction(
	'[Cohort Settings Page] People List Error',
	props<{ error: any }>(),
);

export const loadPeopleWithRoles = createAction(
	'[Cohort Settings Page] Load People With Roles',
	props<{ cohortId: number }>(),
);

export const peopleWithRolesLoaded = createAction(
	'[Cohort Settings Page] People With Roles Loaded',
	props<{ peopleWithRoles: PeopleWithRole[] }>(),
);

export const peopleWithRolesError = createAction(
	'[Cohort Settings Page] People With Roles Error',
	props<{ error: any }>(),
);

export const assignRole = createAction(
	'[Cohort Settings Page] Assign Role',
	props<{
		peopleId: number;
		cohortId: number;
		order: number;
		roleId: number;
	}>(),
);

export const roleAssigned = createAction(
	'[Cohort Settings Page] Role Assigned',
	props<{ response: any }>(),
);

export const roleAssignmentError = createAction(
	'[Cohort Settings Page] Role Assignment Error',
	props<{ error: any }>(),
);

export const loadPriceListForCohort = createAction(
	'[Cohort Settings Page] Load Price List For Cohort',
	props<{ cohortId: number }>(),
);

export const priceListForCohortLoaded = createAction(
	'[Cohort Settings Page] Price List For Cohort Loaded',
	props<{ priceList: Price[] }>(),
);

export const priceListForCohortError = createAction(
	'[Cohort Settings Page] Price List For Cohort Error',
	props<{ error: any }>(),
);

export const updateCohortPrice = createAction(
	'[Cohort Settings Page] Update Price For Cohort',
	props<{ priceId: number; price: number; timezone: string }>(),
);

export const cohortPriceUpdated = createAction(
	'[Cohort Settings Page] Cohort Price Updated',
	props<{ respone: any }>(),
);

export const cohortPriceUpdateError = createAction(
	'[Cohort Settings Page] Cohort Price Update Error',
	props<{ error: any }>(),
);

export const removeRoleAssignment = createAction(
	'[Cohort Settings Page] Remove Role Assignment',
	props<{
		cohortId: number;
		roleId: number;
		peopleId: number;
	}>(),
);

export const roleAssignmentRemoved = createAction(
	'[Cohort Settings Page] Role Assignment Removed',
	props<{ respone: any }>(),
);

export const removeRoleAssignmentError = createAction(
	'[Cohort Settings Page] Remove Role Assignment Error',
	props<{ error: any }>(),
);

export const addPeople = createAction(
	'[Cohort Settings Page] Create People',
	props<{ people: PeopleRequestBody }>(),
);

export const peopleAdded = createAction(
	'[Cohort Settings Page] People added',
	props<{ response: any }>(),
);

export const peopleAddError = createAction(
	'[Cohort Settings Page] People Add Error',
	props<{ error: any }>(),
);
