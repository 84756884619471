import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import {
	changeStudentEmail,
	resetEmailChange,
} from '../redux/student-analytics.actions';
import {
	selectStudentEmailChange,
	selectStudentEmailChangeError,
} from '../redux/student-analytics.selectors';
import * as studentsActions from 'src/app/students/redux/students.actions';
import * as Handlebars from 'handlebars';
import { LoadingService, MessagesService } from 'util-lib';

import {
	orderError,
	orderStatus,
	selectEmailContent,
	selectEmailSubject,
} from 'src/app/students/redux/students.selector';
import { filter, first } from 'rxjs/operators';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-change-email-modal',
	templateUrl: './change-email-modal.component.html',
	styleUrls: ['./change-email-modal.component.scss'],
})
export class ChangeEmailModalComponent implements OnInit, OnDestroy {
	@Input() personName: any;
	@Input() previousEmail: any;
	@Input() cohort: any;
	newEmailId = '';

	selectStudentEmailChange$ = this.store.pipe(select(selectStudentEmailChange));
	emailContent$ = this.store.select(selectEmailContent);
	emailSubject$ = this.store.select(selectEmailSubject);
	orderStatus$ = this.store.select(orderStatus);
	orderError$ = this.store.select(orderError);

	selectStudentEmailChangeError$ = this.store.select(
		selectStudentEmailChangeError,
	);
	showEmailError = false;
	constructor(
		private store: Store<AppState>,
		public modalController: ModalController,
		private messageService: MessagesService,
	) {}
	emailContent = '';
	emailSubject = '';
	showMailSendBtn = false;
	sendingProgress = false;
	subscriptions: Subscription[] = [];
	ngOnInit() {
		console.log(this.personName, this.previousEmail);
		this.store.dispatch(
			studentsActions.fetchEmailTemplate({ id: 'Email_change_template' }),
		);
		this.store.dispatch(
			studentsActions.getExtraDetails({
				cohortId: this.cohort.id,
				filterDates: [
					moment(this.cohort.cohortDate)
						.subtract(8, 'months')
						.format('DD/MM/YYYY'),
					moment().format('DD/MM/YYYY'),
				],
				sort: 'cf_created_date',
			}),
		);
	}
	onEmailChange(event: any) {
		this.newEmailId = event.target.value;
		// console.log(this.newEmailId);
	}
	validateEmail(email) {
		console.log('inside email validation0');
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	}
	confirmChange() {
		this.sendingProgress = true;
		console.log(!this.validateEmail(this.newEmailId));

		if (!this.validateEmail(this.newEmailId)) {
			this.showEmailError = true;
			this.sendingProgress = false;
			return;
		} else {
			this.showEmailError = false;
		}
		console.log('Confirm Change');
		if (this.previousEmail === this.newEmailId) {
			this.showErrorToast('Email is same as previous email');
			this.closeModal(false);
			return;
		}
		this.store.dispatch(
			changeStudentEmail({
				oldEmail: this.previousEmail,
				newEmail: this.newEmailId,
			}),
		);
		this.subscriptions.push(
			this.selectStudentEmailChangeError$
				.pipe(filter(err => !!err))
				.subscribe(({ error }) => {
					this.showErrorToast('Failed to change Email: ' + error.toString());
					this.sendingProgress = false;
					this.closeModal(false);
				}),
		);
		this.subscriptions.push(
			this.emailSubject$.pipe().subscribe(data => {
				if (data) {
					// console.log(data)
					const template = Handlebars.compile(data);
					const subject = template({
						firstName: this.personName,
					});
					this.emailSubject = subject;
				}
			}),
		);

		this.subscriptions.push(
			this.emailContent$.subscribe(data => {
				if (data) {
					// console.log(data)
					const template = Handlebars.compile(data);
					const content = template({
						firstName: this.personName,
						oldEmail: this.previousEmail,
						newEmail: this.newEmailId,
					});
					this.emailContent = content;
				}

				if (this.emailSubject) {
					this.showMailSendBtn = true;
				}
			}),
		);

		this.subscriptions.push(
			this.selectStudentEmailChange$
				.pipe(
					filter(st => !!st),
					first(),
				)
				.subscribe(status => {
					console.log(status);
					const list = [];
					let tempObj = { email: this.previousEmail, name: this.personName };
					list.push(tempObj);
					tempObj = { email: this.newEmailId, name: this.personName };
					list.push(tempObj);
					console.log('now sending email');
					if (status === true) {
						this.sendingProgress = true;
						// console.log(list);
						// console.log(this.emailContent);
						this.store.dispatch(
							studentsActions.bulkGenericNotify({
								list,
								content: this.emailContent,
								subject: this.emailSubject,
								templateType: 'change email',
								emailFrom: 'test@terra.do',
							}),
						);
						this.subscriptions.push(
							this.orderStatus$.subscribe(res => {
								console.log(res, this.sendingProgress);
								if (res && this.sendingProgress) {
									console.log('close the modal');
									this.sendingProgress = false;
									this.showSuccessToast(
										'Email Changed Successfully and Email sent',
									);
									this.store.dispatch(resetEmailChange());
									this.store.dispatch(studentsActions.resetDraftEmail());
									this.store.dispatch(studentsActions.clearOrderStatus());
									this.closeModal(true);
								}
							}),
						);
						this.subscriptions.push(
							this.orderError$
								.pipe(filter(error => !!error))
								.subscribe(error => {
									this.sendingProgress = false;
									this.showErrorToast('Failed to send email' + error);
									this.store.dispatch(studentsActions.clearOrderStatus());
									this.closeModal(false);
								}),
						);
					} else {
					}
				}),
		);
	}
	showErrorToast(message) {
		console.log(message);
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			type: "error",
			title1: 'Error',
			title2: message,
		});
	}
	showSuccessToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			title1: 'Success',
			title2: message,
		});
	}
	ngOnDestroy(): void {
		console.log('destroyed');
		this.emailContent = '';
		this.emailSubject = '';
		this.showMailSendBtn = false;
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	async closeModal(clear: boolean) {
		this.store.dispatch(studentsActions.resetDraftEmail());
		this.store.dispatch(resetEmailChange());
		await this.modalController.dismiss({
			success: clear,
			newEmail: this.newEmailId,
		});
	}
}
