import { createReducer, on } from '@ngrx/store';
import { CourseContentModel } from '../../models/course-content-models';
import * as CourseContentActions from './course-content-actions';

export const courseContentKey = 'course-content';

export const initialCourseContentState: CourseContentModel = {
	selectedCohort: null,
	classSegment: null,
	assignmentSegment: null,
	authors: [],
	selectedAuthors: [],
	additionalResources: null,
	selectedAuthor: null,
	classLoaded: false,
	assignmentLoaded: false,
	syllabusLoaded: false,
	additionalResourcesLoaded: false,
	// instructorLoaded: false,
	syllabusDetail: null,
	// syllabusLoaded: boolean;
	instructorsSegment: null,
	weekSegment: { createStatus: null, deleteStatus: null },
	copyClassStatus: null,
	submitClassIdStatus: null,
	checkClassIdSavedStatus: null,
	hideClassStatus: null,
	activeClassUpdated: false,
	activeAssignmentUpdated: false,
	activeInstructorUpdated: false,
	activeAddResourcesUpdated: false,
	activeSyllabusUpdated: false,
	activeCohortId: null,
	linkUploaded: false,
	overRide: false,
	syllabusUpdated: false,
	moduleAddedStatus: null,
	moduleAddedError: false,
	moduleExists: false,
	classSubModuleStatus: null,
	classStatusUpdating: false,
};

export const courseContentReducer = createReducer(
	initialCourseContentState,
	on(CourseContentActions.setCohort, (state, action) => ({
		...state,
		selectedCohort: {
			...state.selectedCohort,
			...action.cohort,
		},
	})),
	on(CourseContentActions.classesForCohortLoaded, (state, action) => ({
		...state,
		classLoaded: true,
		classSegment: {
			...state.classSegment,
			cohortClasses: action.response,
			cohortClassesError: null,
		},
	})),
	on(CourseContentActions.classesForCohortError, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			createWeekStatus: false,
			cohortClassesError: action.error,
		},
	})),
	on(CourseContentActions.classModuleListLoaded, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			moduleList: action.response,
			moduleListError: null,
		},
	})),
	on(CourseContentActions.classModuleListError, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			moduleListError: action.error,
		},
	})),
	on(CourseContentActions.ContainerCreated, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			createClassContainerStatus: action.response,
			createWeekError: null,
		},
	})),
	on(CourseContentActions.createClassContainerError, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			createClassContainerError: action.error,
		},
	})),
	on(CourseContentActions.createClass, (state, action) => ({
		...state,
		classLoaded: true,
		classSegment: {
			...state.classSegment,
			createClassStatus: false,
			createClassError: null,
		},
	})),
	on(CourseContentActions.classCreated, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			createClassStatus: action.status,
			createClassError: null,
		},
	})),
	on(CourseContentActions.createClassError, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			createClassError: action.error,
		},
	})),
	on(CourseContentActions.updateClass, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			classUpdateStatus: false,
			classUpdateError: null,
		},
	})),
	on(CourseContentActions.classUpdated, (state, action) => ({
		...state,
		classLoaded: true,
		classSegment: {
			...state.classSegment,
			classUpdateStatus: action.status,
		},
	})),
	on(CourseContentActions.classUpdateError, (state, action) => ({
		...state,
		classSegment: { ...state.classSegment, classUpdateError: action.error },
	})),
	on(CourseContentActions.deleteClass, (state, action) => ({
		...state,
		classSegment: {
			...state.classSegment,
			classDeleteStatus: null,
			classDeleteError: null,
		},
	})),
	on(CourseContentActions.classDeleted, (state, action) => ({
		...state,
		classLoaded: true,
		classSegment: {
			...state.classSegment,
			classDeleteStatus: action.status,
			classDeleteError: null,
		},
	})),
	on(CourseContentActions.classDeleteError, (state, action) => ({
		...state,
		classLoaded: true,
		classSegment: {
			...state.classSegment,
			classDeleteError: action.error,
		},
	})),
	on(CourseContentActions.syllabusLoaded, (state, action) => ({
		...state,
		syllabusLoaded: true,
		// selectedCohort: {
		// 	...state.selectedCohort,
		syllabusDetail: action.syllabusDetail,
		// syllabusLoaded: true,
		// },
	})),
	on(CourseContentActions.cohortInstructorLoded, (state, action) => {
		return {
			...state,
			instructorsSegment: {
				...state.instructorsSegment,
				instructors: action.response,
				instructorsLoaded: true,
			},
		};
	}),
	on(CourseContentActions.updatedSyllabusDetailOnStore, (state, action) => ({
		...state,
		// selectedCohort: {
		// 	...state.selectedCohort,
		syllabusDetail: action.syllabusDetail,
		// },
	})),
	on(CourseContentActions.resetSyllabusDetail, (state, action) => ({
		...state,
		// selectedCohort: {
		// 	...state.selectedCohort,
		syllabusDetail: null,
		syllabusLoaded: false,
		// },
	})),
	on(CourseContentActions.assignmentsForCohortLoaded, (state, action) => ({
		...state,
		assignmentLoaded: true,
		assignmentSegment: {
			...state.assignmentSegment,
			assignments: action.response,
			cohortAssignmentsError: null,
		},
	})),
	on(CourseContentActions.assignmentsForCohortError, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			cohortAssignmentsError: action.error,
		},
	})),
	on(CourseContentActions.createAssignment, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			createAssignmentStatus: false,
			createAssignmentError: null,
		},
	})),
	on(CourseContentActions.assignmentCreated, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			createAssignmentStatus: action.status,
			createAssignmentError: null,
		},
	})),
	on(CourseContentActions.assignmentUpdated, (state, action) => ({
		...state,
		assignmentLoaded: true,
		assignmentSegment: {
			...state.assignmentSegment,
			updateAssignmentStatus: action.status,
			createAssignmentError: null,
		},
	})),
	on(CourseContentActions.assignmentUpdateError, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			updateAssignmentError: action.error,
			createAssignmentError: null,
			assignmentUpdateError: null,
		},
	})),
	on(CourseContentActions.assignmentUpdateError, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			assignmentUpdateError: action.error,
		},
	})),
	on(CourseContentActions.createAssignmentError, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			createAssignmentError: action.error,
		},
	})),
	on(CourseContentActions.assignmentDeleted, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			assignmentDeleteStatus: action.status,
			assignmentDeleteError: null,
		},
	})),
	on(CourseContentActions.assignmentDeleteError, (state, action) => ({
		...state,
		assignmentSegment: {
			...state.assignmentSegment,
			assignmentDeleteError: action.error,
		},
	})),
	on(CourseContentActions.loadedAuthors, (state, action) => {
		const tempAuthors = action.authors.filter(
			value => value.firstName?.length > 2,
		);

		return {
			...state,
			authors: tempAuthors,
		};
	}),
	on(CourseContentActions.selectedAuthors, (state, action) => ({
		...state,
		selectedAuthor: action.selectedAuthor,
	})),
	on(CourseContentActions.clearSelectedAuthors, (state, action) => ({
		...state,
		selectedAuthor: null,
	})),
	on(CourseContentActions.instructorUpdated, (state, action) => ({
		...state,
		instructorsSegment: {
			...state.instructorsSegment,
			instructorsUpdateStatus: action.status,
			instructorsUpdateError: null,
		},
	})),
	on(CourseContentActions.instructorUpdateError, (state, action) => ({
		...state,
		instructorsSegment: {
			...state.instructorsSegment,
			instructorsUpdateError: action.error,
		},
	})),
	on(CourseContentActions.instructorDeleted, (state, action) => ({
		...state,
		instructorsSegment: {
			...state.instructorsSegment,
			instructorsDeleteStatus: action.status,
			instructorsDeleteError: null,
		},
	})),
	on(CourseContentActions.instructorDeleteError, (state, action) => ({
		...state,
		instructorsSegment: {
			...state.instructorsSegment,
			instructorsDeleteError: action.error,
		},
	})),
	on(CourseContentActions.cohortAdditionalResourcesLoaded, (state, action) => {
		return {
			...state,
			additionalResourcesLoaded: true,
			additionalResources: {
				...state.additionalResources,
				additionalResources: action.response,
			},
		};
	}),
	on(CourseContentActions.additionalResourceLoadError, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesLoadError: action.error,
			},
		};
	}),
	on(CourseContentActions.additionalResourceAdded, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesAddStatus: action.status,
			},
		};
	}),
	on(CourseContentActions.additionalResourceAddError, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesAddError: action.error,
			},
		};
	}),
	on(CourseContentActions.additionalResourceUpdated, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesUpdateStatus: action.status,
			},
		};
	}),
	on(CourseContentActions.additionalResourceUpdateError, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesUpdateError: action.error,
			},
		};
	}),
	on(CourseContentActions.additionalResourceDeleted, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesDeleteStatus: action.status,
			},
		};
	}),
	on(CourseContentActions.additionalResourceDeleteError, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResourcesDeleteError: action.error,
			},
		};
	}),
	on(CourseContentActions.fileUploadForCohortUrl, (state, action) => {
		return {
			...state,
			additionalResources: {
				additionalResources: [
					...state.additionalResources.additionalResources.map(
						(resource: any) => {
							let url = resource.url;
							if (resource.id === action.id) {
								url = action.url;
							}
							return {
								...resource,
								url,
							};
						},
					),
				],
			},
			linkUploaded: false,
		};
	}),
	on(CourseContentActions.fileUploadForCohort, (state, action) => {
		return {
			...state,
			linkUploaded: true,
		};
	}),
	on(CourseContentActions.addNewFile, (state, action) => {
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResources: [
					...state.additionalResources.additionalResources,
					action.file,
				],
			},
		};
	}),
	on(CourseContentActions.updateNewFile, (state, action) => {
		const additionalResourcesList = [
			...state.additionalResources.additionalResources,
		];
		const elementsIndex = additionalResourcesList.findIndex(
			resource => resource.id === action.file.id && resource.type === 'file',
		);
		if (elementsIndex > -1) {
			additionalResourcesList[elementsIndex] = {
				...additionalResourcesList[elementsIndex],
				...action.file,
			};
		}
		return {
			...state,
			additionalResources: {
				...state.additionalResources,
				additionalResources: [...additionalResourcesList],
			},
		};
	}),
	on(CourseContentActions.createWeekStatusResponse, (state, action) => {
		return {
			...state,
			weekSegment: { ...state.weekSegment, createStatus: action.status },
		};
	}),
	on(CourseContentActions.deleteWeekStatusResponse, (state, action) => {
		return {
			...state,
			weekSegment: { ...state.weekSegment, deleteStatus: action.status },
		};
	}),

	on(CourseContentActions.copyClassStatusResponse, (state, action) => {
		return {
			...state,
			copyClassStatus: action.status,
		};
	}),
	on(CourseContentActions.copyClassOverride, (state, action) => {
		return {
			...state,
			overRide: action.status,
		};
	}),
	on(CourseContentActions.submitClassIdStatus, (state, action) => {
		return {
			...state,
			submitClassIdStatus: action.status,
		};
	}),
	on(CourseContentActions.checkClassSavedStatus, (state, action) => {
		return {
			...state,
			checkClassIdSavedStatus: action.status,
		};
	}),
	on(CourseContentActions.hideClassStatus, (state, action) => {
		return {
			...state,
			hideClassStatus: action.status,
		};
	}),
	on(CourseContentActions.updatingClass, state => {
		return {
			...state,
			classStatusUpdating: !state.classStatusUpdating,
		};
	}),
	on(CourseContentActions.activeClassUpdated, (state, action) => ({
		...state,
		activeClassUpdated: action.status,
	})),
	on(CourseContentActions.activeAssignmentUpdated, (state, action) => ({
		...state,
		activeAssignmentUpdated: action.status,
	})),
	on(CourseContentActions.assignmentError, (state, action) => ({
		...state,
		assignmentError: action.status,
	})),
	on(CourseContentActions.activeInstructorUpdated, (state, action) => ({
		...state,
		activeInstructorUpdated: action.status,
	})),
	on(CourseContentActions.activeAddResourcesUpdated, (state, action) => ({
		...state,
		activeAddResourceUpdated: action.status,
	})),
	on(CourseContentActions.activeSyllabusUpdated, (state, action) => ({
		...state,
		activeSyllabusUpdated: action.status,
		syllabusUpdated: action.status,
	})),
	on(CourseContentActions.setCourseActiveCohort, (state, action) => ({
		...state,
		activeCohortId: action.cohortId,
	})),
	on(CourseContentActions.setNewModuleSuccess, (state, action) => {
		let id = state.classSegment.moduleList?.find(
			item => item.id === action.moduleNameResponse?.id,
		);
		let moduleExists = false;
		let tempModules = [...state.classSegment.moduleList];
		if (!id) {
			tempModules.push(action.moduleNameResponse);
		} else {
			moduleExists = true;
		}
		return {
			...state,
			classSegment: {
				...state.classSegment,
				moduleList: tempModules,
			},
			moduleAddedStatus: action.moduleNameResponse,
			moduleExists: moduleExists,
		};
	}),
	on(CourseContentActions.setNewModuleError, (state, action) => ({
		...state,
		moduleAddedError: action.moduleAddedError,
	})),
	on(CourseContentActions.resetModuleSuccess, state => ({
		...state,
		moduleAddedStatus: null,
	})),

	on(CourseContentActions.resetModuleExists, state => ({
		...state,
		moduleExists: false,
	})),

	on(CourseContentActions.updateModuleList, (state, action) => {
		console.log(action.response);
		const newModule = {
			id: action.response.id,
			name: action.response.name,
			moduleId: action.response.moduleId,
			mandatory: action.response.mandatory,
			duration: action.response.duration,
			authors: action.payload?.authors,
			moduleOrder: action.payload.moduleOrder,
		};
		let tempCohortClasses = [...state.classSegment?.cohortClasses];
		let finalCohortClasses;
		finalCohortClasses = tempCohortClasses.map(tempCohortClass => ({
			...tempCohortClass,
			classes: tempCohortClass.classes.map(tempModule => ({
				...tempModule,
				modules:
					tempModule.id === action.payload.classEventId
						? [...tempModule.modules, newModule]
						: tempModule,
			})),
		}));
		return {
			...state,
			classSegment: {
				...state.classSegment,
				cohortClasses: finalCohortClasses,
			},
			classSubModuleStatus: true,
		};
	}),
	on(CourseContentActions.classSubModuleUpdated, (state, action) => {
		console.log(action.response);
		const updatedModule = {
			id: action.response.id,
			name: action.response.name,
			moduleId: action.response.moduleId,
			mandatory: action.response.mandatory,
			duration: action.response.duration,
			authors: [...action.response.authors],
			moduleOrder: action.response.moduleOrder,
		};
		let tempCohortClasses = [...state.classSegment?.cohortClasses];
		let finalCohortClasses;
		finalCohortClasses = tempCohortClasses.map(tempCohortClass => ({
			...tempCohortClass,
			classes: tempCohortClass.classes.map(tempModule => ({
				...tempModule,
				modules:
					tempModule.modules.length > 0
						? tempModule.modules.map(md => {
								return md.id === action.response.id ? updatedModule : md;
						  })
						: tempModule.modules,
			})),
		}));
		return {
			...state,
			classSegment: {
				...state.classSegment,
				cohortClasses: finalCohortClasses,
			},
		};
	}),
	on(CourseContentActions.classSubModuleDeleted, (state, action) => {
		let tempCohortClasses = [...state.classSegment?.cohortClasses];
		let finalCohortClasses;
		finalCohortClasses = tempCohortClasses.map(tempCohortClass => ({
			...tempCohortClass,
			classes: tempCohortClass.classes.map(tempModule => ({
				...tempModule,
				modules:
					tempModule.modules.length > 0
						? tempModule.modules.filter(md => {
								return md.id !== action.response.id;
						  })
						: tempModule.modules,
			})),
		}));
		return {
			...state,
			classSegment: {
				...state.classSegment,
				cohortClasses: finalCohortClasses,
			},
		};
	}),
	on(CourseContentActions.classSubModuleReordered, (state, action) => {
		let finalCohortClasses = [...state.classSegment?.cohortClasses];
		action.response.data.map(rd => {
			finalCohortClasses = finalCohortClasses.map(tempCohortClass => ({
				...tempCohortClass,
				classes: tempCohortClass.classes.map(tempModule => ({
					...tempModule,
					modules:
						tempModule.modules.length > 0
							? tempModule.modules
									.map(md => ({
										...md,
										moduleOrder:
											rd.moduleId === md.id ? rd.moduleOrder : md.moduleOrder,
									}))
									.sort((a, b) => {
										return a.moduleOrder - b.moduleOrder;
									})
							: tempModule.modules,
				})),
			}));
		});
		return {
			...state,
			classSegment: {
				...state.classSegment,
				cohortClasses: finalCohortClasses,
			},
		};
	}),
	on(CourseContentActions.restSubModuleAddedStatus, (state, action) => ({
		...state,
		classSubModuleStatus: action.status,
	})),
);
