import { createAction, props } from '@ngrx/store';

export const loadApplicationDetails = createAction(
	'[Application Details Resolver] Load Application Details',
	props<{ id: number }>(),
);

export const setApplicationDetails = createAction(
	'[Application Details Resolver] Application Details Set',
	props<{ application: any }>(),
);

export const loadNotes = createAction(
	'[Application Details Page] Load Application Notes',
	props<{ id: number }>(),
);

export const setNotes = createAction(
	'[Application Details Page] Application Notes Set',
	props<{ notes: any }>(),
);

export const addNote = createAction(
	'[Application Details Page] Add Application Note',
	props<{ id: number; note: any }>(),
);

export const noteProgress = createAction(
	'[Application Details Page] Posting Note',
	props<{ status: boolean }>(),
);

export const resetApplicationDetails = createAction(
	'[Application Details Page] Reset Application Details',
);

export const updateApplicationStage = createAction(
	'[Application Details Page] Update Application Stage',
	props<{
		id: number;
		username: string;
		note: string;
		stage: string;
		payment: number;
		discount: number;
	}>(),
);

export const updateApplicationCohort = createAction(
	'[Application Details Page] Update Application Cohort',
	props<{
		id: number;
		email: string;
		oldCohortId: number;
		newCohortId: number;
		productId: number;
		username: string;
		note: string;
	}>(),
);

export const updateCohortOnPaymentLinkSent = createAction(
	'[Application Details Page] Update Cohort On Payment Link Sent',
	props<{
		id: number;
		newCohortId: number;
		productId: number;
		username: string;
		note: string;
		email: string;
		amount: any;
		oldCohortId: number;
	}>(),
);

export const updateApplicationProgress = createAction(
	'[Application Details Page] Application Progress Update',
	props<{ status: boolean }>(),
);

export const genericEmailSuccess = createAction(
	'[Application Details Page] Application Generic Email Success',
	props<{ status: boolean , isCompletedSendingMail:any }>(),
);

export const applicationUpdateError = createAction(
	'[Application Details Page] Application Update Error',
	props<{ error: any }>(),
);

export const createPaymentOrder = createAction(
	'[Application Details Page] Create Payment Order',
	props<{
		applicationId: number;
		email: string;
		cohortId: number;
		sku: string;
	}>(),
);

export const orderError = createAction(
	'[Application Details Page] Payment Order Error',
	props<{ error: any }>(),
);

export const orderCreated = createAction(
	'[Application Details Page] Order Created',
	props<{ order: any }>(),
);

export const refundPaymentOrder = createAction(
	'[Application Details Page] Refund Payment Order',
	props<{
		email: string;
		cohortId: number;
		orderId: string;
	}>(),
);

export const orderRefunded = createAction(
	'[Application Details Page] Order Refunded',
	props<{ status: any }>(),
);

export const refundError = createAction(
	'[Application Details Page] Order Refund Error',
	props<{ error: any }>(),
);

export const remindPayment = createAction(
	'[Application Details Page] Remind Payment',
	props<{
		name: string;
		email: string;
		cohortId: number;
		courseId: number;
		amount: number;
		cohortDate: number;
		courseName: string;
		weeks: string;
		discountCode: string;
		refundWeek: string;
		signatureUserName: string;
		applicationId: number;
		note: string;
	}>(),
);

export const paymentRemindProgress = createAction(
	'[Application Details Page] Payment Remind Progress',
	props<{ status: any }>(),
);

export const remindError = createAction(
	'[Application Details Page] Payment Remind Error',
	props<{ error: any }>(),
);

export const updateFirebaseStudentStatus = createAction(
	'[Application Details Page] Update Firebase Student Status',
	props<{
		email: string;
		cohortId: number;
		status: number;
	}>(),
);

export const firebaseUpdateProgress = createAction(
	'[Application Details Page] Firebase Update Progress',
	props<{ status: boolean }>(),
);

export const fetchEmailTemplate = createAction(
	'[Application Details Page] Fetch Email Template',
	props<{
		id: string;
	}>(),
);

export const updateEmailContent = createAction(
	'[Application Details Page] Update Email Content',
	props<{
		subject: string;
		content: string;
	}>(),
);

export const genericNotify = createAction(
	'[Application Details Page] Generic Notify',
	props<{
		email: string;
		content: string;
		subject: string;
	}>(),
);

export const updateTimezone = createAction(
	'[Application Details Page] Update Timezone',
	props<{ applicationId: number; timezone: string }>(),
);

export const timezoneUpdateStatus = createAction(
	'[Application Details Page] Timezone Update Status',
	props<{ status: any }>(),
);
export const timezoneUpdateError = createAction(
	'[Application Details Page] Timezone Update Error',
	props<{ error: any }>(),
);

export const updateCountry = createAction(
	'[Application Details Page] Update Country',
	props<{ applicationId: number; country: string }>(),
);

export const countryUpdateStatus = createAction(
	'[Application Details Page] Country Update Status',
	props<{ status: any }>(),
);

export const countryUpdateError = createAction(
	'[Application Details Page] Country Update Error',
	props<{ error: any }>(),
);

export const updateApplicationTags = createAction(
	'[Application Details Page] Update Application Tags',
	props<{
		id: number;
		username: string;
		note: string;
		tags: Array<string>;
	}>(),
);

export const getAllTagGroups = createAction(
	'[Application Details Page] Get All Tag Groups',
);

export const setAllTags = createAction(
	'[Application Details Page] Tags Set',
	props<{ tags: any }>(),
);

export const updateTagProgress = createAction(
	'[Application Details Page] Application Tag Progress Update',
	props<{ status: boolean }>(),
);

export const tagUpdateError = createAction(
	'[Application Details Page] Application Tag Update Error',
	props<{ error: any }>(),
);
