// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	url: {
		cohort_url: 'https://terra-api.terra.do/dev/cohortdev/',
		catlog_url: 'https://terra-api.terra.do/dev/catalogdev/',
		payment_url: 'https://terra-api.terra.do/dev/payment/',
		profile_url: 'https://terra-api.terra.do/dev/profile/',
		portofilo_url: 'https://terra-api.terra.do/dev/portfoliodev/',
		coloudinary_url: 'https://api.cloudinary.com/v1_1/',
		notification_url: 'https://notification-server.terra.do/notification/',
		class_id_url: 'https://api.terra.do/stage/classdev/',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyAit0M9mMKuY3oVcXF-IrFFnvy3ZfliS3w',
		authDomain: 'hubble-159a2.firebaseapp.com',
		databaseURL:
			'https://hubble-159a2-default-rtdb.asia-southeast1.firebasedatabase.app',
		projectId: 'hubble-159a2',
		storageBucket: 'hubble-159a2.appspot.com',
		messagingSenderId: '302493094096',
		appId: '1:302493094096:web:afba897677aa13aa6d8ed5',
		measurementId: 'G-2JMXMHWMQ5',
	},
	endpoint: 'https://yz7e8zx4v1.execute-api.us-east-1.amazonaws.com/test/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
