import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectActiveCohort } from 'src/app/cohorts/redux/cohorts-selectors';
import { AppState } from 'src/app/reducers';
import { getExtraDetails } from 'src/app/students/redux/students.actions';
import { selectExtraDetails } from 'src/app/students/redux/students.selector';
import {
	getApplicationQuestions,
	getInternalProfile,
} from '../redux/student-analytics.actions';
import {
	currentStudentSelector,
	selectApplicationDetails,
	selectApplicationQuestions,
} from '../redux/student-analytics.selectors';

@Component({
	selector: 'app-student-internal-profile',
	templateUrl: './student-internal-profile.component.html',
	styleUrls: ['./student-internal-profile.component.scss'],
})
export class StudentInternalProfileComponent implements OnInit {
	cohortId: any;
	selectApplicationQuestions$: Observable<any> = this.store.select(
		selectApplicationQuestions,
	);
	selectApplicationDetails$: Observable<any> = this.store.select(
		selectApplicationDetails,
	);
	activeCohort$: Observable<any> = this.store.select(selectActiveCohort);
	studentProfile$: Observable<any> = this.store.select(selectExtraDetails);
	currentStudentSelector$: Observable<any> = this.store.select(
		currentStudentSelector,
	);
	currentStudent: any = null;
	profileQuestions: any;
	details: any;
	name: any;
	email: any;
	studentdeal: any;
	noData: boolean;
	profileLoading = true;
	link: any;
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.profileQuestions = [
			{
				title: "Let's start with your name?",
				ref: 'name',
			},
			{
				title: 'What Email Address can we Reach you at?',
				ref: 'email',
			},
			{
				title: 'What Number can we call you at?',
				ref: 'phone',
			},
			{
				title: 'Tell us about yourself?',
				ref: 'about',
			},
			{
				title:
					'Do you want to share your portfolio, perosnal website, linkedIn profile with us?',
				ref: 'portfolio',
			},
			{
				title: 'Why do you want to be a part of this program? ',
				ref: 'reason',
			},
			{
				title:
					'What timezone are you located in (or will be when taking the course )',
				ref: 'timezone',
			},
		];
		// this.route.queryParams.pipe().subscribe(params => {
		//   this.cohortId=params?.cohortId
		//   this.name=params.name
		//   this.email=params.email
		//   console.log(this.name,this.email)
		//   this.store.dispatch(getApplicationQuestions())
		//   this.store.pipe(select(selectApplications),filter(value => !!value && value.length>2)).subscribe(deals=>{
		//     let arr:any=deals
		//     this.studentdeal=arr.find(item=>{
		//       console.log(this.email===item.email,item.email.length)
		//       if(item.name.toLowerCase().includes(this.name?.toLowerCase()) || this.email===item.email){
		//         return item
		//       }
		//     })
		//     console.log("+++++++++++++++++++",deals,this.studentdeal)
		//     if(!this.studentdeal){
		//       this.noData=false
		//     }else{
		//       this.noData=true
		//       this.store.dispatch(getInternalProfile({id:this.studentdeal?.id}))
		//     }

		//   })
		// })
		this.currentStudentSelector$.subscribe(currentStudent => {
			this.currentStudent = currentStudent;
		});
		this.activeCohort$.subscribe(cohort => {
			if (cohort) {
				if (!this.cohortId) {
					this.store.dispatch(getApplicationQuestions());
					this.store.dispatch(
						getExtraDetails({
							cohortId: cohort.id,
							filterDates: [
								moment(cohort.cohortDate)
									.subtract(8, 'months')
									.format('DD/MM/YYYY'),
								moment().format('DD/MM/YYYY'),
							],
							sort: 'cf_created_date',
						}),
					);
					this.cohortId = cohort.cohortId;
				}

				if (this.cohortId !== cohort.cohortId) {
					this.store.dispatch(getApplicationQuestions());
					this.store.dispatch(
						getExtraDetails({
							cohortId: cohort.id,
							filterDates: [
								moment(cohort.cohortDate)
									.subtract(8, 'months')
									.format('DD/MM/YYYY'),
								moment().format('DD/MM/YYYY'),
							],
							sort: 'cf_created_date',
						}),
					);
				}

				combineLatest([this.studentProfile$])
					.pipe()
					.subscribe(([details]) => {
						if (details?.applications?.length > 0) {
							this.details = null;
							const deal = details.applications.find(element => {
								if (this.currentStudent.email === element.email) {
									return element;
								}
							});
							if (deal) {
								this.store.dispatch(getInternalProfile({ id: deal?.id }));
								this.profileLoading = true;
							} else {
								this.noData = true;
								this.profileLoading = false;
							}
						}
					});
			}
		});

		this.selectApplicationQuestions$
			.pipe(filter(value => !!value))
			.subscribe(questions => {
				this.selectApplicationDetails$
					.pipe(filter(item => !!item))
					.subscribe(details => {
						this.profileQuestions = questions.data;
						this.details = details.data;
						this.profileLoading = false;
					});
			});
	}

	redirect() {
		window.open(this.link, '_blank').focus();
	}

	getAnswers(ref) {
		if (ref.includes('first')) {
			return this.details.name.split(' ')[0];
		}

		if (ref.includes('last')) {
			return this.details.name.split(' ')[1];
		}

		if (this.details[ref]) {
			if (ref === 'website') {
				this.link = this.details[ref];
			}
			return this.details[ref];
		} else {
			return 'NA';
		}
	}
}
