import { createAction, props } from '@ngrx/store';
import { Cohort } from 'data-model';
import { SideMenuItem } from './reducers/side-menu-reducers';

export const loadSideMenu = createAction('[Home Page Resolver] Load Menu');

export const sideMenuLoaded = createAction(
	'[Home Page] Side Menu Loaded',
	props<{ cohorts: Cohort[] }>(),
);

export const sideMenuClick = createAction(
	'[Side Menu] Click',
	props<{ menu: SideMenuItem }>(),
);

export const sideMenuSelect = createAction(
	'[Side Menu] Select',
	props<{ menu: SideMenuItem }>(),
);

export const sideMenuRedirected = createAction(
	'[Side Menu] Redirect Complete',
	props<{ menu: SideMenuItem }>(),
);
