import { createReducer, on } from '@ngrx/store';
import * as StudentsActions from './students.actions';

export const studentsFeatureKey = 'students';

export interface StudentsApplicationState {
	students: [];
	error: any;
}
export interface ApplicationFilters {
	cohortId: number;
	productId: number;
	sort: string;
	stageIds: number[];
	page: number;
	courseId: number;
	tags: number[];
	filterDates: string[];
}
export interface StudentsState {
	studentsLoaded: boolean;
	paginationInProgress: boolean;
	applicationFilters: ApplicationFilters;
	studentsApplications: StudentsApplicationState;
	pipelineStages: any;
	activeCohort: any;
	countryList: any;
	groupList: any;
	updateGroupStatus: boolean;
	loading: boolean;
	emailSubject: string;
	emailContent: string;
	emailFrom: string;
	updateInProgress: boolean;
	updateError: boolean;
	error: string;
	orderStatus: any;
	orderError: any;
	groupChangeStatus: any;
	groupChangeError: any;
	uploadStatus: any;
	extraDetails;
	modeList: any;
	linkList: any;
}

export const initialStudentsState: StudentsState = {
	studentsLoaded: false,
	paginationInProgress: false,
	applicationFilters: null,
	studentsApplications: {
		students: [],
		error: null,
	},
	pipelineStages: [],
	activeCohort: null,
	countryList: null,
	groupList: null,
	updateGroupStatus: false,
	loading: false,
	emailSubject: '',
	emailContent: '',
	emailFrom: '',
	updateInProgress: false,
	updateError: null,
	error: '',
	orderStatus: null,
	orderError: null,
	groupChangeStatus: null,
	groupChangeError: null,
	uploadStatus: null,
	extraDetails: null,
	modeList: null,
	linkList: null,
};

export const studentsReducer = createReducer(
	initialStudentsState,
	on(StudentsActions.loadStudentsList, state => ({
		...state,
		studentsLoaded: false,
	})),
	on(StudentsActions.studentListLoaded, (state, action) => {
		return {
			...state,
			studentsApplications: {
				error: null,
				students: action.students,
			},
			studentsLoaded: true,
		};
	}),
	on(StudentsActions.emptyStudentList, state => ({
		...state,
		studentsApplications: {
			error: null,
			students: [],
		},
		studentsLoaded: false,
	})),
	on(StudentsActions.countryListLoaded, (state, action) => {
		return {
			...state,
			countryList: action.countries,
		};
	}),
	on(StudentsActions.groupListLoaded, (state, action) => {
		return {
			...state,
			groupList: action.groups,
		};
	}),
	on(StudentsActions.changeOneGroup, (state, action) => {
		return {
			...state,
			updateGroupStatus: true,
		};
	}),
	on(StudentsActions.changeMultipeGroups, (state, action) => {
		return {
			...state,
			updateGroupStatus: true,
		};
	}),
	on(StudentsActions.resetStudentsDetails, state => initialStudentsState),
	on(StudentsActions.groupChangeProgress, (state, action) => {
		return {
			...state,
			updateGroupStatus: action.status,
		};
	}),
	on(StudentsActions.updateEmailContent, (state, action) => ({
		...state,
		emailSubject: action.subject,
		emailContent: action.content,
		emailFrom: action.emailFrom,
	})),
	on(StudentsActions.studentUpdateError, (state, action) => ({
		...state,
		updateInProgress: false,
		updateError: action.error,
	})),
	on(StudentsActions.sendEmailProgress, state => ({
		...state,
		updateInProgress: true,
	})),
	on(StudentsActions.studentUpdateProgress, (state, action) => ({
		...state,
		updateInProgress: action.status,
	})),
	on(StudentsActions.groupChangeError, (state, action) => {
		return {
			...state,
			error: action.error,
		};
	}),
	on(StudentsActions.emailSendOrderCreated, (state, action) => ({
		...state,
		orderStatus: action.order,
		orderError: null,
	})),
	on(StudentsActions.emailSendOrderError, (state, action) => ({
		...state,
		orderError: action.error,
	})),
	on(StudentsActions.clearOrderStatus, state => ({
		...state,
		orderError: null,
		orderStatus: null,
	})),
	on(StudentsActions.groupChnageOrder, (state, action) => ({
		...state,
		groupChangeStatus: action.order,
	})),
	on(StudentsActions.groupChnageOrderError, (state, action) => ({
		...state,
		groupChangeError: action.error,
	})),
	on(StudentsActions.clearGroupChangeStatus, state => ({
		...state,
		groupChangeStatus: null,
		groupChangeError: null,
	})),
	on(StudentsActions.uploadErrorStatus, (state, action) => ({
		...state,
		uploadStatus: action.uploadStatus,
	})),
	on(StudentsActions.extraDetailsDownloaded, (state, action) => ({
		...state,
		extraDetails: action.details,
	})),
	on(StudentsActions.resetExtraDetails, state => ({
		...state,
		extraDetails: null,
	})),
	on(StudentsActions.resetDraftEmail, state => ({
		...state,
		emailSubject: '',
		emailContent: '',
		emailFrom: '',
	})),
	on(StudentsActions.gotPaymentMode, (state, action) => {
		return {
			...state,
			modeList: action.mode,
		};
	}),
	on(StudentsActions.paymentLinkLoaded, (state, action) => {
		return {
			...state,
			linkList: action.links,
		};
	}),
);
