import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MessagesService } from 'util-lib';
import { selectActiveCohort } from '../cohorts/redux/cohorts-selectors';
import { Character, StudentFilters } from '../models/students-page-model';
import { AppState } from '../reducers';
import {
	getInternalProfileSuccess,
	getSelectedStudent,
} from '../student-analytics/redux/student-analytics.actions';
import * as studentsActions from './redux/students.actions';
import {
	selectCountries,
	selectExtraDetails,
	selectGroups,
	selectMemberError,
	selectPaymentLinks,
	selectPaymentMode,
	selectStudentsProgress,
	selectStudentsState,
	uploadErrorStatus,
} from './redux/students.selector';
import { StudentsFilterComponent } from './students-filter/students-filter.component';
import { StudentsModalComponent } from './students-modal/students-modal.component';
import { UploadStatusComponent } from './upload-status/upload-status.component';

@Component({
	selector: 'app-students',
	templateUrl: './students.component.html',
	styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
	activeCohort$ = this.store.select(selectActiveCohort);
	students$: Observable<any> = this.store.select(selectStudentsState);
	countries$: Observable<any> = this.store.select(selectCountries);
	groups$: Observable<any> = this.store.select(selectGroups);
	paymentModes$: Observable<any> = this.store.select(selectPaymentMode);
	studentsLoaded$: Observable<any> = this.store.select(selectStudentsProgress);
	uploadError$: Observable<any> = this.store.select(uploadErrorStatus);
	extraDetails$: Observable<any> = this.store.select(selectExtraDetails);
	studentsLoaded: any;
	activeCohort: any;
	allStudents: any = [];
	studentsToDisplay: any;
	mainSelect = false;
	checkedList: any;
	filterList: any;
	countryList: any;
	groupList: any;
	paymentModeList: any;
	studentList: any;
	characters$: Observable<Character[]>;
	countrySelected: any[];
	groupSelected: any[];
	statusSelected: any[];
	filters: StudentFilters;
	studentsFromSheet: any;
	chipList: any[];
	paymentLinks: any;
	p: number = 1;
	loader: boolean = true;
	searchedValue: string = '';
	tempAllStudents: any = [];
	lastPage: number = 1;
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private messageService: MessagesService,
		private route: ActivatedRoute,
		public modalController: ModalController,
		private popoverController: PopoverController,
	) {}

	ngOnInit() {
		this.activeCohort$.subscribe(cohort => {
			this.mainSelect = false;
			this.checkedList = [];
			console.log(this.allStudents);
			console.log('cohort changed in students page', cohort);
			if (cohort) {
				this.chipList = [];
				this.p = 1;
				this.lastPage = 1;
				this.loader = true;
				setTimeout(() => {
					this.loader = false;
				}, 5000);
				this.activeCohort = cohort;
				console.log(this.activeCohort);
				this.allStudents = [];
				this.searchedValue = '';
				// this.store.dispatch(studentsActions.loadStudentsList());
				this.store.dispatch(studentsActions.loadCountryList());
				this.store.dispatch(studentsActions.loadGroupList());
				this.store.dispatch(studentsActions.resetExtraDetails());
				this.store.dispatch(studentsActions.getPaymentMode());
				this.getPaymentDetails(cohort.id);
			}
		});
		// this.students$.subscribe(obj => {
		// 	this.studentList = obj.students;
		// 	// console.log(obj);
		// 	// this.allStudents = [];
		// 	// if (obj.students.length > 0) {
		// 	// 	this.allStudents = obj.students.map((student: any) => ({
		// 	// 		...student,
		// 	// 		isSelected: false,
		// 	// 	}));
		// 	// }
		// 	// console.log('new obj', this.allStudents);
		// 	this.studentsToDisplay = [];
		// 	if (obj.students.length > 0) {
		// 		this.studentsToDisplay = obj.students.map((student: any) => ({
		// 			...student,
		// 			isSelected: false,
		// 		}));
		// 		this.allStudents = [...this.studentsToDisplay];
		// 	}
		// 	// console.log('new obj', this.allStudents);
		// });
		this.countries$.subscribe(country => {
			this.countryList = country;
		});
		this.groups$.subscribe(group => {
			this.groupList = group;
		});
		this.paymentModes$.subscribe(mode => {
			this.paymentModeList = mode;
		});
		this.route.queryParams.pipe().subscribe(params => {
			if (this.activeCohort) {
				// console.log('terra.do testtttttttttttt', params);
				this.getPaymentDetails(params.cohortId);
			}
		});
		this.store.dispatch(studentsActions.getPaymentLink());

		this.store
			.select(selectMemberError)
			.pipe(filter(error => !!error))
			.subscribe(error => {
				this.showErrorToast(error);
			});

		this.uploadError$.subscribe(uploadStatus => {
			if (uploadStatus === 'failed') {
				this.showImportStatus('failure');
			} else if (uploadStatus === 'success') {
				this.showImportStatus('success');
				this.clearAllFilters();
			}
			this.store.dispatch(
				studentsActions.uploadErrorStatus({ uploadStatus: null }),
			);
		});
	}

	isAllSelected() {
		this.mainSelect = this.allStudents.every((item: any) => {
			return item.isSelected === true;
		});
		this.getCheckedItemList();
	}

	checkUncheckAll() {
		for (const student of this.allStudents) {
			student.isSelected = this.mainSelect;
		}
		this.getCheckedItemList();
	}
	unCheckAll() {
		for (const student of this.allStudents) {
			student.isSelected = false;
		}
		this.getCheckedItemList();
	}
	getCheckedItemList() {
		this.checkedList = [];
		for (const student of this.allStudents) {
			if (student.isSelected) {
				this.checkedList.push(student);
			}
		}
		// console.log(this.checkedList);
		// this.checkedList = JSON.stringify(this.checkedList);
	}

	async openIonMailModal() {
		if (this.checkedList.length === 0) {
			this.showErrorToast('Please select some students');
			return;
		}
		const modal = await this.modalController.create({
			component: StudentsModalComponent,
			cssClass: 'students-actions-modal',
			backdropDismiss: false,
			componentProps: {
				modalType: 'mail',
				checkedList: this.checkedList,
				activeCohort: this.activeCohort,
			},
		});

		modal.onDidDismiss().then((settings: any) => {
			const { data } = settings;
			if (data.clearStudentDetails) {
				this.store.dispatch(studentsActions.loadStudentsList());
				this.getPaymentDetails(this.activeCohort.id);
				this.unCheckAll();
				this.mainSelect = false;
				this.clearAllFilters();
			}
		});

		return await modal.present();
	}

	showErrorToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			type: 'error',
			title1: 'Error',
			title2: message,
		});
	}
	showSuccessToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			title1: 'Success',
			title2: message,
		});
	}
	async openIonGroupModal() {
		// console.log('checkedList', this.checkedList);
		if (this.checkedList.length === 0) {
			// console.log('error');
			this.showErrorToast('Please select some students');
		} else {
			const modal = await this.modalController.create({
				component: StudentsModalComponent,
				cssClass: 'students-actions-modal',
				backdropDismiss: false,
				componentProps: {
					modalType: 'group',
					groupList: this.groupList,
					checkedList: this.checkedList,
					activeCohort: this.activeCohort,
				},
			});

			modal.onDidDismiss().then((settings: any) => {
				const { data } = settings;
				if (data.clearStudentDetails) {
					this.showSuccessToast('Group Changed sucessfully');
					this.store.dispatch(studentsActions.loadStudentsList());
					this.unCheckAll();
					this.mainSelect = false;
				}
				this.store.dispatch(studentsActions.clearGroupChangeStatus());
			});
			return await modal.present();
		}
	}

	selectTemplate() {
		// console.log('choose template');
	}

	async presentPopover(event: any, filterType) {
		if (filterType === 'country') {
			this.filterList = Object.values(this.countryList)[0];
			// console.log('filterList', this.filterList);
		} else if (filterType === 'group') {
			this.filterList = Object.values(this.groupList)[0];
			this.filterList = this.filterList.map(el => {
				// console.log('el', el.name);
				return el.name;
			});
		} else if (filterType === 'status') {
			const tempStatusList = ['Successful', 'Failed'];
			console.log(tempStatusList);
			this.filterList = tempStatusList;
		}
		const popover = await this.popoverController.create({
			component: StudentsFilterComponent,
			componentProps: {
				filterType,
				filterList: this.filterList,
				chipList: this.chipList,
			},
			cssClass: 'students-filter',
			event,
			translucent: true,
		});

		await popover.present();
		const { data } = await popover.onDidDismiss();
		if (data) {
			this.unCheckAll();
			this.mainSelect = false;
			this.onItemSelected(data);
		}
	}

	onItemSelected(data) {
		// console.log('data', data);
		if (this.countrySelected?.length && data.selectedCountries) {
			data.selectedCountries.forEach(element => {
				if (this.countrySelected.includes(element)) {
					this.countrySelected = this.countrySelected.filter(
						el => el !== element,
					);
				} else {
					this.countrySelected = [...this.countrySelected, element];
				}
			});
		} else if (data.selectedCountries) {
			this.countrySelected = [...data?.selectedCountries];
		}
		// console.log('this.countrySelected', this.countrySelected);
		if (this.groupSelected?.length && data.selectedGroups) {
			data.selectedGroups.forEach(element => {
				if (this.groupSelected.includes(element)) {
					this.groupSelected = this.groupSelected.filter(el => el !== element);
				} else {
					this.groupSelected = [...this.groupSelected, element];
				}
			});
		} else if (data.selectedGroups) {
			this.groupSelected = [...data?.selectedGroups];
		}
		if (this.statusSelected?.length && data.selectedStatus) {
			data.selectedStatus.forEach(element => {
				if (this.statusSelected.includes(element)) {
					this.statusSelected = this.statusSelected.filter(
						el => el !== element,
					);
				} else {
					this.statusSelected = [...this.statusSelected, element];
				}
			});
		} else if (data.selectedStatus) {
			this.statusSelected = [...data?.selectedStatus];
		}
		this.filters = {
			country: this.countrySelected,
			group: this.groupSelected,
			status: this.statusSelected,
		};
		this.chipList = [];
		if (this.countrySelected?.length) {
			this.chipList.push(...this.countrySelected);
		}
		if (this.groupSelected?.length) {
			this.chipList.push(...this.groupSelected);
		}
		if (this.statusSelected?.length) {
			this.chipList.push(...this.statusSelected);
		}
		this.chipList = this.chipList.filter((elem, index, self) => {
			return index === self.indexOf(elem);
		});
		// console.log('chiplist', this.chipList)
		this.reorderStudentList();
		// this.store.dispatch(studentsActions.loadStudentsListwithFilter({filters: this.filters}));
	}

	onTagRemove(chip) {
		this.chipList = this.chipList.filter(el => el !== chip);
		if (this.countrySelected?.length) {
			this.countrySelected = this.countrySelected.filter(el => el !== chip);
		}
		if (this.groupSelected?.length) {
			this.groupSelected = this.groupSelected.filter(el => el !== chip);
		}
		if (this.statusSelected?.length) {
			this.statusSelected = this.statusSelected.filter(el => el !== chip);
		}
		this.filters = {
			country: this.countrySelected,
			group: this.groupSelected,
			status: this.statusSelected,
		};
		this.reorderStudentList();
		// this.store.dispatch(studentsActions.loadStudentsListwithFilter({filters: this.filters}));
	}

	clearAllFilters() {
		if (this.chipList?.length) {
			this.chipList = [];
			this.countrySelected = [];
			this.groupSelected = [];
			this.statusSelected = [];
			this.filters = {
				country: this.countrySelected,
				group: this.groupSelected,
				status: this.statusSelected,
			};
			this.reorderStudentList();
			// this.store.dispatch(studentsActions.loadStudentsList());
		}
	}

	reorderStudentList() {
		this.unCheckAll();
		this.mainSelect = false;
		console.log('/////////////***********//////////');
		this.allStudents = [...this.studentsToDisplay];
		console.log('this.allStudents', this.allStudents);
		if (this.filters.group?.length) {
			this.allStudents = this.allStudents.filter(el =>
				this.filters.group?.includes(el.group),
			);
		}
		if (this.filters.country?.length) {
			this.allStudents = this.allStudents.filter(el =>
				this.filters.country?.includes(el.country),
			);
		}
		if (this.filters.status?.length) {
			console.log(this.filters.status);
			this.allStudents = this.allStudents.filter(el =>
				this.filters.status?.includes(el.status),
			);
		}
		this.tempAllStudents = this.allStudents;
		this.onSearch(this.searchedValue);
	}

	getPaymentDetails(cohortId) {
		this.store.select(selectStudentsState).subscribe(obj => {
			this.studentList = obj.students;
			this.allStudents = [];
			this.studentsToDisplay = [];
			if (obj.students.length > 0) {
				this.studentsToDisplay = obj.students.map((student: any) => ({
					...student,
					isSelected: false,
				}));
				this.allStudents = [...this.studentsToDisplay];
			}
			this.allStudents.sort((a, b) => {
				var textA = a.firstName.toUpperCase() + a.lastName.toUpperCase();
				var textB = b.firstName.toUpperCase() + b.lastName.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
			console.log('new obj new', this.allStudents);
			this.tempAllStudents = this.allStudents;
			this.onSearch(this.searchedValue);
		});
		this.store.select(selectPaymentMode).subscribe(mode => {
			console.log('payment mode', mode);
			this.paymentModeList = mode;
		});
		this.store.select(selectPaymentLinks).subscribe(links => {
			// console.log('selected link', links);
			this.paymentLinks = links;
			console.log('selected link', this.paymentLinks);
		});
		this.store.dispatch(
			studentsActions.getExtraDetails({
				cohortId: cohortId,
				filterDates: [
					moment(this.activeCohort.cohortDate)
						.subtract(8, 'months')
						.format('DD/MM/YYYY'),
					moment().format('DD/MM/YYYY'),
				],
				sort: 'cf_created_date',
			}),
		);
		this.store
			.select(selectExtraDetails)
			.pipe(
				filter(error => !!error),
				// take(1),
			)
			.subscribe(details => {
				this.paymentModeList?.data.forEach(m => {
					this.allStudents.forEach(st => {
						if (st.email === m.email) {
							st.paymentMode = m.paymentMode;
							if (m.lastStatus === 'succeeded') {
								st.status = 'Successful';
							} else {
								st.status = m.lastStatus;
							}
							if (m.amount) {
								st.payReceived = m.amount;
							}
							st.payPending = '0';
							// st.payPending = m.amount;
							st.payLink = null;
							st.payDate = m.lastTransaction;
							// console.log("instalment amout", Math.floor(st.payReceived/3));
							if (m.lastStatus === 'failed') {
								st.status = 'Failed';
								// console.log('failed', st.email);
								// st.payReceived = 600;
								// st.payPending = 600;
								// console.log("checking payment links", this.paymentLinks);
							}
							this.paymentLinks?.data?.forEach(link => {
								if (
									Number(link.price) === Number(Math.floor(st.payReceived / 3))
								) {
									st.payLink = link.paymentLink;
								}
							});
						}
					});
				});
				if (details?.applications?.length > 0) {
					console.log('extraDetails Loaded', details.applications);
					// console.log('this.allStudents', this.allStudents);
					details.applications.forEach(element => {
						if (element.deal_stage === 'Payment received') {
							this.allStudents.forEach(st => {
								if (element.email === st.email) {
									st.paymentMethod = 'Full Payment';
									st.instalmentPending = '-';
									st.instalmentReceived = '-';
									// st.payDate = element.payment_date;
									if (st.payReceived) {
										st.payPending = '0';
									}
									// st.payReceived = element.payment_value;
									// st.payLink = null;
									// st.payPending = element.payment_value;

									element.tags.forEach(tag => {
										if (tag === 'Instalment') {
											st.paymentMethod = 'Instalment';
										}
										if (tag === '1Instalment Pending') {
											st.paymentMethod = 'Instalment';
											st.instalmentPending = 1;
											st.instalmentReceived = 2;

											let amount = Number(element.payment_value);
											st.payPending = amount / 2;
											st.payReceived = amount;
										} else if (tag === '2Instalment Pending') {
											st.paymentMethod = 'Instalment';
											st.instalmentPending = 2;
											st.instalmentReceived = 1;
											let amount = Number(element.payment_value);
											st.payPending = amount * 2;
											st.payReceived = amount;
										} else if (tag === 'Instalment Complete') {
											st.paymentMethod = 'Instalment';
											st.instalmentPending = '0';
											st.instalmentReceived = 3;
											st.payPending = '0';
										}
									});
								}
							});
						}
					});
				}
				if (this.searchedValue.trim().length === 0) {
					this.tempAllStudents = this.allStudents;
				}
			});
	}

	getDate(value) {
		return `${moment(value).format('D-MMM')}`;
	}

	exportToSheet() {
		this.store.dispatch(
			studentsActions.getExtraDetails({
				cohortId: this.activeCohort.id,
				filterDates: [
					moment(this.activeCohort.cohortDate)
						.subtract(8, 'months')
						.format('DD/MM/YYYY'),
					moment().format('DD/MM/YYYY'),
				],
				sort: 'cf_created_date',
			}),
		);
		this.extraDetails$
			.pipe(
				filter(error => !!error),
				take(1),
			)
			.subscribe(details => {
				if (details?.applications?.length > 0) {
					// console.log('extraDetails Loaded', details);
					let newStudentsList = [];
					this.allStudents.forEach(st => {
						const tempObj = {
							firstName: st.firstName,
							lastName: st.lastName,
							email: st.email,
							country: st.country,
							group: st.group,
							paymentMode: st.paymentMode,
							paymentMethod: st.paymentMethod,
							instalmentsreceived: st.instalmentReceived,
							instalmentPending: st.instalmentPending,
							payReceived: st.payReceived,
							payPending: st.payPending,
							payDate: moment(st.payDate).format('D-MMM'),
							status: st.status,
						};
						newStudentsList.push(tempObj);
					});
					console.log('new Students List 1', newStudentsList);
					let finalStudentList = [];
					details.applications.forEach(element => {
						if (element.deal_stage === 'Payment received') {
							newStudentsList.forEach(st => {
								if (element.email === st.email) {
									// console.log("emails same");
									// console.log("tags", element.tags);
									// console.log("student", st);
									if (element.timezone) {
										st.timezone = element.timezone;
									} else {
										st.timezone = null;
									}
									st.tellUsAboutYourSelf = element.about;
									st.whyBootcamp = element.reason;
									st.deal_stage = element.deal_stage;

									// element.tags.forEach(tag => {
									// 	if(tag === "Instalment"){
									// 		st.paymentMode = "Installments";
									// 	}
									// 	if(tag === "1Instalment Pending"){
									// 		st.instalments = "1 Installment Pending";
									// 	}
									// 	else if(tag === "1Instalment Pending"){
									// 		st.instalments = "2 Installments Pending";
									// 	}
									// 	else if (tag === "Instalment Complete"){
									// 		st.instalments = "No installments Left";
									// 	}
									// });

									finalStudentList.push(st);
								}
							});
						}
					});

					const notMatchUser = newStudentsList.filter(
						({ email }) =>
							finalStudentList.map(({ email }) => email).indexOf(email) === -1,
					);

					finalStudentList = [...finalStudentList, ...notMatchUser];
					console.log('new Students List 2', finalStudentList);
					if (finalStudentList.length > 0 && details) {
						this.exportCSVFile(
							{
								firstName: 'First name',
								lastName: 'Last name',
								email: 'Email id',
								country: 'Country name',
								group: 'Group name',
								paymentMode: 'Payment Mode',
								paymentMethod: 'Payment Method',
								instalmentsreceived: 'Instalment Received',
								instalmentPending: 'Instalment Pending',
								payReceived: 'Amount Received',
								payPending: 'Amount Pending',
								payDate: 'Last Charge Date',
								status: 'Charge Status',
								timezone: 'Timezone',
								tellUsAboutYourSelf: 'Tell us about yourself',
								whyBootcamp: 'Why bootcamp',
								deal_stage: 'Deal Stage',
							},
							finalStudentList,
							'Student List',
						);
					} else {
						this.showErrorToast('No students available');
					}
				}
			});
	}

	convertToCSV(objArray) {
		var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		var str = '';

		for (var i = 0; i < array.length; i++) {
			var line = '';
			for (var index in array[i]) {
				if (line !== '') line += ',';

				line += array[i][index];
			}

			str += line + '\r\n';
		}

		return str;
	}

	exportCSVFile(headers, items, fileTitle) {
		var jsonObject = JSON.stringify(items);

		var csv = this.convertToCSV(jsonObject);

		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: false,
			// title: 'Group List',
			useBom: true,
			noDownload: false,
			headers: [
				'First Name',
				'Last Name',
				'Email',
				'Country',
				'Group',
				'Payment Mode',
				'Payment Method',
				'Instalment Received',
				'Instalment Pending',
				'Amount Received',
				'Amount Pending',
				'Last Charge Date',
				'Charge Status',
				'TimeZone',
				'Tell us about yourself',
				'Why Bootcamp',
				'Deal Stage',
			],
		};
		return new ngxCsv(
			[
				...items.map(kv => {
					var r = { ...kv };
					for (var k in r) if (!r[k]) delete r[k];
					return r;
				}),
			],
			fileTitle + '.csv',
			options,
		);
	}

	handleFileInput(event) {
		// console.log('handle file input ', event);

		const selectedFile = event.target.files[0] as File;
		// console.log('file ', selectedFile);
		let extension = '';
		if (!!selectedFile) {
			extension = selectedFile.name.split('.')[
				selectedFile.name.split('.').length - 1
			];
			// console.log("extension ", extension, selectedFile.name.split("."));
			const formData: FormData = new FormData();
			formData.append('file', selectedFile);
			this.store.dispatch(
				studentsActions.changeMultipeGroupsViaFile({
					file: formData,
				}),
			);
		}
	}

	async showImportStatus(status) {
		const modal = await this.modalController.create({
			component: UploadStatusComponent,
			cssClass: 'students-upload-status',
			backdropDismiss: false,
			componentProps: {
				modalType: 'loading',
				statusType: status,
			},
		});

		modal.onDidDismiss();

		return await modal.present();
	}
	onStudentClick(
		email: string = null,
		name: string = null,
		studentProfileId: number = null,
	) {
		this.store.dispatch(getInternalProfileSuccess({ data: null }));
		this.store.dispatch(getSelectedStudent({ id: studentProfileId }));
		const queryParams: Params = {
			id: studentProfileId,
			cohortId: this.activeCohort.id,
		};
		this.router.navigate(['/student/analytics'], { queryParams });
	}

	onEyeClick(student) {
		console.log('eye', student);
	}
	onLinkClick(student) {
		console.log('link', student);
	}
	onCertificateClick(student) {
		console.log('certi', student);
	}

	trackByFn(index: number, item: any) {
		return item.id;
	}
	onSearch(searchString) {
		this.searchedValue = searchString;
		this.allStudents = this.tempAllStudents;
		if (searchString.trim().length > 0) {
			this.p = 1;
			let searchStudentsList = [];
			const nameList = this.allStudents.filter(tempStudent => {
				// console.log(tempStudent);
				let tempFirstName = tempStudent.firstName.toLowerCase();
				let tempLastName = tempStudent.lastName.toLowerCase();
				let tempFullName = tempFirstName + ' ' + tempLastName;
				if (tempFullName.includes(searchString.trim().toLowerCase())) {
					return tempStudent;
				}
				// else if (tempLastName.includes(searchString.toLowerCase())) {
				// 	return tempStudent;
				// }
			});

			searchStudentsList.push(...nameList);
			const emailList = this.allStudents.filter(tempStudent => {
				// console.log(tempStudent);
				let tempEmail = tempStudent.email.toLowerCase();
				if (tempEmail.includes(searchString.trim().toLowerCase())) {
					return tempStudent;
				}
			});
			searchStudentsList.push(...emailList);
			searchStudentsList = [...new Set(searchStudentsList)];
			console.log('search List', searchStudentsList);
			this.allStudents = searchStudentsList;
		} else {
			this.p = this.lastPage;
		}
	}
	pageChanged(event) {
		this.p = event;
		this.lastPage = this.p;
	}
	ionViewDidLeave() {
		this.searchedValue = '';
	}
}
