import { Injectable } from '@angular/core';
import {
	Resolve,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';
import { selectSideMenuState } from '../side-menu/side-menu.selectors';
import { SideMenuActions } from '../side-menu/side-menu-action-types';
import { selectCohortsLoaded } from '../cohorts/redux/cohorts-selectors';
// import { initializeMentorRequestPage } from './redux/mentor-request-actions';

@Injectable()
export class StudentsResolver implements Resolve<any> {
	constructor(private store: Store<AppState>) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<any> {
		return this.store.pipe(
			select(selectCohortsLoaded),
			tap(cohortsLoaded => {
				this.store
					.pipe(select(selectSideMenuState))
					.pipe(
						tap(sideMenuState => {
							if (!sideMenuState.sideMenu.length) {
								this.store.dispatch(SideMenuActions.loadSideMenu());
							}
						}),
						filter(sideMenu => sideMenu.sideMenu.length > 0),
						first(),
						tap(sideMenuState => {
							const activeMenu = sideMenuState.sideMenu.find(
								item => item.id === sideMenuState.selected,
							);

							if (activeMenu.url !== state.url) {
								this.store.dispatch(
									SideMenuActions.sideMenuSelect({
										menu: sideMenuState.sideMenu.find(item =>
											state.url?.includes(item.url),
										),
									}),
								);
							}
						}),
					)
					.subscribe();
			}),
			first(),
		);
	}
}
