import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { TerraCohortsService } from 'data-model';
import { from, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	map,
	mergeMap,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import {
	selectActiveCohort,
	selectActiveCohortState,
} from 'src/app/cohorts/redux/cohorts-selectors';
import { MessagesService } from 'util-lib';
import { AppState } from '../../reducers';
import * as CourseContentActions from './course-content-actions';
import { createWeekStatusResponse } from './course-content-actions';
import {
	currentCohortInstructors,
	selectAdditionalResources,
	selectAssignmentsForCohort,
	selectClassesForCohort,
	selectClassModuleList,
	selectCurrentCohortSyllabusLoaded,
	selectedAddResourceUpdated,
	selectedAssignmentUpdated,
	selectedClassUpdated,
	selectedInstructorUpdated,
	selectedSyllabusUpdated,
	selectInstructorLoaded,
} from './course-content-selector';

@Injectable()
export class CourseContentEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
		private terraCohortsService: TerraCohortsService,
		private message: MessagesService,
	) {}

	loadClassesForCohort$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadClassesForCohort),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectClassesForCohort),
				this.store.select(selectedClassUpdated),
				this.store.select(selectActiveCohortState),
			),
			filter(
				([action, token, cohortClasses, classUpdated, sameCohort]) =>
					!cohortClasses || classUpdated || !sameCohort,
			),
			switchMap(([action, token]) =>
				this.bobService.loadClassesForCohort(token, action.cohortId).pipe(
					mergeMap(response => [
						CourseContentActions.classesForCohortLoaded({
							response: [...response].sort(
								(a, b) => a.startDateTime - b.startDateTime,
							),
						}),
						CourseContentActions.activeClassUpdated({ status: false }),
						CourseContentActions.setCourseActiveCohort({
							cohortId: action.cohortId,
						}),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.classesForCohortError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	loadClassModuleList$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadClassModuleList),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectClassesForCohort),
				this.store.select(selectedClassUpdated),
				this.store.select(selectActiveCohortState),
			),
			filter(
				([action, token, cohortClasses, classUpdated, sameCohort]) =>
					!cohortClasses || classUpdated || !sameCohort,
			),
			switchMap(([action, token]) =>
				this.bobService.classModuleList(token).pipe(
					mergeMap(response => [
						CourseContentActions.classModuleListLoaded({
							response,
						}),
						CourseContentActions.activeClassUpdated({ status: false }),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.classModuleListError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	createClassContainer$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.createClassContainer),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.createClassContainer(token, action.name).pipe(
					map(response => CourseContentActions.ContainerCreated({ response })),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.createClassContainerError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	createClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.createClass),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ classBody }, token]) =>
				this.bobService.createClassForCohort(classBody, token).pipe(
					mergeMap(response => [
						CourseContentActions.classCreated({
							status: !!response,
						}),
						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.createClassError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	updateClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateClass),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ classData }, token]) =>
				this.bobService.updateCohortClass(classData, token).pipe(
					mergeMap(response => [
						CourseContentActions.classUpdated({
							status: !!response,
						}),
						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.classUpdateError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	deleteClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteClass),
			withLatestFrom(this.authStore.firebaseToken$),
			switchMap(([action, token]) =>
				this.bobService.deleteClassFromCohort(token, action.classId).pipe(
					mergeMap((data: any) => [
						CourseContentActions.classDeleted({
							status: data,
						}),
						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.classDeleteError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	reloadClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reloadClass),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			concatMap(([action, cohort, token]) =>
				of(
					CourseContentActions.loadClassesForCohort({
						cohortId: cohort.id,
					}),
				),
			),
		),
	);

	// loadSyllabus$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType(CourseContentActions.loadSyllabus),
	// 		withLatestFrom(
	// 			this.store.pipe(select(selectCurrentCohortSyllabusLoaded)),
	// 			// this.store.pipe(select(selectSyllabusDetail)),
	// 			this.authStore.firebaseToken$,
	// 		),
	// 		concatMap(([action, isSyllabusLoaded, token]) => {
	// 			if (!isSyllabusLoaded) {
	// 				return from(
	// 					this.terraCohortsService.getSyllabusByCohortId(
	// 						action.cohortId,
	// 						token,
	// 					),
	// 				).pipe(
	// 					catchError(error => {
	// 						if (error.status === 401) {
	// 							this.authStore.logout();
	// 						}
	// 						return throwError(error.error);
	// 					}),
	// 				);
	// 			} else {
	// 				return of(false);
	// 			}
	// 		}),
	// 		filter(syllabus => !!syllabus),
	// 		map(syllabusDetail =>
	// 			CourseContentActions.syllabusLoaded({ syllabusDetail }),
	// 		),
	// 	),
	// );

	loadSyllabus$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadSyllabus),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.pipe(select(selectCurrentCohortSyllabusLoaded)),
				this.store.pipe(select(selectedSyllabusUpdated)),
				this.store.pipe(select(selectActiveCohortState)),
			),
			filter(
				([action, token, isSyllabusLoaded, syllabusUpdated, sameCohort]) =>
					!isSyllabusLoaded || syllabusUpdated || !sameCohort,
			),
			switchMap(([action, token]) =>
				this.bobService.loadSyllabusForCohort(token, action.cohortId).pipe(
					mergeMap(response => [
						CourseContentActions.syllabusLoaded({ syllabusDetail: response }),
						CourseContentActions.activeSyllabusUpdated({ status: false }),
						CourseContentActions.setCourseActiveCohort({
							cohortId: action.cohortId,
						}),
					]),
					catchError(error => {
						if (error.status === 401) {
							this.authStore.logout();
						}
						return throwError(error.error);
					}),
				),
			),
		),
	);

	reloadSyllabus$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reloadSyllabus),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			concatMap(([action, cohort, token]) =>
				of(
					CourseContentActions.loadSyllabus({
						cohortId: cohort.id,
					}),
				),
			),
		),
	);

	loadCohortInstructor$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadCohortInstructor),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(currentCohortInstructors),
				this.store.select(selectedInstructorUpdated),
				this.store.select(selectActiveCohortState),
			),
			filter(
				([action, token, cohortInstrucor, instructorUpdated, sameCohort]) =>
					!cohortInstrucor || instructorUpdated || !sameCohort,
			),
			switchMap(([action, token]) =>
				this.bobService.getCourseInstructor(token, action.cohortId).pipe(
					map((response: any[]) => {
						const tweakedResponse: any[] = [];
						response.map(value => {
							const key = value.name;
							const object = {};
							value.people.roleId = value.roleId;
							object[key] = value.people;
							tweakedResponse.push(object);
						});
						const responses: any = [];
						tweakedResponse.filter(res => {
							const roleCourse = Object.keys(res)[0];
							Object.values(res).filter((single: any) => {
								single.filter(si => {
									const extraProfile = [];
									// if (si.profileId) {
									// 	extraProfile.push({
									// 		key: 'Terra.do profile',
									// 		value: si.profileId,
									// 	});
									// }

									if (si.calendlyLink) {
										extraProfile.push({
											key: 'Calendly',
											value: si.calendlyLink,
										});
									}

									if (si.website) {
										extraProfile.push({ key: 'Website', value: si.website });
									}

									if (si.linkedin) {
										extraProfile.push({ key: 'LinkedIn', value: si.linkedin });
									}

									if (si.twitter) {
										extraProfile.push({ key: 'Twitter', value: si.twitter });
									}
									responses.push({
										...si,
										roleCourse,
										extraProfile,
										roleId: single.roleId,
									});
								});
							});
						});
						return responses;
					}),
					map((response: any[]) => {
						return [
							...response.filter(
								couse => couse.roleCourse === 'Course Director',
							),
							...response.filter(couse => couse.roleCourse === 'Instructor'),
							...response.filter(
								couse => couse.roleCourse === 'Guest Lecturer',
							),
							...response.filter(
								couse =>
									couse.roleCourse !== 'Course Director' &&
									couse.roleCourse !== 'Instructor' &&
									couse.roleCourse !== 'Guest Lecturer',
							),
						];
					}),
					mergeMap((response: any[]) => [
						CourseContentActions.cohortInstructorLoded({ response }),
						CourseContentActions.activeInstructorUpdated({ status: false }),
						CourseContentActions.setCourseActiveCohort({
							cohortId: action.cohortId,
						}),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.classesForCohortError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	updateSyllabusDetail$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateSyllabusDetail),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) => {
				return from(
					this.bobService.updateSyllabus(token, action.syllabusDetail),
				).pipe(
					mergeMap(response => [
						CourseContentActions.updatedSyllabusDetailOnStore({
							syllabusDetail: response,
						}),
						CourseContentActions.activeSyllabusUpdated({ status: true }),
						CourseContentActions.reloadSyllabus(),
					]),
					catchError(error => {
						if (error.status === 401) {
							this.authStore.logout();
						}
						return throwError(error.error);
					}),
				);
			}),
		),
	);

	updateInstructorDetail$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateInstructorDetail),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) => {
				return from(
					this.bobService.updateInstructors(token, action.instructors),
				).pipe(
					map((response: any) => {
						const bool = true;
						let error = '';
						response.filter(rs => {
							if (rs.message !== ' 1 instructor updated.') {
								error = rs.message;
							}
						});
						if (bool) {
							this.message.showMsg({
								icon: 'checkmark-circle-outline',
								title1: 'Updated!',
								title2: 'Course Instructors successfully updated',
							});
							return action.instructors;
						} else {
							return throwError(error);
						}
					}),
					catchError(error => {
						if (error.status === 401) {
							this.authStore.logout();
						}
						return throwError(error.error);
					}),
				);
			}),
			map((response: any) =>
				CourseContentActions.cohortInstructorLoded({ response }),
			),
		),
	);

	loadAssignmentsForCohort$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadAssignmentsForCohort),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectAssignmentsForCohort),
				this.store.select(selectedAssignmentUpdated),
				this.store.select(selectActiveCohortState),
			),
			filter(
				([action, token, cohortAssignment, assignmentUpdated, sameCohort]) =>
					!cohortAssignment || assignmentUpdated || !sameCohort,
			),
			switchMap(([action, token]) =>
				this.bobService.loadAssignmentsForCohort(token, action.cohortId).pipe(
					mergeMap(response => [
						CourseContentActions.assignmentsForCohortLoaded({
							response: [
								...response.map((res, index) => {
									return {
										...res,
										uid: index + 1,
									};
								}),
							],
						}),
						CourseContentActions.activeAssignmentUpdated({ status: false }),
						CourseContentActions.setCourseActiveCohort({
							cohortId: action.cohortId,
						}),
						// CourseContentActions.assignmentError({ status: false }),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.assignmentsForCohortError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	createAssignment$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.createAssignment),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ assignmentBody }, token]) =>
				this.bobService
					.createAssignmentForCohort(
						assignmentBody,
						assignmentBody.cohortId,
						token,
					)
					.pipe(
						mergeMap(response => [
							CourseContentActions.assignmentCreated({
								status: !!response,
							}),
							CourseContentActions.activeAssignmentUpdated({ status: true }),
							CourseContentActions.reloadAssignment(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.createAssignmentError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	deleteAssignment$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteAssignment),
			withLatestFrom(this.authStore.firebaseToken$),
			switchMap(([action, token]) =>
				this.bobService
					.deleteAssignmentFromCohort(
						token,
						action.assignmentId,
						action.cohortId,
					)
					.pipe(
						mergeMap(response => [
							CourseContentActions.assignmentDeleted({
								status: response,
							}),
							CourseContentActions.activeAssignmentUpdated({ status: true }),
							CourseContentActions.reloadAssignment(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.assignmentDeleteError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	updateAssignment$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateAssignment),
			withLatestFrom(this.authStore.firebaseToken$),
			switchMap(([{ assignmentData }, token]) =>
				this.bobService
					.updateCohortAssignment(
						assignmentData,
						assignmentData.cohortId,
						token,
					)
					.pipe(
						mergeMap(response => [
							CourseContentActions.assignmentUpdated({
								status: !!response,
							}),
							CourseContentActions.activeAssignmentUpdated({ status: true }),
							CourseContentActions.reloadAssignment(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.assignmentUpdateError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	reloadAssignment$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reloadAssignment),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			concatMap(([action, cohort, token]) =>
				of(
					CourseContentActions.loadAssignmentsForCohort({
						cohortId: cohort.id,
					}),
				),
			),
		),
	);

	reloadInstructor$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reloadInstructor),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			concatMap(([action, cohort, token]) =>
				of(
					CourseContentActions.loadCohortInstructor({
						cohortId: cohort.id,
					}),
				),
			),
		),
	);

	loadAuthors$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadAuthors),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectClassesForCohort),
				this.store.select(selectedClassUpdated),
				this.store.select(selectActiveCohortState),
			),
			switchMap(([action, token]) =>
				this.bobService.getPeopleList(token).pipe(
					mergeMap(response => [
						CourseContentActions.loadedAuthors({
							authors: response,
						}),
						CourseContentActions.activeClassUpdated({ status: false }),
					]),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.loadAuthorsError({
								error: response.error,
							}),
						);
					}),
				),
			),
			// map(() => CourseContentActions.reloadAssignment()),
		),
	);
	updateInstructor$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateInstructor),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.updateInstructor(token, action.instructor).pipe(
					map(response =>
						CourseContentActions.instructorUpdated({
							status: !!response,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.instructorUpdateError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	deleteInstructor$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteInstructor),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService
					.deleteInstructor(
						token,
						action.instructorId,
						action.roleId,
						action.cohortId,
					)
					.pipe(
						mergeMap(response => [
							CourseContentActions.instructorDeleted({
								status: response,
							}),
							CourseContentActions.activeInstructorUpdated({ status: true }),
							CourseContentActions.reloadInstructor(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.instructorDeleteError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	loadAdditionalResource$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.loadCohortAdditionalResource),
			withLatestFrom(
				this.authStore.firebaseToken$,
				this.store.select(selectAdditionalResources),
				this.store.select(selectedAddResourceUpdated),
				this.store.select(selectActiveCohortState),
			),
			filter(
				([action, token, cohortResources, resourcesUpdated, sameCohort]) =>
					!cohortResources || !resourcesUpdated || !sameCohort,
			),
			concatMap(([action, token]) =>
				this.bobService
					.loadAdditionalResourcesForCohort(token, action.cohortId)
					.pipe(
						mergeMap(response => [
							CourseContentActions.cohortAdditionalResourcesLoaded({
								response,
							}),
							CourseContentActions.activeAddResourcesUpdated({ status: false }),
							CourseContentActions.setCourseActiveCohort({
								cohortId: action.cohortId,
							}),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.additionalResourceLoadError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	addAdditionalResource$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.addAdditionalResource),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.addAdditionalResource(token, action.additionalResource)
					.pipe(
						mergeMap(response => [
							CourseContentActions.activeAddResourcesUpdated({ status: true }),
							CourseContentActions.additionalResourceAdded({
								status: !!response,
							}),
							CourseContentActions.reloadAdditionalResources(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.additionalResourceAddError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	updateAdditionalResource$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateAdditionalResource),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.updateAdditionalResource(token, action.additionalResource)
					.pipe(
						mergeMap(response => [
							CourseContentActions.additionalResourceUpdated({
								status: !!response,
							}),
							CourseContentActions.activeAddResourcesUpdated({ status: true }),
							CourseContentActions.reloadAdditionalResources(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.additionalResourceUpdateError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	deleteAdditionalResource$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteAdditionalResource),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.deleteAdditionalResource(token, action.additionalResourceId)
					.pipe(
						mergeMap(response => [
							CourseContentActions.additionalResourceDeleted({
								status: response,
							}),
							CourseContentActions.activeAddResourcesUpdated({ status: true }),
							CourseContentActions.reloadAdditionalResources(),
						]),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CourseContentActions.additionalResourceDeleteError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	reloadAdditionalResources$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reloadAdditionalResources),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			concatMap(([action, cohort, token]) =>
				of(
					CourseContentActions.loadCohortAdditionalResource({
						cohortId: cohort.id,
					}),
				),
			),
		),
	);

	uploadFileForCohort$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.fileUploadForCohort),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.fileUpload(action.folder, action.file, token).pipe(
					map(response => {
						return CourseContentActions.fileUploadForCohortUrl({
							url: response.url,
							id: action.id,
						});
					}),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.fileUploadForCohortError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	// week section
	createWeek$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.createWeek),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			switchMap(([{ week }, cohort, token]) =>
				this.bobService.createWeekTimeline(week, cohort, token).pipe(
					mergeMap((data: any) => [
						createWeekStatusResponse({ status: data }),
						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						return of(
							CourseContentActions.createWeekStatusResponse({
								status: { message: 'error' },
							}),
						);
					}),
				),
			),
		),
	);

	deleteWeek$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteWeek),
			withLatestFrom(
				this.store.select(selectActiveCohort),
				this.authStore.firebaseToken$,
			),
			switchMap(([{ id }, cohort, token]) =>
				this.bobService.deleteWeekTimeline(id, cohort, token).pipe(
					mergeMap((data: any) => [
						CourseContentActions.deleteWeekStatusResponse({ status: data }),
						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						return of(
							CourseContentActions.deleteWeekStatusResponse({
								status: { message: 'error' },
							}),
						);
					}),
				),
			),
		),
	);

	copyClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.copyClass),
			withLatestFrom(this.authStore.firebaseToken$),
			filter(([action, token]) => !!action.oldId),
			switchMap(([action, token]) =>
				this.bobService
					.copyClassService(
						action.oldId,
						action.newId,
						token,
						action.confirmation,
					)
					.pipe(
						mergeMap((data: any) => [
							CourseContentActions.copyClassStatusResponse({ status: data }),
							CourseContentActions.copyClassOverride({ status: false }),
							// CourseContentActions.activeClassUpdated({ status: true }),
							// CourseContentActions.reloadClass(),
						]),
						catchError(response => {
							console.log(response);
							return of(
								CourseContentActions.copyClassStatusResponse({
									status: {
										message: response.error?.error
											? response.error.error
											: response.message,
									},
								}),
							);
						}),
					),
			),
		),
	);

	submitClassId$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.submitClassId),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.submitClassIdService(action.id, token).pipe(
					map(response => {
						return CourseContentActions.submitClassIdStatus({
							status: response,
						});
					}),
					catchError(response => {
						console.log(response);
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.submitClassIdStatus({
								status: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	checkClassSaved$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.checkClassSaved),
			withLatestFrom(this.authStore.firebaseToken$),
			switchMap(([action, token]) =>
				this.bobService.checkClassSavedService(action.id, token).pipe(
					mergeMap(response => [
						CourseContentActions.checkClassSavedStatus({
							status: response,
						}),
						CourseContentActions.checkClassSavedStatus({ status: null }),
					]),
					catchError(response => {
						console.log(response);
						if (response.status === 401) {
							this.authStore.logout();
						}
						if (response.status === 404) {
							return of(
								CourseContentActions.checkClassSavedStatus({
									status: 404,
								}),
							);
						} else {
							return of(
								CourseContentActions.checkClassSavedStatus({
									status: response.error,
								}),
							);
						}
					}),
				),
			),
		),
	);

	hideClass$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.hideClass),
			withLatestFrom(this.authStore.firebaseToken$),
			tap(() => {
				this.store.dispatch(CourseContentActions.updatingClass());
			}),
			switchMap(([action, token]) =>
				this.bobService.hideClass(action.classid, token).pipe(
					tap(() => {
						this.store.dispatch(CourseContentActions.updatingClass());
					}),
					mergeMap(response => [
						CourseContentActions.hideClassStatus({
							status: response,
						}),

						CourseContentActions.activeClassUpdated({ status: true }),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						console.log(response);
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.hideClassStatus({
								status: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	setNewModule$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.setNewModule),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.createNewModule(action.moduleName, token).pipe(
					mergeMap(response => [
						CourseContentActions.setNewModuleSuccess({
							moduleNameResponse: response,
						}),
						CourseContentActions.reloadClass(),
					]),
					catchError(response => {
						console.log(response);
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CourseContentActions.setNewModuleSuccess({
								moduleNameResponse: response,
							}),
						);
					}),
				),
			),
		),
	);
	addNewModule$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.addNewModule),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.addNewModule(
						action.name,
						action.duration,
						action.moduleId,
						action.mandatory,
						action.classEventId,
						action.authors.map(a => a.id),
						action.moduleOrder,
						token,
					)
					.pipe(
						map(response => {
							this.showSuccessToast('Module Added SuccessFully');
							return CourseContentActions.updateModuleList({
								response: response,
								payload: action,
							});
						}),
						catchError(response => {
							console.log(response.error.error);
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	updateClassSubModule$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.updateClassSubModule),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.updateClassSubModule(
						action.name,
						action.duration,
						action.moduleId,
						action.mandatory,
						action.classEventId,
						action.authors.map(a => a.id),
						action.id,
						action.moduleOrder,
						token,
					)
					.pipe(
						map(response => {
							this.showSuccessToast('Module Updated Successfully');
							return CourseContentActions.classSubModuleUpdated({
								response: action,
							});
						}),
						catchError(response => {
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	deleteClassSubModule$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.deleteClassSubModule),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.deleteClassSubModule(action.id, token).pipe(
					map(response => {
						this.showSuccessToast('Module Deleted Successfully');
						return CourseContentActions.classSubModuleDeleted({
							response: action,
						});
					}),
					catchError(response => {
						this.showErrorToast(response.error.error);
						if (response.status === 401) {
							this.authStore.logout();
						}
						throw response;
					}),
				),
			),
		),
	);
	reorderModules$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.reorderModules),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.reorderModules(action.data, token).pipe(
					map(response => {
						this.showSuccessToast('Module Reordered Successfully');
						return CourseContentActions.classSubModuleReordered({
							response: action,
						});
					}),
					catchError(response => {
						this.showErrorToast(response.error.error);
						if (response.status === 401) {
							this.authStore.logout();
						}
						throw response;
					}),
				),
			),
		),
	);
	bulkUploadSections$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.bulkUploadSections),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService
					.bulkUploadSections(action.cohortId, action.file, token)
					.pipe(
						map(response => {
							this.showSuccessToast(response.message);
							this.store.dispatch(
								CourseContentActions.activeClassUpdated({ status: true }),
							);
							this.store.dispatch(CourseContentActions.reloadClass());
							return CourseContentActions.bulkUploadSectionsSuccess({
								response: response,
							});
							// return response
						}),
						catchError(response => {
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	bulkUploadClassEvents$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.bulkUploadClassEvents),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.bulkUploadClassEvent(action.cohortId, action.file, token)
					.pipe(
						map(response => {
							this.showSuccessToast(response.message);
							return CourseContentActions.bulkUploadClassEventsSuccess({
								response: response,
							});
						}),
						catchError(response => {
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	bulkUploadModules$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.bulkUploadModules),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.bulkUploadModules(action.cohortId, action.file, token)
					.pipe(
						map(response => {
							this.showSuccessToast(response.message);
							return CourseContentActions.bulkUploadModulesSuccess({
								response: response,
							});
						}),
						catchError(response => {
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	bulkUploadCalendarEvents$ = createEffect(() =>
		this.actions.pipe(
			ofType(CourseContentActions.bulkUploadCalendarEvents),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService
					.bulkUploadCalendarEvents(action.cohortId, action.file, token)
					.pipe(
						map(response => {
							this.showSuccessToast(response.message);
							return CourseContentActions.bulkUploadCalendarEventsSuccess({
								response: response,
							});
						}),
						catchError(response => {
							this.showErrorToast(response.error.error);
							if (response.status === 401) {
								this.authStore.logout();
							}
							throw response;
						}),
					),
			),
		),
	);
	showSuccessToast(message) {
		this.message.showMsg({
			icon: 'checkmark-circle-outline',
			title1: 'Success!',
			title2: message,
		});
	}
	showErrorToast(message) {
		this.message.showMsg({
			icon: 'alert-circle-outline',
			type: 'error',
			title1: 'Error',
			title2: message,
		});
	}
}
