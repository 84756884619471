import { Component, Input, OnInit } from '@angular/core';
import { AuthStoreService } from 'auth-lib';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { combineLatest, Observable } from 'rxjs';
import { SideMenuActions } from './side-menu-action-types';
import { ActionSheetController } from '@ionic/angular';
import { MessagesService } from 'util-lib';
import { selectActiveMenuId, selectAllMenu } from './side-menu.selectors';
import { SideMenuItem } from './reducers/side-menu-reducers';
import { map } from 'rxjs/operators';
import { selectActiveCohort } from '../cohorts/redux/cohorts-selectors';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
	@Input() viewType: 'desktop' | 'mobile' = 'desktop';

	sideMenu$: Observable<{
		allMenu: SideMenuItem[];
		activeMenuId: number;
	}>;
	cohortId: number;

	constructor(
		public auth: AuthStoreService,
		public router: Router,
		private store: Store<AppState>,
		public messages: MessagesService,
		public actionSheetController: ActionSheetController,
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.sideMenu$ = combineLatest([
			this.store.pipe(select(selectAllMenu)),
			this.store.pipe(select(selectActiveMenuId)),
		]).pipe(
			map(([allMenu, activeMenuId]) => {
				return {
					allMenu,
					activeMenuId,
				};
			}),
		);
	}

	ngOnInit() {
		this.store.pipe(select(selectActiveCohort)).subscribe(cohort => {
			cohort ? (this.cohortId = cohort.id) : (this.cohortId = null);
		});
	}

	redirect(menu) {
		console.log('menu', menu);
		this.store.dispatch(SideMenuActions.sideMenuRedirected({ menu }));
		this.router.navigate([menu.url], {
			queryParams: {
				cohortId: this.cohortId,
			},
		});

		// this.store.dispatch(SideMenuActions.sideMenuRedirected({ menu }));
	}

	trackByFn(index: number, item: any) {
		return item.id;
	}
}
