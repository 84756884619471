import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CohortsEffects } from '../cohorts/redux/cohorts-effects';
import { cohortsKey, cohortsReducer } from '../cohorts/redux/cohorts-reducer';
import { CourseContentEffects } from '../course-content/redux/course-content-effects';
import { courseContentKey, courseContentReducer } from '../course-content/redux/course-content-reducer';
import { DashboardEffects } from '../home/redux/dashboard-effects';
import { dashboardKey, dashboardReducer } from '../home/redux/dashboard-reducer';
import { StudentsEffects } from '../students/redux/students.effects';
import { CohortSelectorComponent } from './cohort-selector.component';

@NgModule({
	imports: [NgSelectModule, FormsModule, CommonModule,
		StoreModule.forFeature(cohortsKey, cohortsReducer),
		StoreModule.forFeature(dashboardKey, dashboardReducer),
		StoreModule.forFeature(courseContentKey, courseContentReducer),
		EffectsModule.forFeature([CohortsEffects, DashboardEffects, CourseContentEffects,StudentsEffects]),
	],
	declarations: [CohortSelectorComponent],
	entryComponents: [CohortSelectorComponent],
	exports: [CohortSelectorComponent],
})
export class CohortSelectorModule { }
