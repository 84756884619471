import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MiniNavbarComponent } from './mini-navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [MiniNavbarComponent],
  exports: [MiniNavbarComponent],

})
export class MiniNavbarModule { }
