import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MessagesService } from 'util-lib';
import * as CohortAnalyticsActions from '../cohort-analytics/redux/cohort-analytics.actions';
import {
	CohortMixPanelAPI,
	studentCohortMixpanelData,
} from '../cohort-analytics/redux/cohort-analytics.selectors';
import { setActiveCohort } from '../cohorts/redux/cohorts-actions';
import {
	selectActiveCohort,
	selectCohortsList,
} from '../cohorts/redux/cohorts-selectors';
import {
	selectAssignmentsForCohort,
	selectClassesForCohort,
} from '../course-content/redux/course-content-selector';
import { AppState } from '../reducers';
import { loadStudentsList } from '../students/redux/students.actions';
import {
	selectStudentsProgress,
	selectStudentsState,
} from '../students/redux/students.selector';
import Utils from '../Util';
import { ChangeEmailModalComponent } from './change-email-modal/change-email-modal.component';
import * as studentAnalyticsActions from './redux/student-analytics.actions';
import { getSelectedStudent } from './redux/student-analytics.actions';
import {
	currentStudentSelector,
	selectCohortEvents,
	selectCohortsStudentOtherData,
} from './redux/student-analytics.selectors';

@Component({
	selector: 'app-student-analytics',
	templateUrl: './student-analytics.component.html',
	styleUrls: ['./student-analytics.component.scss'],
})
export class StudentAnalyticsComponent implements OnInit {
	activeCohort$ = this.store.select(selectActiveCohort);
	students$: Observable<any> = this.store.select(selectStudentsState);
	selectStudentsProgress$: Observable<any> = this.store.select(
		selectStudentsProgress,
	);

	selectedSegment: any;
	classViewed: number = 0;
	classCompleted: number = 0;
	assignmentNo: number = 0;
	branAssignmentNo: number = 0;
	daysInactive: number = 0;
	branDaysInactive: number = 0;
	reviewPending: number = 0;
	branReviewPending: number = 0;
	eventAttends: number = 0;
	branEventAttends: number = 0;
	eventlastDate: string = '--';
	branEventlastDate: string = '--';
	classLastDate: string = '--';

	currentStudentSelector$: Observable<any> = this.store.select(
		currentStudentSelector,
	);
	selectClassesForCohort$: Observable<any> = this.store.select(
		selectClassesForCohort,
	);

	selectCohortsStudentOtherData$: Observable<any> = this.store
		.select(selectCohortsStudentOtherData)
		.pipe(
			map(d => {
				if (d.lastSlackActive === '--') {
					return {
						...d,
						lastSlackActive: '--',
					};
				} else if (!!d.lastSlackActive) {
					return {
						...d,
						lastSlackActive: format(d.lastSlackActive, 'dd-MMM'),
					};
				} else {
					return d;
				}
			}),
		);

	studentName: string;
	studentEmail: string;
	studentCountry: string;
	studentGroup: string;
	studentGroupId: number;
	studentImage: string;
	classesCompleted: number;
	classesViewed: number;
	eventsAttented: number;
	assignmentsSubmitted: number;
	selectedCohort: any;
	// lastActivity:
	studentPresent = false;
	studentOnCohort = true;
	studentProfileIdPresent = false;
	paramsId: any;
	studentdeal: any;
	studentProfileId: number;
	currentCohort: any;

	dataLoader = true;
	constructor(
		private router: Router,
		private store: Store<AppState>,
		private route: ActivatedRoute,
		private messageService: MessagesService,
		public modalController: ModalController,
	) {}

	ngOnInit() {
		this.studentName = null;
		this.studentOnCohort = true;
		this.studentdeal = null;

		this.studentCountry = null;
		this.studentGroup = null;
		this.studentImage = null;
		this.assignmentsSubmitted = 0;
		this.eventsAttented = 0;
		this.selectedSegment = 'class';
		this.route.queryParams.pipe().subscribe(params => {
			this.selectedSegment = 'class';

			if (params) {
				this.selectedCohort = params.cohortId;
				this.store.pipe(select(selectCohortsList)).subscribe(value => {
					if (value) {
						const currentCohort = value.find(item => {
							if (parseInt(item.id, 10) === parseInt(params.cohortId, 10)) {
								return item;
							}
							// this.selectedCohort = currentCohort;
						});
						this.currentCohort = currentCohort;
						this.store.dispatch(setActiveCohort({ cohort: currentCohort }));
					}
				});
			}
			if (params?.id) {
				this.paramsId = params.id;
				this.store.dispatch(getSelectedStudent({ id: params.id }));
				this.studentPresent = true;
				// this.studentEmail = params.email;
				this.studentProfileId = params.id;
				this.studentOnCohort = false;
				this.studentProfileIdPresent = true;
			} else {
				this.studentPresent = false;
				this.studentOnCohort = true;
			}

			if (params?.cohortId) {
				this.store.dispatch(
					studentAnalyticsActions.getStudentData({
						cohortId: params?.cohortId,
						studentId: params.id,
					}),
				);

				this.students$.subscribe((student: any) => {
					if (student?.students?.length > 0) {
						this.store.dispatch(
							CohortAnalyticsActions.getCohortMixpanelList({
								cohortId: params?.cohortId,
							}),
						);
					}
				});
			}
		});

		this.currentStudentSelector$.subscribe(data => {
			if (data) {
				this.studentName = data.firstName + ' ' + data.lastName;
				this.studentCountry = data.country;
				this.studentEmail = data.email;
				this.studentImage = data.multimedia;
				if (!data?.group) {
					this.studentGroup = '--';
				}
				if (data.country?.length === 0) {
					this.studentCountry = '--';
				}
				this.studentGroupId = null;

				const courses = data.courses;
				courses &&
					courses.forEach(element => {
						if (element.cohortId === Number(this.selectedCohort)) {
							this.studentGroup = element?.groupName;
							this.studentGroupId = element?.groupId;
						}
					});
			}
		});

		this.activeCohort$.subscribe(cohort => {
			if (cohort) {
				this.studentdeal = null;
				this.studentPresent = false;
				this.store.dispatch(loadStudentsList());
			}
			this.students$.subscribe(obj => {
				if (obj?.students?.length && cohort) {
					const arr: any = obj.students;
					this.studentdeal = arr.find(item => {
						if (item.profileId === Number(this.studentProfileId)) {
							return item;
						}
					});

					this.initClassData(cohort);
					this.initAssignmentData(cohort);
					this.initEvents(cohort);
					this.getInactiveDate(cohort);
					if (!this.studentdeal) {
						if (this.studentProfileIdPresent) {
							this.studentOnCohort = false;
							this.studentPresent = false;
						}
					} else {
						this.studentPresent = true;
					}
				}
			});
		});
	}
	copyProfileUrl() {
		navigator.clipboard.writeText(window.location.href.toString());
		this.showSuccessToast('Link Copied Successfully');
	}
	onSegmentChange(segment: string) {
		this.selectedSegment = segment;
	}

	segmentChanged(segment: any) {
		this.selectedSegment = segment.detail.value;
	}
	showSuccessToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			title1: 'Success',
			title2: message,
		});
	}

	classData: any;
	classModuleData: any;
	initClassData(cohort) {
		combineLatest([
			this.store.select(studentCohortMixpanelData),
			this.currentStudentSelector$,
		])
			.pipe(
				filter(([classesFromStudent, studentName]) => {
					try {
						if (
							studentName &&
							studentName.email &&
							classesFromStudent.studentClass &&
							classesFromStudent.studentClass[cohort.id] &&
							classesFromStudent.studentClass[cohort.id].length > 0
						) {
							let classData = classesFromStudent.studentClass[cohort.id].filter(
								({ email }) => email === studentName.email,
							);

							return classData.length > 0;
						} else {
							return false;
						}
					} catch (e) {
						return false;
					}
				}),
			)
			.subscribe(([classesFromStudent, studentName]) => {
				let classData: any = [];
				let classModuleData: any = [];
				try {
					if (
						studentName &&
						studentName.email &&
						classesFromStudent.studentClass &&
						classesFromStudent.studentClass[cohort.id] &&
						classesFromStudent.studentClass[cohort.id].length > 0
					) {
						const classDataSelect = classesFromStudent.studentClass[
							cohort.id
						].filter(({ email }) => email === studentName.email);

						if (classDataSelect.length > 0) {
							classData = classDataSelect[0].events.map(ev => {
								let allModules = ev.modules
									.filter(({ mandatory }) => mandatory)
									.map(({ completionRate }) => completionRate || 0);

								if (allModules.length > 0) {
									return {
										...ev,
										isActiveClass: true,
										completionRate:
											allModules.reduce((a, b) => a + b, 0) / allModules.length,
									};
								} else {
									return {
										...ev,
										isActiveClass: true,
									};
								}
							});

							classModuleData = classData
								.map(({ modules }) => modules)
								.flat(1)
								.map((record: any) => {
									return {
										...record,
										name: record.name,
										isActiveClass: true,
										completionRate: record.completionRate || 0,
									};
								});
						}
					}
				} catch (e) {
					console.log(e);
				}

				this.classData = classData;
				this.classModuleData = classModuleData;
				this.classViewed = this.classData.filter(
					({ completionRate }) => completionRate && completionRate > 0,
				).length;

				this.classCompleted = this.classData.filter(
					({ completionRate }) => completionRate && completionRate === 100,
				).length;

				const classLastDate = this.classData
					.filter(({ lastActive }) => lastActive)
					.map(({ lastActive }) => lastActive)
					.sort((a, b) => {
						let AStartDate: Date = new Date(a);
						let BStartDate: Date = new Date(b);
						return BStartDate.valueOf() - AStartDate.valueOf();
					});

				if (classLastDate.length > 0) {
					this.classLastDate = classLastDate[0];
				} else {
					this.classLastDate = '--';
				}
			});
	}

	selectAssignmentsForCohort$: Observable<any> = this.store.select(
		selectAssignmentsForCohort,
	);

	getTagColor = (tag: string) => {
		return Utils.stageTagColor(tag, 'studentAnalytics');
	};

	assignmentData: any;

	initAssignmentData(cohort) {
		combineLatest([
			this.selectAssignmentsForCohort$,
			this.store.select(studentCohortMixpanelData),
			this.currentStudentSelector$,
		])
			.pipe(
				filter(
					([classesFromCohrot, classesFromStudent, studentName]) =>
						!!classesFromCohrot && classesFromCohrot.length > 0,
				),
			)
			.subscribe(([classesFromCohrot, classesFromStudent, studentName]) => {
				let assignmentList = [...classesFromCohrot];
				assignmentList = assignmentList.sort((a, b) => {
					let AStartDate: Date = new Date(a.startDateTime);
					let BStartDate: Date = new Date(b.startDateTime);
					return AStartDate.valueOf() - BStartDate.valueOf();
				});

				assignmentList = assignmentList.map(assignemnt => {
					let isActiveClass = false;
					if (assignemnt.startDateTime <= Date.now()) {
						isActiveClass = true;
					}

					return {
						name: assignemnt.title.trim(),
						status: 'Not Started',
						lastDate: '--',
						submissionDate: '--',
						color: this.getTagColor('Not Started'),
						assignmentId: assignemnt.id,
						branSubmissionDate: '--',
						branLastDate: '--',
						branStatus: 'Not Started',
						isActiveClass,
					};
				});

				try {
					if (
						studentName &&
						studentName.email &&
						classesFromStudent.allData &&
						classesFromStudent.allData[cohort.id] &&
						classesFromStudent.allData[cohort.id][studentName.email] &&
						classesFromStudent.allData[cohort.id][studentName.email].length > 0
					) {
						let extenData: any =
							classesFromStudent.allData[cohort.id][studentName.email];
						let matchCurrentStudentData = [...extenData].sort((a, b) => {
							let AStartDate: Date = new Date(a.time);
							let BStartDate: Date = new Date(b.time);
							return BStartDate.valueOf() - AStartDate.valueOf();
						});

						let emailData = matchCurrentStudentData.filter(
							c =>
								[
									'assignment_create_click',
									'assignment_submitted',
									'assignment_reviewed',
									'bran_assignments',
								].indexOf(c.name) > -1,
						);

						assignmentList = assignmentList.map(fc => {
							let submissionURL = '';
							let matchObj = emailData
								.filter(c => c.assignmentId == fc.assignmentId)
								.sort((a, b) => {
									let AStartDate: Date = new Date(a.time);
									let BStartDate: Date = new Date(b.time);
									return BStartDate.valueOf() - AStartDate.valueOf();
								});

							if (matchObj.length > 0) {
								let matchBranObj = matchObj
									.filter(c => c.name === 'bran_assignments')
									.sort((a, b) => {
										let AStartDate: Date = new Date(a.time);
										let BStartDate: Date = new Date(b.time);
										return BStartDate.valueOf() - AStartDate.valueOf();
									});

								let matchMixPanelObj = matchObj
									.filter(c => c.name !== 'bran_assignments')
									.sort((a, b) => {
										let AStartDate: Date = new Date(a.time);
										let BStartDate: Date = new Date(b.time);
										return BStartDate.valueOf() - AStartDate.valueOf();
									});

								let branStatus = 'Not Started';
								let branSubmissionDate = '--';
								let branLastDate = '--';

								if (matchBranObj.length > 0) {
									branLastDate = format(matchBranObj[0].time, 'dd-MMM');

									if (matchBranObj[0].reviewedOn) {
										branStatus = 'Reviewed';
									} else if (matchBranObj[0].submittedOn) {
										branStatus = 'Submitted';
									} else if (matchBranObj[0].startedOn) {
										branStatus = 'Started';
									}

									if (matchBranObj[0].submittedOn) {
										branSubmissionDate = format(
											matchBranObj[0].submittedOn,
											'dd-MMM',
										);
									}
								}

								let newStatus = 'Not Started';
								let submissionDate: any = '--';
								let lastDate = '--';
								if (matchMixPanelObj.length > 0) {
									lastDate = format(matchMixPanelObj[0].time, 'dd-MMM');

									if (
										matchMixPanelObj.filter(
											m => m.name === 'assignment_reviewed',
										).length > 0
									) {
										newStatus = 'Reviewed';
									} else if (
										matchMixPanelObj.filter(
											m => m.name === 'assignment_submitted',
										).length > 0
									) {
										submissionDate = format(
											matchMixPanelObj.filter(
												m => m.name === 'assignment_submitted',
											)[0].time,
											'dd-MMM',
										);
										newStatus = 'Submitted';
									} else if (
										matchMixPanelObj.filter(
											m => m.name === 'assignment_create_click',
										).length > 0
									) {
										newStatus = 'Started';
									}
								}
								let submissionURLObj = extenData
									.filter(c => ['bran_assignments'].indexOf(c.name) > -1)
									.filter(c => c.assignmentId == fc.assignmentId);

								if (submissionURLObj.length > 0) {
									submissionURL = submissionURLObj[0].url;
								}
								return {
									name: fc.name,
									status: newStatus,
									lastDate,
									submissionDate,
									color: this.getTagColor(branStatus),
									branSubmissionDate,
									branLastDate,
									branStatus,
									isActiveClass: fc.isActiveClass,
									submissionURL: submissionURL,
								};
							} else {
								return fc;
							}
						});

						this.assignmentNo = assignmentList.filter(
							c => c.status == 'Submitted' || c.status == 'Reviewed',
						).length;

						this.branAssignmentNo = assignmentList.filter(
							c => c.branStatus == 'Submitted' || c.branStatus == 'Reviewed',
						).length;

						this.reviewPending = assignmentList.filter(
							c => c.status == 'Submitted',
						).length;

						this.branReviewPending = assignmentList.filter(
							c => c.branStatus == 'Submitted',
						).length;
					}
				} catch (e) {
					console.log(e);
				}

				this.assignmentData = assignmentList;
			});
	}

	selectEventsForCohort$: Observable<any> = this.store.select(
		selectCohortEvents,
	);

	eventsData: any;

	initEvents(cohort) {
		this.store.dispatch(studentAnalyticsActions.getCohortEventData());

		combineLatest([
			this.selectEventsForCohort$,
			this.store.select(studentCohortMixpanelData),
			this.currentStudentSelector$,
		])
			.pipe(
				filter(
					([classesFromCohrot, classesFromStudent, studentName]) =>
						!!classesFromCohrot && classesFromCohrot.length > 0,
				),
			)
			.subscribe(([classesFromCohrot, classesFromStudent, studentName]) => {
				let eventsList = [...classesFromCohrot];
				eventsList = eventsList.sort((a, b) => {
					let AStartDate: Date = new Date(a.startTime);
					let BStartDate: Date = new Date(b.startTime);
					return AStartDate.valueOf() - BStartDate.valueOf();
				});
				eventsList = eventsList.map(assignemnt => {
					let isActiveClass = false;
					if (assignemnt.startTime <= Date.now()) {
						isActiveClass = true;
					}

					return {
						name: assignemnt.title.trim(),
						groupId: assignemnt.groupId,
						attendance: false,
						live: false,
						viewRecording: false,
						eventId: assignemnt.eventId,
						branAttendance: false,
						branLive: false,
						branViewRecording: false,
						isActiveClass,
					};
				});
				if (this.studentGroupId) {
					eventsList = eventsList.filter(
						e => e.groupId === this.studentGroupId || e.groupId == null,
					);
				}

				try {
					if (
						studentName &&
						studentName.email &&
						classesFromStudent.allData &&
						classesFromStudent.allData[cohort.id] &&
						classesFromStudent.allData[cohort.id][studentName.email] &&
						classesFromStudent.allData[cohort.id][studentName.email].length > 0
					) {
						let extenData: any =
							classesFromStudent.allData[cohort.id][studentName.email];
						let matchCurrentStudentData = [...extenData].sort((a, b) => {
							let AStartDate: Date = new Date(a.time);
							let BStartDate: Date = new Date(b.time);
							return BStartDate.valueOf() - AStartDate.valueOf();
						});

						let emailData = matchCurrentStudentData.filter(
							c =>
								[
									'calendar_event_recording_click',
									'calendar_event_zoom_click',
									'calendar_event_click',
									'bran_events',
								].indexOf(c.name) > -1,
						);

						eventsList = eventsList.map(fc => {
							let matchObj = emailData
								.filter(c => c.eventId == fc.eventId)
								.sort((a, b) => {
									let AStartDate: Date = new Date(a.time);
									let BStartDate: Date = new Date(b.time);
									return BStartDate.valueOf() - AStartDate.valueOf();
								});

							if (matchObj.length > 0) {
								let matchMixPanelObj = matchObj
									.filter(c => c.name !== 'bran_events')
									.sort((a, b) => {
										let AStartDate: Date = new Date(a.time);
										let BStartDate: Date = new Date(b.time);
										return BStartDate.valueOf() - AStartDate.valueOf();
									});

								let live = false;
								let viewRecording = false;

								if (
									matchMixPanelObj.filter(
										c => c.name == 'calendar_event_recording_click',
									).length > 0
								) {
									viewRecording = true;
								}
								if (
									matchMixPanelObj.filter(
										c => c.name == 'calendar_event_zoom_click',
									).length > 0
								) {
									live = true;
								}
								if (
									matchMixPanelObj.filter(
										c => c.name === 'calendar_eveventsListent_click',
									).length > 0
								) {
									live = true;
								}

								let matchBranObj = matchObj
									.filter(c => c.name === 'bran_events')
									.sort((a, b) => {
										let AStartDate: Date = new Date(a.time);
										let BStartDate: Date = new Date(b.time);
										return BStartDate.valueOf() - AStartDate.valueOf();
									});

								let branLive = false;
								let branViewRecording = false;

								if (matchBranObj.length > 0) {
									if (matchBranObj[0].liveAttended) {
										branLive = true;
									}

									if (matchBranObj[0].recordingViewed) {
										branViewRecording = true;
									}
								}
								return {
									name: fc.name,
									attendance: live || viewRecording,
									live,
									viewRecording,
									branAttendance: branLive || branViewRecording,
									branLive,
									branViewRecording,
									isActiveClass: fc.isActiveClass,
								};
							} else {
								return fc;
							}
						});

						let branAssignmentData = emailData
							.filter(c => c.name === 'bran_events')
							.sort((a, b) => {
								let AStartDate: Date = new Date(a.time);
								let BStartDate: Date = new Date(b.time);
								return BStartDate.valueOf() - AStartDate.valueOf();
							});

						let mixPanelAssignmentData = emailData
							.filter(c => c.name !== 'bran_events')
							.sort((a, b) => {
								let AStartDate: Date = new Date(a.time);
								let BStartDate: Date = new Date(b.time);
								return BStartDate.valueOf() - AStartDate.valueOf();
							});

						if (branAssignmentData.length > 0) {
							this.branEventAttends = eventsList.filter(
								e => e.branAttendance,
							).length;

							this.branEventlastDate = format(
								branAssignmentData[0].time,
								'dd-MMM',
							);
						}

						if (mixPanelAssignmentData.length > 0) {
							this.eventAttends = eventsList.filter(e => e.attendance).length;

							this.eventlastDate = format(
								mixPanelAssignmentData[0].time,
								'dd-MMM',
							);
						}
					}
				} catch (e) {
					console.log(e);
				}

				this.eventsData = eventsList;
			});
	}

	getInactiveDate(cohort) {
		combineLatest([
			this.store.select(studentCohortMixpanelData),
			this.currentStudentSelector$,
			this.store.select(CohortMixPanelAPI),
		]).subscribe(([classesFromStudent, studentName, loader]) => {
			this.dataLoader = !loader;

			try {
				if (
					studentName &&
					studentName.email &&
					classesFromStudent.allData &&
					classesFromStudent.allData[cohort.id] &&
					classesFromStudent.allData[cohort.id][studentName.email] &&
					classesFromStudent.allData[cohort.id][studentName.email].length > 0
				) {
					let extenData: any = classesFromStudent.allData[cohort.id][
						studentName.email
					].filter(
						c =>
							[
								'calendar_event_recording_click',
								'calendar_event_zoom_click',
								'calendar_event_click',
								'slack_last_active',
								'class_view_click',
								'class-p25-done',
								'class-p50-done',
								'class-p75-done',
								'class-p100-done',
								'class_complete_click',
							].indexOf(c.name) > -1,
					);

					let matchCurrentStudentData = [...extenData].sort((a, b) => {
						let AStartDate: Date = new Date(a.time);
						let BStartDate: Date = new Date(b.time);
						return BStartDate.valueOf() - AStartDate.valueOf();
					});

					if (matchCurrentStudentData.length > 0) {
						this.daysInactive = differenceInDays(
							new Date(),
							matchCurrentStudentData[0].time,
						);
					} else {
						this.daysInactive = 0;
					}

					let branExtenData: any = classesFromStudent.allData[cohort.id][
						studentName.email
					].filter(
						c =>
							['bran_class', 'bran_events', 'slack_last_active'].indexOf(
								c.name,
							) > -1,
					);

					let branMatchCurrentStudentData = [...branExtenData].sort((a, b) => {
						let AStartDate: Date = new Date(a.time);
						let BStartDate: Date = new Date(b.time);
						return BStartDate.valueOf() - AStartDate.valueOf();
					});

					if (branMatchCurrentStudentData.length > 0) {
						this.branDaysInactive = differenceInDays(
							new Date(),
							branMatchCurrentStudentData[0].time,
						);
					} else {
						this.branDaysInactive = 0;
					}
				}
			} catch (e) {
				console.log(e);
			}
		});
	}

	async opEmailChangeModal() {
		const modal = await this.modalController.create({
			component: ChangeEmailModalComponent,
			cssClass: 'students-email-change-modal',
			backdropDismiss: false,
			componentProps: {
				personName: this.studentName,
				previousEmail: this.studentEmail,
				cohort: this.currentCohort,
			},
		});

		modal.onDidDismiss().then((settings: any) => {
			console.log(settings);
			const { data } = settings;
			if (data?.success === true) {
				this.store.dispatch(getSelectedStudent({ id: this.paramsId }));
				this.store.dispatch(loadStudentsList());
				// this.studentEmail = data.email;
			}
		});

		return await modal.present();
	}
}
