import { createReducer, on } from '@ngrx/store';
import { Cohort } from '../../models/dashboard-models';
import * as CohortsActions from './cohorts-actions';

export const cohortsKey = 'cohorts';

export interface CohortsState {
	cohortsLoaded: boolean;
	cohortsList: any;
	activeCohort: Cohort;
}

export const initialCohortsState: CohortsState = {
	cohortsLoaded: false,
	cohortsList: [],
	activeCohort: null,
};

export const cohortsReducer = createReducer(
	initialCohortsState,
	on(CohortsActions.setActiveCohort, (state, action) => ({
		...state,
		activeCohort: action.cohort,
	})),
	on(CohortsActions.cohortsLoaded, (state, action) => {
		return {
			...state,
			cohortsList: action.cohorts.map(item => {
				const cohortDate = new Date(item.cohortDate);
				return {
					...item,
					title: `[${item.id}-${item.alias}]  ${cohortDate.toLocaleDateString(
						'default',
						{
							month: 'short',
						},
					)} ${cohortDate.getFullYear()} ${item.courseName}`,
				};
			}),
			cohortsLoaded: true,
		};
	}),
);
