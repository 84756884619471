import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MentorRequestModel } from '../../models/mentor-request-models';
import { mentorRequestKey } from './mentor-requests-reducer';

export const selectMentorRequestState = createFeatureSelector<
	MentorRequestModel
>(mentorRequestKey);

export const selectFilters = createSelector(
	selectMentorRequestState,
	state => state?.filters,
);

export const selectRequests = createSelector(
	selectMentorRequestState,
	state => state.requests,
);

export const selectRequestsLoaded = createSelector(
	selectMentorRequestState,
	state => state.requestsLoaded,
);

export const selectRequestSummary = createSelector(
	selectMentorRequestState,
	state => state?.summary,
);

export const selectPaginationProgress = createSelector(
	selectMentorRequestState,
	state => state.paginationProgress,
);
