import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../Util';

@Component({
	selector: 'app-assignments',
	templateUrl: './assignments.component.html',
	styleUrls: ['./assignments.component.scss'],
})
export class AssignmentsComponent implements OnInit {
	getTagColor = (tag: string) => {
		return Utils.stageTagColor(tag, 'studentAnalytics');
	};

	constructor() {}

	@Input() assignmentData: any;

	ngOnInit() {}
	assignmentClick(url) {
		let newUrl ="https://my.terra.do/login?redirectUrl=";
		if(url.includes("assignments.terra.do")){
			newUrl = newUrl+url;
			console.log(newUrl);
			window.open(newUrl, '_blank');
			return;
		}
		window.open(url, '_blank');
	}
}
