import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-class',
	templateUrl: './class.component.html',
	styleUrls: ['./class.component.scss'],
})
export class ClassComponent implements OnInit {
	constructor() {}

	isModuleView: boolean = false;

	@Input() classData: any = [];
	@Input() classModuleData: any = [];

	ngOnInit() {}
}
