import { createReducer, on } from '@ngrx/store';
import { Cohort } from '../../models/dashboard-models';
import Utils from '../../Util';
import * as DashboardActions from './dashboard-actions';

export const dashboardKey = 'dashboard';

export interface ApplicationFilters {
	cohortId: number;
	productId: number;
	sort: string;
	stageIds: number[];
	searchValue: string;
	page: number;
	courseQuestions: any[];
	courseId: number;
	tags: number[];
	filterDates: string[];
	fromExteriorRoute: boolean;
}

export interface Summary {
	total_applications: number;
	stages: [];
}

export interface ApplicationsSummary {
	summary: Summary;
	error: any;
}

export interface ApplicationsState {
	applications: [];
	error: any;
}

export interface DashboardState {
	applicationsLoaded: boolean;
	applicationsSummaryLoaded: boolean;
	cohortsLoaded: boolean;
	paginationInProgress: boolean;
	cohortsList: any;
	applicationFilters: ApplicationFilters;
	applicationsSummary: ApplicationsSummary;
	applications: ApplicationsState;
	pipelineStages: any;
	activeCohort: Cohort;
	allCohortUrl: string;
}

export const initialDashboardState: DashboardState = {
	applicationsLoaded: false,
	applicationsSummaryLoaded: false,
	cohortsLoaded: false,
	paginationInProgress: false,
	cohortsList: [],
	applicationFilters: null,
	applicationsSummary: {
		summary: {
			stages: [],
			total_applications: 0,
		},
		error: null,
	},
	applications: {
		applications: [],
		error: null,
	},
	pipelineStages: [],
	activeCohort: null,
	allCohortUrl: null
};
export const defaultFilterState = {
	pipelineId: 15000026345,
	cohortId: null,
	// filterDates: ["25/12/2020", "12/10/2021"],
	page: 1,
	sort: Utils.CREATED_AT,
	stageIds: [],
	searchValue: '',
};
export const dashboardReducer = createReducer(
	initialDashboardState,
	on(DashboardActions.resetDashboard, state => initialDashboardState),
	on(DashboardActions.setActiveCohort, (state, action) => ({
		...state,
		activeCohort: action.cohort,
	})),
	on(DashboardActions.setApplicationFilters, (state, action) => {
		// localStorage.setItem(Utils.COHORT_CONTEXT, JSON.stringify(action.filters));
		return {
			...state,
			applicationFilters: action.filters,
		};
	}),
	on(DashboardActions.cohortsLoaded, (state, action) => {
		return {
			...state,
			cohortsList: action.cohorts.map(item => {
				const cohortDate = new Date(item.cohortDate);
				return {
					...item,
					title: `[${item.id}] ${cohortDate.toLocaleDateString('default', {
						month: 'short',
					})} ${cohortDate.getFullYear()} ${item.courseName}`,
				};
			}),
			cohortsLoaded: true,
		};
	}),
	on(DashboardActions.applicationsSummaryLoaded, (state, action) => ({
		...state,
		applicationsSummary: {
			error: null,
			summary: action.summary,
		},
		applicationsSummaryLoaded: true,
	})),
	on(DashboardActions.applicationsSummaryError, (state, action) => ({
		...state,
		applicationsSummary: {
			...state.applicationsSummary,
			error: action.error,
		},
		applicationsSummaryLoaded: true,
	})),
	on(DashboardActions.loadApplications, state => ({
		...state,
		applicationsLoaded: false,
	})),
	on(DashboardActions.paginateApplications, state => ({
		...state,
		paginationInProgress: true,
	})),
	on(DashboardActions.applicationsLoaded, (state, action) => {
		return {
			...state,
			applications: {
				error: null,
				applications: action.applications,
			},
			applicationsLoaded: true,
		};
	}),
	on(DashboardActions.applicationsPaginated, (state, action) => {
		return {
			...state,
			applications: {
				error: null,
				applications: [
					...state.applications.applications,
					...action.applications,
				],
			},
			paginationInProgress: false,
		};
	}),
	on(DashboardActions.applicationsError, (state, action) => ({
		...state,
		applications: {
			...state.applications,
			error: action.error,
		},
		applicationsLoaded: true,
		paginationInProgress: false,
	})),
	on(DashboardActions.refetchApplications, state => ({
		...state,
		applicationsSummaryLoaded: false,
		applicationsLoaded: false,
	})),
	on(DashboardActions.setDefaultPipelineStages, (state, action) => ({
		...state,
		pipelineStages: action.stages,
	})),
	on(DashboardActions.removeApplication, (state, action) => {
		const applicationsList = state.applications.applications;
		const application = applicationsList.find(item => item.id === action.id);
		const summary = state.applicationsSummary.summary;
		const stagesList = summary.stages;
		const stage = stagesList.find(
			item => item.id === application.deal_stage_id,
		);

		let totalApplications = 0;
		if (summary.total_applications) {
			totalApplications = summary.total_applications;
			--totalApplications;
		}
		let newStageList;
		if (stage?.applications === 1) {
			newStageList = stagesList.filter(item => item.id !== stage.id);
		} else {
			const stageIndex = stagesList.findIndex(item => item.id === stage.id);
			let appCount = stage.applications;
			newStageList = [...stagesList];
			newStageList[stageIndex] = { ...stage, applications: --appCount };
		}

		return {
			...state,
			applications: {
				...state.applications,
				applications: applicationsList.filter(
					item => item.id !== application.id,
				),
			},
			applicationsSummary: {
				...state.applicationsSummary,
				summary: {
					stages: newStageList,
					total_applications: totalApplications,
				},
			},
		};
	}),
	on(DashboardActions.reviseApplicationStages, (state, action) => {
		const summary = state.applicationsSummary.summary;
		const stagesList = summary.stages;
		const stage = stagesList.find(
			item => item.id === action.deal.deal_stage_id,
		);
		let newStageList;
		const getNameById = (id: number) =>
			state.pipelineStages.find(item => item.id === id).name;

		// Update the new deal stage data
		if (stage) {
			const index = stagesList.findIndex(item => item.id === stage.id);
			newStageList = [...stagesList];
			let newCount = stage?.applications;
			newStageList[index] = { ...stage, applications: ++newCount };
		} else {
			newStageList = [...stagesList];
			newStageList.push({
				id: action.deal.deal_stage_id,
				title: getNameById(action.deal.deal_stage_id),
				applications: 1,
			});
		}
		const applicationsList = state.applications.applications;
		const appDetails = applicationsList.find(
			item => item.id === action.deal.id,
		);
		if (!appDetails) {
			return { ...state };
		}
		// Update the old deal stage data from the application
		const removeStage = newStageList.find(
			item => item.id === appDetails?.deal_stage_id,
		);
		if (removeStage?.applications === 1) {
			newStageList = newStageList.filter(item => item.id !== removeStage.id);
		} else {
			const stageIdx = newStageList.findIndex(
				item => item?.id === removeStage?.id,
			);
			let stageAppCount = removeStage?.applications;
			newStageList[stageIdx] = {
				...removeStage,
				applications: --stageAppCount,
			};
		}

		// Update the application details
		const appIdx = applicationsList.findIndex(
			item => item.id === appDetails.id,
		);
		const newApplicationsList = [...applicationsList];
		newApplicationsList[appIdx] = {
			...newApplicationsList[appIdx],
			deal_stage_id: action.deal.deal_stage_id,
			deal_stage_name: getNameById(action.deal.deal_stage_id),
			...(getNameById(action.deal.deal_stage_id) ===
				Utils.PAYMENT_LINK_SENT && {
				payment_value: action.deal.custom_field.cf_payment_value,
			}),
		};
		return {
			...state,
			applications: {
				...state.applications,
				applications: newApplicationsList,
			},
			applicationsSummary: {
				...state.applicationsSummary,
				summary: {
					...summary,
					stages: newStageList,
				},
			},
		};
	}),
	on(DashboardActions.courseQuestionsLoaded, (state, action) => {
		return {
			...state,
			applicationFilters: {
				...state.applicationFilters,
				courseQuestions: action.questions,
			},
		};
	}),
	on(DashboardActions.setAllCohortUrl, (state, action) => {
		return {
			...state,
			allCohortUrl: action.currentUrl
		};
	}),
	
);
