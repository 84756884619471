import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
	CloudinaryConfiguration,
	CloudinaryModule,
} from '@cloudinary/angular-5.x';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthLibModule } from 'auth-lib';
import { CKEditorModule } from 'ckeditor4-angular';
import { Cloudinary } from 'cloudinary-core';
import { FeatureToggleLibModule } from 'feature-toggle-lib';
import { NgxPaginationModule } from 'ngx-pagination';
import { TerraUIModule } from 'terra-ui';
import { UtilLibModule } from 'util-lib';
import { CohortSelectorModule } from '../cohort-selector/cohort-selector.module';
import { CohortSettingsEffects } from '../cohort-settings/redux/cohort-settings-effects';
import {
	cohortSettingsKey,
	cohortSettingsReducer,
} from '../cohort-settings/redux/cohort-settings-reducer';
import { SideMenuComponentModule } from '../side-menu/side-menu.component.module';
import { StudentAnalyticsModule } from '../student-analytics/student-analytics.module';
import { StudentsEmailModalComponent } from '../students/students-email-modal/students-email-modal.component';
import { StudentsModalComponent } from '../students/students-modal/students-modal.component';
import { StudentsEffects } from './redux/students.effects';
import * as fromStudents from './redux/students.reducer';
import { StudentsFilterComponent } from './students-filter/students-filter.component';
import { StudentsResolver } from './students-resolver';
import { StudentsRoutingModule } from './students-routing.module';
import { StudentsComponent } from './students.component';
import { UploadStatusComponent } from './upload-status/upload-status.component';
//import { RoundprogressModule } from 'angular-svg-round-progressbar';
// import { NgCircleProgressModule } from 'ng-circle-progress';
import { MiniNavbarModule } from '../mini-navbar/mini-navbar.module';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TerraUIModule,

		FeatureToggleLibModule,
		// NgCircleProgressModule.forRoot({
		// 	outerStrokeGradient: true,
		// 	outerStrokeWidth: 10,
		// 	innerStrokeWidth: 10,
		// 	animateTitle: false,
		// 	animationDuration: 1000,
		// 	showUnits: false,
		// 	showBackground: true,
		// 	startFromZero: false,
		// 	lazy: true,
		// 	showTitle: false,
		// 	showSubtitle: false,
		// 	showInnerStroke: true,
		// 	clockwise: true,
		// 	responsive: false,
		// 	showZeroOuterStroke: false,
		// 	space: -10,
		// 	backgroundStrokeWidth: 0,
		// }),
		CloudinaryModule.forRoot({ Cloudinary }, {
			cloud_name: 'terra-do',
		} as CloudinaryConfiguration),
		AuthLibModule,
		SideMenuComponentModule,
		EffectsModule.forFeature([StudentsEffects]),
		StoreModule.forFeature(cohortSettingsKey, cohortSettingsReducer),
		StoreModule.forFeature(
			fromStudents.studentsFeatureKey,
			fromStudents.studentsReducer,
		),
		EffectsModule.forFeature([CohortSettingsEffects]),
		NgSelectModule,
		CohortSelectorModule,
		CommonModule,
		StudentsRoutingModule,
		HttpClientModule,
		UtilLibModule,
		CKEditorModule,
		StudentAnalyticsModule,
		NgxPaginationModule,
		MiniNavbarModule,
	],
	declarations: [
		StudentsComponent,
		StudentsModalComponent,
		StudentsFilterComponent,
		StudentsEmailModalComponent,
		UploadStatusComponent,
	],
	providers: [StudentsResolver],
})
export class StudentsModule {}
