import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCourseContentActiveCohort } from 'src/app/course-content/redux/course-content-selector';
import { cohortsKey, CohortsState } from './cohorts-reducer';

export const selectCohortsState = createFeatureSelector<CohortsState>(
	cohortsKey,
);

export const selectActiveCohort = createSelector(
	selectCohortsState,
	state => state.activeCohort,
);

export const selectCohortsLoaded = createSelector(
	selectCohortsState,
	state => state.cohortsLoaded,
);

export const selectCohortsList = createSelector(
	selectCohortsState,
	state => state.cohortsList,
);

export const selectActiveCohortState = createSelector(
    selectCohortsState,
	selectCourseContentActiveCohort, 
    (state, stateActiveCohortId) => {

		// console.log('what is happening: state.activeCohort.id ', state.activeCohort.id)
		// console.log('what is happening: state.activeCohortId ', props.activeCohortId)
		let stateActiveCohort : any = state.activeCohort?.id;
		let activeCohort : any = stateActiveCohortId;
		return stateActiveCohort === parseInt(activeCohort, 10);
	
    },
);
