import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureToggleLibModule } from 'feature-toggle-lib';
import { sideMenuReducer } from './reducers/side-menu-reducers';
import { SideMenuComponent } from './side-menu.component';
import { SideMenuEFfects } from './side-menu.effects';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ReactiveFormsModule,
		FeatureToggleLibModule,
		StoreModule.forFeature('sidemenu', sideMenuReducer),
		EffectsModule.forFeature([SideMenuEFfects]),
	],
	declarations: [SideMenuComponent],
	entryComponents: [SideMenuComponent],
	exports: [SideMenuComponent],
})
export class SideMenuComponentModule { }
