import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentAnalyticsComponent } from './student-analytics.component';
import { StudentAnalyticsResolver } from './student-analytics.resolver';

const routes: Routes = [
	{
		path: '',
		component: StudentAnalyticsComponent,
		resolve: {
			students: StudentAnalyticsResolver,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class StudentsAnalyticsRoutingModule {}
