import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { QuickActionsPage } from 'src/app/quick-actions/quick-actions.page';
import { AppState } from 'src/app/reducers';
import {
	changeMultipeGroups,
	changeOneGroup,
	clearOrderStatus,
	fetchEmailTemplate,
	getPaymentLink,
} from '../redux/students.actions';
import {
	groupChangeOrderStatus,
	groupChnageOrderError,
	selectPaymentLinks,
	selectUpdateStatus,
} from '../redux/students.selector';
import { LoadingService, MessagesService } from 'util-lib';
import { template } from 'handlebars';
import { StudentsEmailModalComponent } from '../students-email-modal/students-email-modal.component';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-students-modal',
	templateUrl: './students-modal.component.html',
	styleUrls: ['./students-modal.component.scss'],
})
export class StudentsModalComponent implements OnInit {
	@Input() modalType: any;
	@Input() groupList: any;
	@Input() checkedList: any;
	@Input() activeCohort: any;
	selectedGroup: number;
	selectedTemplate: string;
	selectedTemplateName: string;
	selectUpdateStatus$ = this.store.select(selectUpdateStatus);
	groupChangeOrderStatus$ = this.store.select(groupChangeOrderStatus);
	groupChnageOrderError$ = this.store.select(groupChnageOrderError);

	updateProgress: boolean;
	templateOptions: any;
	constructor(
		private store: Store<AppState>,
		public modalController: ModalController,
		private messageService: MessagesService,
	) {}

	ngOnInit() {
		console.log(this.checkedList);
		this.updateProgress = false;
		const obj = [
			{
				id: 'two_weeks_out_email',
				name: 'Two Weeks Out Email',
			},
			{
				id: 'Welcome_email_group',
				name: 'Welcome Email',
			},
			{
				id: '3-instructor_intro',
				name: 'Instructors Intro Email',
			},
			{
				id: 'payment_reminder_link_template',
				name: 'Payment Link Email',
			},
		];
		this.templateOptions = obj;
		// console.log(this.templateOptions);
		// console.log('this.checkedList', this.checkedList);
		// console.log("group Lists", this.groupList.data[0].name);
	}
	onGroupSelect(group: number) {
		this.selectedGroup = group;
	}
	onTemplateSelect(template: string) {
		console.log(template);
		// console.log('template selected', template);
		this.selectedTemplate = template;
		if (
			template === 'Welcome_email_group' ||
			template === 'two_weeks_out_email'
		) {
			this.selectedTemplate = this.activeCohort.courseId + '-' + template;
		}
		console.log(this.selectedTemplate);
		this.templateOptions.forEach(element => {
			if (element.id === template) {
				this.selectedTemplateName = element.name;
			}
		});
		// console.log("selected template name", this.selectedTemplateName);
	}
	async closeModal(clear: boolean) {
		await this.modalController.dismiss({ clearStudentDetails: clear });
	}

	updateGroup() {
		this.updateProgress = true;
		if (!this.selectedGroup) {
			this.showErrorToast('Please Choose a Group');
			return;
		}
		if (this.checkedList.length === 1) {
			this.store.dispatch(
				changeOneGroup({
					studentId: this.checkedList[0].id,
					groupId: this.selectedGroup,
				}),
			);
			this.groupChangeOrderStatus$.subscribe(status => {
				console.log(status, this.updateProgress);
				if (status && this.updateProgress) {
					console.log('close the modal');
					this.updateProgress = false;
					this.showSuccessToast('Group Changed Successfully');
					this.closeModal(true);
				}
			});
			this.groupChnageOrderError$
				.pipe(filter(error => !!error))
				.subscribe(error => {
					// console.log("inside error subscription",error);
					this.updateProgress = false;
					this.showErrorToast('Failed to change Group' + error);
					this.closeModal(false);
				});
		} else {
			console.log(this.checkedList);
			const ids = [];
			this.checkedList.forEach(element => {
				ids.push(element.id);
			});
			console.log(ids);
			this.store.dispatch(
				changeMultipeGroups({
					studentIds: ids,
					groupId: this.selectedGroup,
				}),
			);
			this.groupChangeOrderStatus$.subscribe(status => {
				console.log(status, this.updateProgress);
				if (status && this.updateProgress) {
					console.log('close the modal');
					this.updateProgress = false;
					this.showSuccessToast('Group Changed Successfully');
					this.closeModal(true);
				}
			});
			this.groupChnageOrderError$
				.pipe(filter(error => !!error))
				.subscribe(error => {
					this.updateProgress = false;
					this.showErrorToast('Failed to change Group' + error);
					this.closeModal(false);
				});
		}
		// this.closeModal();
	}
	async selectTemplate() {
		if (!this.selectedTemplate) {
			this.showErrorToast('Please Choose a Template');
			return;
		}
		if(this.selectedTemplate ===  'payment_reminder_link_template' ){
			console.log("payment reminder email template selected");
			if(this.checkedList.length > 1){
				this.showErrorToast('Select only one student at a time');
				return;
			}
			console.log(!this.checkedList[0].payLink);
			if(this.checkedList[0].status === "succeeded"){
				this.showErrorToast("User's last payment was successful payment");
				return;
			}
			if(!this.checkedList[0].payLink){
				this.showErrorToast("Installment URL not found for this amount");
				return;
			}
			if(this.checkedList[0].paymentMethod === 'Full Payment'){
				this.showErrorToast("User has payed the full amount");
				return;
			}
		}
		console.log(this.checkedList);
		const tempCheckedList = this.checkedList;
		const emails = [];
		const names = [];
		const groups = [];
		this.checkedList.forEach(element => {
			if (
				this.selectedTemplateName === 'Welcome Email' &&
				element.welcomeMail === false
			) {
				const tempObj = {
					email: element.email,
					studentId: element.id,
					fullName: element.firstName + ' ' + element.lastName,
					groupName: element.group,
				};
				emails.push(tempObj);
			} else if (
				this.selectedTemplateName === 'Two Weeks Out Email' &&
				element.onStartMail === false
			) {
				const tempObj = {
					email: element.email,
					studentId: element.id,
					fullName: element.firstName + ' ' + element.lastName,
					groupName: element.group,
				};
				emails.push(tempObj);
			} else if (this.selectedTemplateName === 'Instructors Intro Email') {
				const tempObj = {
					email: element.email,
					studentId: element.id,
					fullName: element.firstName + ' ' + element.lastName,
					groupName: element.group,
				};
				emails.push(tempObj);
			} else if (this.selectedTemplateName === 'Payment Link Email') {
				const tempObj = {
					email: element.email,
					studentId: element.id,
					firstName: element.firstName,
					groupName: element.group,
					linkDetails: element.payLink,
				};
				emails.push(tempObj);
			}
			console.log('Selected Emails', emails);
			names.push(element.name);
			groups.push(element.group);
		});
		if (emails.length === 0) {
			console.log('Already sent email to these students');
		}
		// console.log(this.selectedTemplate);
		this.store.dispatch(fetchEmailTemplate({ id: this.selectedTemplate }));

		const modal = await this.modalController.create({
			component: StudentsEmailModalComponent,
			cssClass: 'quick-actions-modal',
			backdropDismiss: true,
			componentProps: {
				emailList: emails,
				nameList: names,
				groupList: groups,
				selectedTemplateName: this.selectedTemplateName,
				activeCohort: this.activeCohort,
			},
		});
		modal.onDidDismiss().then((settings: any) => {
			const { data } = settings;
			if (data.clearStudentDetails) {
				console.log('onModal Close', data.clearStudentDetails);
				setTimeout(() => {
					this.closeModal(true);
				}, 500);
				this.showSuccessToast('Email Sent Successfully');
			}
			this.store.dispatch(clearOrderStatus());
		});
		return await modal.present();
		// this.closeModal(true);
	}
	showErrorToast(message) {
		console.log(message);
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			type: "error",
			title1: 'Error',
			title2: message,
		});
	}
	showSuccessToast(message) {
		this.messageService.showMsg({
			icon: 'alert-circle-outline',
			title1: 'Success',
			title2: message,
		});
	}

	trackByFn(index: number, item: any) {
		return item.id;
	}
}
