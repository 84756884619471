import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { of } from 'rxjs';
import {
	catchError, map, mergeMap, withLatestFrom
} from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import { AppState } from 'src/app/reducers';
import * as CohortSettingsActions from './cohort-settings-actions';


@Injectable()
export class CohortSettingsEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
	) { }

	loadRolesList$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.loadRolesList),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.getAdminRoles(token).pipe(
					map(response => {
						return CohortSettingsActions.rolesListLoaded({
							roles: response,
						});
					}),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CohortSettingsActions.rolesListError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	loadPeopleList$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.loadPeopleList),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.getPeopleList(token).pipe(
					map(response => {
						return CohortSettingsActions.peopleListLoaded({
							people: [...response],
						});
					}),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CohortSettingsActions.peopleListError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	loadPeopleWithRoles$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.loadPeopleWithRoles),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ cohortId }, token]) =>
				this.bobService.getPeopleWithRoles(token, cohortId).pipe(
					map(response =>
						CohortSettingsActions.peopleWithRolesLoaded({
							peopleWithRoles: response,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CohortSettingsActions.peopleWithRolesError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	assignRole$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.assignRole),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ peopleId, cohortId, order, roleId }, token]) =>
				this.bobService
					.assignRole(token, peopleId, cohortId, order, roleId)
					.pipe(
						map(response => CohortSettingsActions.roleAssigned({ response })),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CohortSettingsActions.roleAssignmentError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	getPriceListForCohort$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.loadPriceListForCohort),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ cohortId }, token]) =>
				this.bobService.getPriceListForCohort(token, cohortId).pipe(
					map(response =>
						CohortSettingsActions.priceListForCohortLoaded({
							priceList: response,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CohortSettingsActions.priceListForCohortError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);

	updateCohortPrice$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.updateCohortPrice),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ priceId: cohortId, price, timezone }, token]) =>
				this.bobService
					.updateCohortPrice(token, cohortId, price, timezone)
					.pipe(
						map(response =>
							CohortSettingsActions.cohortPriceUpdated({ respone: response }),
						),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CohortSettingsActions.cohortPriceUpdateError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	removeRoleAssignment$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.removeRoleAssignment),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([{ cohortId, roleId, peopleId }, token]) =>
				this.bobService
					.removeRoleAssignment(token, cohortId, roleId, peopleId)
					.pipe(
						map(response =>
							CohortSettingsActions.roleAssignmentRemoved({
								respone: response,
							}),
						),
						catchError(response => {
							if (response.status === 401) {
								this.authStore.logout();
							}
							return of(
								CohortSettingsActions.removeRoleAssignmentError({
									error: response.error,
								}),
							);
						}),
					),
			),
		),
	);

	addPeople$ = createEffect(() =>
		this.actions.pipe(
			ofType(CohortSettingsActions.addPeople),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.createPeople(token, action.people).pipe(
					map(response =>
						CohortSettingsActions.peopleAdded({
							response,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return of(
							CohortSettingsActions.peopleAddError({
								error: response.error,
							}),
						);
					}),
				),
			),
		),
	);
}
