import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MbscModule } from '@mobiscroll/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthLibModule } from 'auth-lib';
import {
	FeatureToggleLibModule,
	FeatureToggleLibService,
} from 'feature-toggle-lib';
import { DragulaModule } from 'ng2-dragula';
import { TerraUIModule } from 'terra-ui';
import { UtilLibModule } from 'util-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CohortsEffects } from './cohorts/redux/cohorts-effects';
import { cohortsKey, cohortsReducer } from './cohorts/redux/cohorts-reducer';
import { FilterPipeModule } from './filter.pipe';
import { metaReducers, reducers } from './reducers';
import { SideMenuComponentModule } from './side-menu/side-menu.component.module';
import { StudentAnalyticsModule } from './student-analytics/student-analytics.module';
import { StudentsModule } from './students/students.module';
import { ChartsModule } from 'ng2-charts';
import { MiniNavbarModule } from './mini-navbar/mini-navbar.module';

export function preloadFeagureFlags(
	featureToggleService: FeatureToggleLibService,
) {
	return () => {
		return featureToggleService.getFeatureFlags().toPromise();
	};
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		FormsModule,

		MbscModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		FeatureToggleLibModule,
		AuthLibModule,
		TerraUIModule,
		UtilLibModule,
		DragulaModule.forRoot(),
		SideMenuComponentModule,
		FilterPipeModule,
		StudentsModule,
		StudentAnalyticsModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictActionSerializability: false,
				strictStateSerializability: true,
			},
		}),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([]),
		StoreModule.forFeature(cohortsKey, cohortsReducer),
		EffectsModule.forFeature([CohortsEffects]),
		ChartsModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyB4zUgqQzsnzBoHxhbIoznUJD98Wz4bCA4',
			libraries: ['places'],
		}),
		MiniNavbarModule
	],

	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: preloadFeagureFlags,
			deps: [FeatureToggleLibService],
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
