import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { throwError } from 'rxjs';

import { map, mergeMap, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import * as CohortsActions from './cohorts-actions';
import { AuthStoreService } from 'auth-lib';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class CohortsEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private route: ActivatedRoute,
		private router: Router,
	) {}

	loadCohorts = createEffect(() =>
		this.actions.pipe(
			ofType(CohortsActions.loadCohortsList),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.getCohortsList(token).pipe(
					map(response =>
						CohortsActions.cohortsLoaded({
							cohorts: response.data,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return throwError(response.error);
					}),
				),
			),
		),
	);
	updateActiveCohortInURL = createEffect(
		() =>
			this.actions.pipe(
				ofType(CohortsActions.setActiveCohort),
				tap(action => {
					if (action.cohort) {
						this.router.navigate([], {
							relativeTo: this.route,
							queryParams: {
								cohortId: action.cohort.id,
							},
							queryParamsHandling: 'merge',
						});
					}
				}),
			),
		{ dispatch: false },
	);
}
