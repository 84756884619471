import { createFeatureSelector, createSelector } from '@ngrx/store';
import { studentCohortMixpanelData } from '../../cohort-analytics/redux/cohort-analytics.selectors';
import {
	studentAnalyticsFeatureKey,
	StudentAnalyticsState,
} from './student-analytics.reducer';

export const selectStudentAnalyticsState = createFeatureSelector<
	StudentAnalyticsState
>(studentAnalyticsFeatureKey);

export const currentStudentSelector = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.selectedStudent;
	},
);

export const selectApplicationQuestions = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.questions;
	},
);

export const selectApplicationDetails = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.details;
	},
);

export const selectCohortEvents = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.cohortEvents;
	},
);

export const selectCohrotEventData = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.cohortEvents;
	},
);

export const selectCohortEventsProgress = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.cohortEventsLoaded;
	},
);

export const selectCohortsStudentClass = createSelector(
	studentCohortMixpanelData,
	state => {
		return state;
	},
);

export const selectCohortsStudentOtherData = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.studentData;
	},
);
export const selectStudentEmailChange = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.changeEmailStatus;
	},
);
export const selectStudentEmailChangeError = createSelector(
	selectStudentAnalyticsState,
	state => {
		return state.changeEmailError;
	},
);
