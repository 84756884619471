import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dashboardKey, DashboardState } from './dashboard-reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>(
	dashboardKey,
);

export const selectApplicationsSummaryState = createSelector(
	selectDashboardState,
	state => {
		return state.applicationsSummary;
	},
);

export const selectActiveCohort = createSelector(
	selectDashboardState,
	state => state.activeCohort,
);

export const selectApplicationFilters = createSelector(
	selectDashboardState,
	state => state.applicationFilters,
);

export const selectSummaryError = createSelector(
	selectApplicationsSummaryState,
	state => state.error,
);

export const selectSummary = createSelector(
	selectApplicationsSummaryState,
	state => state.summary,
);

export const selectApplicationsCount = createSelector(
	selectSummary,
	state => state.total_applications,
);

export const selectStages = createSelector(
	selectSummary,
	state => state.stages,
);

export const selectApplicationsState = createSelector(
	selectDashboardState,
	state => {
		return state.applications;
	},
);

export const selectApplications = createSelector(
	selectApplicationsState,
	state => state.applications,
);

export const selectApplicationsError = createSelector(
	selectApplicationsState,
	state => state.error,
);

export const selectDashboardLoaded = createSelector(
	selectDashboardState,
	state => {
		return (
			state.applicationsLoaded &&
			state.applicationsSummaryLoaded &&
			state?.cohortsLoaded
		);
	},
);

export const selectApplicationsLoaded = createSelector(
	selectDashboardState,
	state => state.applicationsLoaded,
);

export const selectPaginationProgress = createSelector(
	selectDashboardState,
	state => state.paginationInProgress,
);

export const selectCohortsLoaded = createSelector(
	selectDashboardState,
	state => state?.cohortsLoaded,
);

export const selectCohortsList = createSelector(
	selectDashboardState,
	state => state.cohortsList,
);

export const selectCourseQuestions = createSelector(
	selectDashboardState,
	state => state?.applicationFilters?.courseQuestions,
);

export const selectSelectedCourse = createSelector(
	selectDashboardState,
	state => state.applicationFilters.courseId,
);

export const selectPipelineStages = createSelector(
	selectDashboardState,
	state => state?.pipelineStages,
);
export const selectAllCohortUrl = createSelector(
	selectDashboardState,
	state => state?.allCohortUrl,
);
