import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	cohortAnalyticsFeatureKey,
	CohortAnalyticsState,
} from './cohort-analytics.reducer';

export const selectCohortAnalyticsState = createFeatureSelector<
	CohortAnalyticsState
>(cohortAnalyticsFeatureKey);

export const CohortMixPanelAPI = createSelector(
	selectCohortAnalyticsState,
	state => {
		return state.API;
	},
);

export const CohortExisitingData = createSelector(
	selectCohortAnalyticsState,
	state => {
		return Object.keys(state.allData);
	},
);

export const studentCohortMixpanelData = createSelector(
	selectCohortAnalyticsState,
	state => {
		return state;
	},
);

export const studentCohortAssignmentAttendanceList = createSelector(
	selectCohortAnalyticsState,
	state => {
		return state;
	},
);

export const studentCohortConsolidatedAttendanceList = createSelector(
	selectCohortAnalyticsState,
	state => {
		return state;
	},
);

export const studentCohortAssignmentListAttendanceList = createSelector(
	selectCohortAnalyticsState,
	state => {
		return state;
	},
);
