import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-upload-status',
	templateUrl: './upload-status.component.html',
	styleUrls: ['./upload-status.component.scss'],
})
export class UploadStatusComponent implements OnInit {
	@Input() statusType: any;
	@Input() modalType: any;
	message: any;
	current: number;
	max: number;

	constructor(public modalController: ModalController) {}

	ngOnInit() {
		this.current = 0;
		this.max = 100;
		if (this.statusType === 'success') {
			this.message = 'Successfully Uploaded';
		} else {
			this.message = 'Failed to upload';
		}

		//setTimeout(() => this.closeModal(), 3000);
		//*ngIf="statusType == 'success'; else chooseFailMessage"
	}
	async closeModal() {
		await this.modalController.dismiss();
	}
}
