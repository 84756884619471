import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { TerraCohortsService } from 'data-model';
import {
	concatMap, map, withLatestFrom
} from 'rxjs/operators';
import { AppState } from '../reducers';
import { SideMenuActions } from '../side-menu/side-menu-action-types';

@Injectable()
export class SideMenuEFfects {
	constructor(
		private actions: Actions,
		private terraCohortsService: TerraCohortsService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
		public router: Router,
	) { }

	loadSideMenu = createEffect(() =>
		this.actions.pipe(
			ofType(SideMenuActions.loadSideMenu),
			withLatestFrom(this.authStore.user$, this.authStore.firebaseToken$),
			concatMap(([action, user, token]) =>
				this.terraCohortsService.getCohortByEmail(user.email, token),
			),
			map(cohorts => SideMenuActions.sideMenuLoaded({ cohorts })),
		),
	);

	sideMenuClick = createEffect(() =>
		this.actions.pipe(
			ofType(SideMenuActions.sideMenuClick),
			// tap(sideMenuClick => {
			// 	this.router.navigateByUrl(sideMenuClick.menu.url);
			// }),
			map(sideMenuClick =>
				SideMenuActions.sideMenuRedirected({ menu: sideMenuClick.menu }),
			),
		),
	);

	sideMenuSelect = createEffect(() =>
		this.actions.pipe(
			ofType(SideMenuActions.sideMenuSelect),
			map(sideMenuClick =>
				SideMenuActions.sideMenuRedirected({ menu: sideMenuClick.menu }),
			),
		),
	);
}
