import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStoreService } from 'auth-lib';
import { of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	map,
	mergeMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { BobService } from 'src/app/bob.service';
import { selectActiveCohort } from 'src/app/cohorts/redux/cohorts-selectors';
import { selectApplicationFilters } from 'src/app/home/redux/dashboard-selectors';
import { AppState } from '../../reducers';
import * as DashboardActions from './dashboard-actions';

@Injectable()
export class DashboardEffects {
	constructor(
		private actions: Actions,
		private bobService: BobService,
		private authStore: AuthStoreService,
		private store: Store<AppState>,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	loadCohorts = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.loadCohortsList),
			withLatestFrom(this.authStore.firebaseToken$),
			mergeMap(([action, token]) =>
				this.bobService.getCohortsList(token).pipe(
					map(response =>
						DashboardActions.cohortsLoaded({
							cohorts: response.data,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return throwError(response.error);
					}),
				),
			),
		),
	);

	loadDefaultPipelineStagges$ = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.loadDefaultPipelineStages),
			mergeMap(() =>
				this.bobService.getDefaultPipelineStages().pipe(
					map(({ data }) =>
						DashboardActions.setDefaultPipelineStages({
							stages: data?.stages,
						}),
					),
				),
			),
		),
	);

	loadApplicationsSummary = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.loadApplicationsSummary),
			withLatestFrom(
				this.store.select(selectApplicationFilters),
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, filters, activeCohort]) =>
				this.bobService
					.getApplicationsSummary(activeCohort.id, filters.courseId)
					.pipe(
						map(response => {
							return DashboardActions.applicationsSummaryLoaded({
								summary: response.data,
							});
						}),
						catchError(({ error }) =>
							of(DashboardActions.applicationsSummaryError({ error })),
						),
					),
			),
		),
	);

	loadApplications = createEffect(
		() =>
			this.actions.pipe(
				ofType(DashboardActions.loadApplications),
				withLatestFrom(
					this.store.select(selectApplicationFilters),
					this.store.select(selectActiveCohort),
				),
				mergeMap(([action, filters, activeCohort]) =>
					this.bobService
						.fetchApplications(
							activeCohort.id,
							filters.filterDates,
							filters.sort,
							1,
							filters.stageIds,
							filters.tags,
							filters.searchValue,
						)
						.pipe(
							map(response => {
								return DashboardActions.applicationsLoaded({
									applications: response.data.applications,
								});
							}),
						),
				),
			),
		// 	mergeMap(
		// 		({
		// 			filters: {
		// 				cohortId,
		// 				filterDates,
		// 				sort,
		// 				page,
		// 				stageIds,
		// 				tags,
		// 				searchValue,
		// 			},
		// 		}) =>
		// 			this.bobService
		// 				.fetchApplications(
		// 					cohortId,
		// 					filterDates,
		// 					sort,
		// 					page,
		// 					stageIds,
		// 					tags,
		// 					searchValue,
		// 				)
		// 				.pipe(
		// 					map(response =>
		// 						DashboardActions.applicationsLoaded({
		// 							applications: response.data.applications,
		// 						}),
		// 					),
		// 					catchError(({ error }) =>
		// 						of(DashboardActions.applicationsError({ error })),
		// 					),
		// 				),
		// 	),
		// ),
	);

	loadPaymentReceivedApplications = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.loadPaymentReceivedApplications),
			withLatestFrom(
				this.store.select(selectApplicationFilters),
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, filters, activeCohort]) =>
				this.bobService
					.fetchPaymentReceivedApplications(
						activeCohort.id,
						filters.filterDates,
						filters.sort,
						1,
						filters.stageIds,
						filters.tags,
						filters.searchValue,
					)
					.pipe(
						map(response => {
							console.log('filter dates passed are', filters.filterDates);
							if (filters.page > 1) {
								return DashboardActions.applicationsLoaded({
									applications: response.data.applications,
								});
							} else {
								return DashboardActions.applicationsLoaded({
									applications: response.data.applications,
								});
							}
						}),
					),
			),
		),
	);

	paginateApplications = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.paginateApplications),
			withLatestFrom(
				this.store.select(selectApplicationFilters),
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, filters, activeCohort]) =>
				this.bobService
					.fetchApplications(
						activeCohort.id,
						filters.filterDates,
						filters.sort,
						filters.page,
						filters.stageIds,
						filters.tags,
						filters.searchValue,
					)
					.pipe(
						map(response => {
							return DashboardActions.applicationsPaginated({
								applications: response.data.applications,
							});
						}),
					),
			),
		),
	);

	paginatePaymentReceivedApplications = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.paginatePaymentReceivedApplications),
			withLatestFrom(
				this.store.select(selectApplicationFilters),
				this.store.select(selectActiveCohort),
			),
			mergeMap(([action, filters, activeCohort]) =>
				this.bobService
					.fetchPaymentReceivedApplications(
						activeCohort.id,
						filters.filterDates,
						filters.sort,
						filters.page,
						filters.stageIds,
						filters.tags,
						filters.searchValue,
					)
					.pipe(
						map(response => {
							return DashboardActions.applicationsPaginated({
								applications: response.data.applications,
							});
						}),
					),
			),
		),
	);

	loadCourseQuestions = createEffect(() =>
		this.actions.pipe(
			ofType(DashboardActions.loadCourseQuestions),
			withLatestFrom(this.authStore.firebaseToken$),
			concatMap(([action, token]) =>
				this.bobService.getCourseQuestions(token, action.courseId).pipe(
					map(response =>
						DashboardActions.courseQuestionsLoaded({
							questions: response.data,
						}),
					),
					catchError(response => {
						if (response.status === 401) {
							this.authStore.logout();
						}
						return throwError(response.error);
					}),
				),
			),
		),
	);
	updateFilterStateInUrl = createEffect(
		() =>
			this.actions.pipe(
				ofType(DashboardActions.setApplicationFilters),
				tap(action => {
					this.router.navigate([], {
						relativeTo: this.route,
						queryParams: {
							stages: action.filters.stageIds?.length
								? JSON.stringify(action.filters?.stageIds)
								: undefined,
							sort: action.filters.sort,
							q: action.filters.searchValue
								? action.filters.searchValue
								: undefined,
								previous: null,
						},
						queryParamsHandling: 'merge',
					});
				}),
			),
		{ dispatch: false },
	);
}
