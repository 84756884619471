import { createReducer, on } from '@ngrx/store';
import * as CohortAnalyticsActions from './cohort-analytics.actions';

export const cohortAnalyticsFeatureKey = 'CohortAnalytics';

export interface CohortAnalyticsState {
	allData: any;
	API: boolean;
	studentClass: any;
	classStudent: any;
}

export const initialState: CohortAnalyticsState = {
	allData: {},
	API: true,
	studentClass: {},
	classStudent: {},
};

export const cohortAnalyticsReducer = createReducer(
	initialState,
	on(CohortAnalyticsActions.getCohortAPIStart, state => {
		return {
			...state,
			API: false,
		};
	}),
	on(CohortAnalyticsActions.getCohortAPIEnd, state => {
		return {
			...state,
			API: true,
		};
	}),
	on(CohortAnalyticsActions.getCohortMixpanelListSuccess, (state, action) => {
		const allDataArr = { ...state.allData };
		allDataArr[action.cohortId] = action.data;

		return {
			...state,
			allData: allDataArr,
		};
	}),
	on(CohortAnalyticsActions.getCohortStudentClass, (state, action) => {
		const allDataArr = { ...state.allData };
		allDataArr[action.cohortId] = action.data;

		return {
			...state,
			studentClass: allDataArr,
		};
	}),
	on(CohortAnalyticsActions.getCohortClassStudent, (state, action) => {
		const correctCompletionRate = action.data.map(student => {
			return {
				...student,
				events: student.events.map(ev => {
					const allModules = ev.modules
						.filter(({ mandatory }) => mandatory)
						.map(({ completionRate }) => completionRate || 0);

					if (allModules.length > 0) {
						return {
							...ev,
							isActiveClass: true,
							completionRate:
								allModules.reduce((a, b) => a + b, 0) / allModules.length,
						};
					} else {
						return {
							...ev,
							isActiveClass: true,
						};
					}
				}),
			};
		});

		if (correctCompletionRate.length > 0) {
			const classes = correctCompletionRate[0].events.map(ev => {
				return {
					classEventId: ev.classEventId,
					classId: ev.classId,
					eventId: ev.eventId,
					modules: ev.modules.map(({ name, moduleId, mandatory }) => ({
						name,
						moduleId,
						mandatory,
						notStated: action.data
							.map(student => {
								return {
									...student,
									events: student.events.filter(
										({ classEventId, completionRate }) =>
											classEventId === ev.classEventId && completionRate === 0,
									),
								};
							})
							.filter(({ events }) => events.length > 0),
						started: action.data
							.map(student => {
								return {
									...student,
									events: student.events.filter(
										({ classEventId, completionRate }) =>
											classEventId === ev.classEventId &&
											!(completionRate === 100 || completionRate === 0),
									),
								};
							})
							.filter(({ events }) => events.length > 0),
						completed: action.data
							.map(student => {
								return {
									...student,
									events: student.events.filter(
										({ classEventId, completionRate }) =>
											classEventId === ev.classEventId &&
											completionRate === 100,
									),
								};
							})
							.filter(({ events }) => events.length > 0),
					})),
					name: ev.name,
				};
			});

			const allDataArr = { ...state.allData };
			allDataArr[action.cohortId] = classes;

			return {
				...state,
				classStudent: allDataArr,
			};
		} else {
			return state;
		}
	}),
);

export interface CohortAnalyticFilters {
	// eventType: any[];
	group: any[];
	class: any[];
	assignment: any[];
	event: any[];
}
