import { createAction, props } from '@ngrx/store';

export const getCohortAPIStart = createAction(
	'[Cohort Analytics] Cohort Analytics API start',
);

export const getCohortAPIEnd = createAction(
	'[Cohort Analytics] Cohort Analytics API end',
);

export const getCohortMixpanelList = createAction(
	'[Cohort Analytics] Cohort Analytics List',
	props<{ cohortId: number }>(),
);

export const getCohortMixpanelListSuccess = createAction(
	'[Cohort Analytics] Cohort Analytics List success',
	props<{ cohortId: number; data: any }>(),
);

export const getCohortStudentClass = createAction(
	'[Cohort Analytics] Cohort Analytics Student Class',
	props<{ cohortId: number; data: any }>(),
);

export const getCohortClassStudent = createAction(
	'[Cohort Analytics] Cohort Analytics Class Student',
	props<{ cohortId: number; data: any }>(),
);
